import React, { useEffect, useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/icons/zensory_logo.svg";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import { checkEmailFormat, isNullOrEmpty } from "../Data/Util/util";
import SpinnerLoader from "../SpinnerLoader";
import AuthErrorPopup from "../AuthErrorPopup";
import { LOGGER, LogLevel } from "../Util/Logger";
import AuthSuccessPopup from "../AuthSuccessPopup";
import { NODE_ID_LOGIN, getConfigLink, isLinkId } from "../Data/constants";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState();
  const [emailErrorDialog, showEmailErrorDialog] = useState(false);
  const [emailErrorDescription, setEmailErrorDescription] = useState();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showSendSuccess, setShowSendSuccess] = useState(false);
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] =
    useState();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ForgotPasswordScreen);
  }, []);

  useEffect(() => {
    async function hasUser() {
      const hasUser = await ZensoryModule.getAuth()
        .hasUser(true)
        .then((res) => {
          return res;
        });
      if (hasUser) {
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
      }
      setIsLoggedIn(false);
      LOGGER.log(LogLevel.DEBUG, `hasUser=${hasUser}`);
    }
    hasUser();
  }, [isLoggedIn]);

  const onEmailChange = (text) => {
    if (!checkEmailFormat(text)) {
      showEmailErrorDialog(true);
      setEmailErrorDescription(getText(TextId.emailErrorDescription));
    } else {
      showEmailErrorDialog(false);
    }
    setEmail(text);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    preventDefault(e);
    const isSent = await ZensoryModule.getAuth()
      .forgotPassword(email)
      .catch((error) => {
        setForgotPasswordErrorMessage(error.message);
        setShowLoginPopup(true);
      });
    if (isSent) {
      //show success popup
      setShowSendSuccess(true);
    }
    setLoading(false);
  };

  const transformText = (node, children) => {
    if (node.tagName.toLowerCase() === "a") {
      return (
        <a
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "white",
          }}
          href={
            getConfigLink(node.id, NODE_ID_LOGIN)
          }
          target={
            isLinkId(node.id)
              ? "_blank"
              : null
          }
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
  };

  const onClosePopUp = () => {
    setEmail("");
    setShowSendSuccess(false);
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  return (
    <>
      <div className="login-container">
        {showLoginPopup ? (
          <AuthErrorPopup
            setShowAuthErrorPopup={setShowLoginPopup}
            errorMessage={forgotPasswordErrorMessage}
          />
        ) : null}
        {showSendSuccess ? (
          <AuthSuccessPopup
            setShowAuthErrorPopup={onClosePopUp}
            successMessage={getText(TextId.forgotPasswordSuccessMessage, {
              EMAIL: email,
            })}
          />
        ) : null}
        <div className="header-row-login">
          <div className="logo-container">
            <img className="logo" src={logo} alt="Zensory logo" />
          </div>
        </div>
        <div className="login-content-container">
          <h1 className="login-heading">
            <Interweave content={getText(TextId.forgotPasswordtitle)} />
          </h1>

          <form className="input-container" onSubmit={handleSubmit}>
            <div className="input-field">
              <div className="input-title"> Your Email </div>
              <input
                className=""
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required={true}
                value={email}
                onChange={(e) => onEmailChange(e.target.value)}
              />
            </div>
            <span className="subtitle">
              {getText(TextId.forgotPasswordAdvisory)}
            </span>

            {emailErrorDialog ? (
              <span className="text-danger">{emailErrorDescription}</span>
            ) : null}

            <button
              disabled={
                emailErrorDialog || showLoginPopup || isNullOrEmpty(email)
              }
              className="next-button"
              style={{ position: "relative" }}
            >
              {loading ? <SpinnerLoader /> : "Reset Password"}
            </button>
          </form>
          <div className="info-container">
            <div className="sign-up-text">
              <Interweave
                content={getText(TextId.forgotPasswordText)}
                transform={transformText}
              />
            </div>
          </div>
          <div
            className="web-version"
            id="version-login"
            onClick={(e) => onVersionClicked(e)}
          >
            <Interweave content={`Version: ${getConfiguration().version}`} />
          </div>
        </div>
      </div>
    </>
  );
};

const ForgotPassword = withScorm()(ForgotPasswordPage);
export default ForgotPassword;
