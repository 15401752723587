import { MoodId, ScreenOrientation, SoundscapeId, SubSenseId } from "../Data/constants"

// Choose Breath tiles
import visualBreathFocusTile from "../../assets/icons/boxbreath.jpg"
import visualBreathRelaxTile from "../../assets/icons/threepartrelax.jpg"
import audioBreathRelaxTile from "../../assets/icons/guidedrelax.jpg"
import audioBreathFocusTile from "../../assets/icons/guidedenergise.jpg"

// Breath Experience backgrounds
import threePartBreathing from "../../assets/icons/breathing_background_relax.jpg"
import guidedrelax from "../../assets/images/audioGuidedBreathingRelax.jpg"
import guidedenergise from "../../assets/images/audioGuidedBreathingFocus.jpg"
import threePartBreathingLowRes from "../../assets/icons/breathing_background_relax_low_res.jpg"
import guidedrelaxLowRes from "../../assets/images/audioguidedbreathingrelax_low_res.jpg"
import guidedenergiseLowres from "../../assets/images/audioguidedbreathingfocus_low_res.jpg"

export const getChooseBreathTileImage = (moodId, subSenseId) => {
    switch (moodId) {
        case MoodId.Focused:
            switch (subSenseId) {
                case SubSenseId.VisualGuidedBreath:
                    return visualBreathFocusTile;
                case SubSenseId.AudioGuidedBreath:
                    return audioBreathFocusTile;
                default:
                    throw new Error(`getChooseBreathTileImage - invalid subSenseId=${subSenseId}`);
            }

        case MoodId.Relax:
            switch (subSenseId) {
                case SubSenseId.VisualGuidedBreath:
                    return visualBreathRelaxTile;
                case SubSenseId.AudioGuidedBreath:
                    return audioBreathRelaxTile;
                default:
                    throw new Error(`getChooseBreathTileImage - invalid subSenseId=${subSenseId}`);
            }
        
        default:
            throw new Error(`getChooseBreathTileImage - invalid moodId=${moodId}`);
    }
};

export const getBreathExperienceImage = (moodId, subSenseId) => {
    switch (moodId) {
        case MoodId.Focused:
            switch (subSenseId) {
                case SubSenseId.VisualGuidedBreath:
                    return {
                        highRes: "", // no image need as Visual Box breath uses a background from css 
                        lowRes: ""
                    };
                case SubSenseId.AudioGuidedBreath:
                    return { 
                        highRes: guidedenergise,
                        lowRes: guidedenergiseLowres
                    };
                default:
                    throw new Error(`getBreathExperienceImage - invalid subSenseId=${subSenseId}`);
            }

        case MoodId.Relax:
            switch (subSenseId) {
                case SubSenseId.VisualGuidedBreath:
                    return { 
                        highRes: threePartBreathing,
                        lowRes: threePartBreathingLowRes
                    };
                case SubSenseId.AudioGuidedBreath:
                    return { 
                        highRes: guidedrelax,
                        lowRes: guidedrelaxLowRes
                    };
                default:
                    throw new Error(`getBreathExperienceImage - invalid subSenseId=${subSenseId}`);
            }  
               
        default:
            throw new Error(`getBreathImage - invalid moodId=${moodId}`);
    }
};
