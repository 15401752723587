import { AuthApi } from "../../API/AuthApi";
import { getFirebaseHelper } from "../../../components/Util/FirebaseHelper";
import { UserCredential, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";
import { GlobalAppState } from "../../AppState";
import { LOGGER, LogLevel } from "../../Util/Logger";
import { ZensoryCloudFunctionNames } from "../../Data/Models/zensory_api";
import { User } from "../../Data/Models/app";
import { getDeviceDetailsFirebase } from "../../Util/DeviceUtil";

interface GetAppUserResponse {
    result: {
        user: User;
    }
}

interface NewUserRequest {
    userId: string;
    name: string | null | undefined;
    country: string | null | undefined;
    language: string | null | undefined;
    deviceId: string | null | undefined;
    platform: string | null | undefined;
  }

export class FirebaseAuthImpl implements AuthApi {

    async onUserSignedUp(userId: string, name: string): Promise<boolean> {
        const deviceDetails = getDeviceDetailsFirebase();
        
        const payload: NewUserRequest = {
            userId: userId,
            name: name,
            country: "",
            language: window.navigator.language,
            deviceId: deviceDetails.deviceId,
            platform: deviceDetails.os
        };

        try {
            const response = (await getFirebaseHelper().callCloudFunction(
                ZensoryCloudFunctionNames.OnUserSignedUp,
                payload
            ));
            LOGGER.log(LogLevel.DEBUG, `FirebaseAuthImpl.onUserSignedUp() response=${JSON.stringify(response)}`);

            if (response) {
                return true;
            } else {
                throw new Error("onUserSignedUp error. Check logs");
            }
        } catch (error) {
            throw new Error("onUserSignedUp error " + error);
        }
    }

    async getCurrentUserWithObserver(): Promise<string | null> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        const onAuthStateChangedPromise = new Promise((resolve: (value: string | null) => void, reject) => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    if (!GlobalAppState.isSigningUp()) {
                        const response = (await getFirebaseHelper()
                            .callCloudFunction(
                                ZensoryCloudFunctionNames.GetUser,
                                { 
                                    userId: user.uid
                                }
                            )
                        ) as GetAppUserResponse;

                        if (response.result?.user) {
                            // User is signed in.
                            GlobalAppState.setIsLoggedIn(true);
                            GlobalAppState.setUser(response.result.user);
                        } else {
                            // else there is an issue getting the user (eg. user not found)
                            // but we cannot log out because this can be called during signup before
                            // the user account is created, so just let it fail open
                            LOGGER.log(LogLevel.DEBUG, `AUTH: getCurrentUserWithObserver() response.result=${JSON.stringify(response)}`);
                            resolve(null);
                        }
                    }
                    resolve(user.uid);
                } else {
                    // No user is signed in.
                    GlobalAppState.setIsLoggedIn(false);
                    resolve(null);
                }
            }, err => {
                reject(err);
            });
        });
        return onAuthStateChangedPromise;
    }

    getCurrentUser(): string | null {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        const user = auth.currentUser;

        if (user) {
            // User is signed in
            return user.uid;
        } else {
            // No user is signed in.
            return null;
        }
    }

    async hasUser(setInGlobalAppState: boolean): Promise<boolean> {
        if (setInGlobalAppState && !GlobalAppState.isSigningUp()) {
            return this.getCurrentUserWithObserver().then(uid => {
                return uid !== null;
            });
        } else {
            return this.getCurrentUser() !== null;
        }
    }

    async login(email: string, password: string): Promise<boolean> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        const user = await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                return user;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                LOGGER.log(LogLevel.ERROR, `FirebaseAuthImpl.login() error=${JSON.stringify(error)}`);
                throw new Error(errorCode, errorMessage);
            });

        if (user) {
            return true;
        } else {
            return false;
        }
    }

    async signup(
        email: string,
        password: string,
        name: string
    ): Promise<boolean> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        // set isSigningUp to true to prevent us from fetching the user before they are
        // added to the database
        GlobalAppState.setIsSigningUp(true);

        const currentUserId = this.getCurrentUser();
        if (currentUserId !== null) {
            LOGGER.log(LogLevel.DEBUG, `FirebaseAuthImpl.signup() user already exists`);
            await this.afterUserSignedUp(currentUserId, name, email, password);
            return true;
        }

        const user = await createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                LOGGER.log(LogLevel.DEBUG, `Successfully signed up createUserWithEmailAndPassword`);
                // Signed up 
                const user = userCredential.user;
                updateProfile(user, {
                    displayName: name
                });
                await this.afterUserSignedUp(user.uid, name, email, password);
                return user;

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                LOGGER.log(LogLevel.ERROR, `FirebaseAuthImpl.login() error=${JSON.stringify(error)}`);
                throw new Error(errorCode, errorMessage);
            });

        GlobalAppState.setIsSigningUp(false);

        if (user) {
            return true;
        } else {
            return false;
        }
    }

    async afterUserSignedUp(
        userId: string,
        name: string,
        email: string,
        password: string
    ): Promise<boolean> {
        // add the user to the database
        const onUserSignedUpRes = await this.onUserSignedUp(userId, name);
        LOGGER.log(LogLevel.DEBUG, `Sign up with backend onUserSignedUpRes=${onUserSignedUpRes}`);
        return onUserSignedUpRes;
    }

    async forgotPassword(email: string): Promise<boolean> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        const response = await sendPasswordResetEmail(auth, email)
            .then(() => {
                //email sent
                return true;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                LOGGER.log(LogLevel.ERROR, `FirebaseAuthImpl.sendPasswordResetEmail() error=${JSON.stringify(error)}`);
                throw new Error(errorCode, errorMessage);
            });

        if (response) {
            return true;
        } else {
            return false;
        }
    }

    async logout(): Promise<void> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        await signOut(auth).then(() => {
            // Sign-out successful.
            GlobalAppState.setUser();
            GlobalAppState.setIsLoggedIn(false);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            LOGGER.log(LogLevel.ERROR, `FirebaseAuthImpl.logout() error=${JSON.stringify(error)}`);
            throw new Error(errorCode, errorMessage);
        });
    }

    async signInWithCustomToken(token: string): Promise<boolean> {
        const firebaseHelper = getFirebaseHelper();
        const auth = firebaseHelper.getAuth();

        const promise: Promise<boolean> = new Promise((resolve: (value: boolean) => void, reject) => {
            signInWithCustomToken(auth, token)
                .then((userCredential: UserCredential) => {
                    // Signed in
                    LOGGER.log(LogLevel.DEBUG, `FirebaseAuthImpl.signInWithCustomToken() user=${JSON.stringify(userCredential.user)}`);
                    resolve(true);
                })
                .catch((error) => {
                    // Error signing in
                    LOGGER.log(LogLevel.ERROR, `FirebaseAuthImpl.signInWithCustomToken() error=${JSON.stringify(error)}`);
                    resolve(false);
                });
        });

        const resut = await promise;
        GlobalAppState.setIsLoggedIn(resut);
        return resut;
    }
}