import { useEffect, useState } from "react";
import "../styles/breathwork.css"
import "../styles/boxBreathing2.css"
import { AudioTriggerId } from "./Data/constants";
import { ZensoryModule } from "../ZensoryModule";
import { LOGGER, LogLevel } from "./Util/Logger";
import { AudioType, getNewAudioPlayer } from "./API/AudioPlayerAPI";
import { audioObjMapToArray, getAudioObjLevel } from "./Util/AudioHelper";

const VISUAL_BREATH_COUNT_TYPE_INHALE = 0;
const VISUAL_BREATH_COUNT_TYPE_HOLD = 1;
const VISUAL_BREATH_COUNT_TYPE_EXHALE = 2;

const VisualGuidedBoxBreath = (props) => {

  let isPlayAudio = true;

  const [audioPlayer, setAudioPlayer] = useState(null);
  const [audioClips, setaudioClips] = useState({});
  
  let container = document.querySelector('.guided-breath-container');
  let text = document.querySelector('#text');
  let topText = document.querySelector('#top-text');
  let starting = document.querySelector('#start-message');
  let guidedPointerContainer = document.querySelector('#guided-pointer-container');
  let guidedPointer = document.querySelector('#guided-pointer');

  // Change values here and in container.grow and container.shrink
  let inhaleTimeSec = 4;    // seconds
  let exhaleTimeSecs = 4;   // seconds
  let holdTimeSecs = 4;     // seconds

  let COUNT = 1250;         // each count should be 1.25 seconds 
  let inhaleTime = inhaleTimeSec * COUNT;   // milli-seconds
  let exhaleTime = exhaleTimeSecs * COUNT;   // milli-seconds
  let holdTime = holdTimeSecs * COUNT;     // milli-seconds
  let totalTime = inhaleTime + exhaleTime + (2 * holdTime);

  let iterationCount = 0;
  let intervalId;

  let restText = 'Rest<span>return to normal breath</span>';

  // defaults
  let countdownTime = 5;
  let numIterations = 999;
  let restIntervalSecs = 0;
  let hasStartedLocal = false;

  let currentCount = 0;
  let currentType = VISUAL_BREATH_COUNT_TYPE_INHALE;

  function getCurrentType(type) {
    currentType = type;
    switch (currentType) {
      case VISUAL_BREATH_COUNT_TYPE_INHALE:
        currentCount = inhaleTimeSec;
        break;
      case VISUAL_BREATH_COUNT_TYPE_EXHALE:
        currentCount = exhaleTimeSecs;
        break;
      case VISUAL_BREATH_COUNT_TYPE_HOLD:
        currentCount = holdTimeSecs;
        break;
      default:
        throw new Error(`Invalid type=${type}`);
    }
  }

  useEffect(() => {
    setaudioClips(audioClips => {
      LOGGER.log(LogLevel.DEBUG, `setaudioClips() started`);
      const audioMap = ZensoryModule.getAudioLoader().getBreathAudioTriggerMap();
      const audioTriggers = {};
      Object.keys(audioMap).forEach(key => {
        audioTriggers[key] = {
          id: key,
          type: AudioType.VisualBreathTrigger,
          uri: audioMap[key],
          isUnlimited: false,
          volume: getAudioObjLevel(props.zense, props.experience, AudioType.VisualBreathTrigger)
        };
      });
      return audioTriggers;
    });
  }, []);

  useEffect(() => {
    const audios = audioObjMapToArray(audioClips);
    if (audios.length > 0) {
      const setAudioPlayerAndLoad = async () => {
        LOGGER.log(LogLevel.DEBUG, `VisualGuidedBoxBreath useEffect([audioClips]) Starting Breath trigger load for audios.len=${audios.length}`);
        const audioPlayerLocal = getNewAudioPlayer(
          audios,
          // on load
          () => {
            LOGGER.log(LogLevel.DEBUG, `VisualGuidedBoxBreath useEffect([audioClips]) Breath trigger audios loaded`);
            setAudioPlayer(audioPlayerLocal);
            props.setVisualBreathTriggerVolume(getAudioObjLevel(props.zense, props.experience, AudioType.VisualBreathTrigger));
            props.setHasLoadedVisualBreath(true);
          },
          // on error
          (error) => {
            LOGGER.log(LogLevel.ERROR, `VisualGuidedBoxBreath useEffect([audioClips]) Audio player error=${error}`);
            // TODO: set Experience error prop
          }
        );
        await audioPlayerLocal.load();
      };
      setAudioPlayerAndLoad();
    }
  }, [audioClips]);

  useEffect(() => {
    if (starting && !hasStartedLocal && props.hasStarted) {
      startCountdown(countdownTime, starting, startBreathing);
    }
  }, [props.isPlaying, starting, props.hasStarted]);

  const pauseAudioTriggers = (hasExpired) => {
    LOGGER.log(LogLevel.DEBUG, `pauseAudioTriggers() started, audioPlayer=${audioPlayer}`);

    if (audioPlayer !== null) {
      audioPlayer.pause();

      if (hasExpired) {
        audioPlayer.destroy();
      }
    }
  }

  useEffect(() => {
    if (props.expired) {
      pauseBreathing();
      pauseAudioTriggers(true);
    }
  }, [props.expired]);

  useEffect(() => {
    if (audioPlayer !== null) {
      audioPlayer.setVisualBreathTriggerVolume(props.visualBreathTriggerVolume);
    }
  }, [props.visualBreathTriggerVolume]);

  const playAudio = (id) => {
    if (props.expired || !isPlayAudio || audioPlayer === null) {
      return;
    }

    audioPlayer.playAudioObj(audioClips[id]);
  }

  // Start the breathing

  const startCountdown = (numSecs, textView, nextFunction) => {
    if (numSecs <= 0) {
      if (!hasStartedLocal) {
        hasStartedLocal = true;
        guidedPointerContainer.style.animationPlayState = "running";
        guidedPointer.style.animationPlayState = "running";
      }
      textView.innerHTML = '';
      nextFunction();
      return;
    }

    textView.innerHTML = `Starting in ${numSecs}`;

    setTimeout(() => {
      startCountdown(numSecs - 1, textView, nextFunction);
    }, COUNT);
  }

  const breathAnimation = () => {
    // if the number of iterations is done, go to a rest time
    if (iterationCount >= numIterations) {
      pauseBreathing();

      text.innerHTML = restText;
      topText.innerHTML = '';

      startBreathingCountdown(restIntervalSecs);

      setTimeout(() => {
        iterationCount = 0;
        startBreathing();
      }, restIntervalSecs * COUNT);

      return;
    }

    // Inhale
    text.innerHTML = 'Inhale';
    currentType = VISUAL_BREATH_COUNT_TYPE_INHALE;
    startBreathingCountdown(inhaleTimeSec, AudioTriggerId.In1);
    container.className = 'guided-breath-container grow'; // add a growth class to the container to enlarge the effect

    // Trigger after inhaleTime
    setTimeout(() => {
      if (holdTime > 0) {
        text.innerHTML = 'Hold';
        currentType = VISUAL_BREATH_COUNT_TYPE_HOLD;
        startBreathingCountdown(holdTimeSecs, AudioTriggerId.Hold1);
      }

      // Trigger after hold
      setTimeout(() => {
        text.innerHTML = 'Exhale';
        currentType = VISUAL_BREATH_COUNT_TYPE_EXHALE;
        startBreathingCountdown(exhaleTimeSecs, AudioTriggerId.Out1);
        container.className = 'guided-breath-container shrink'; // add a shrink class to the container to reduce the size

        // Trigger after exhaleTime
        setTimeout(() => {
          if (holdTime > 0) {
            text.innerHTML = 'Hold';
            currentType = VISUAL_BREATH_COUNT_TYPE_HOLD;
            startBreathingCountdown(holdTimeSecs, AudioTriggerId.Hold1);
          }

          iterationCount++;

        }, exhaleTime);

      }, holdTime);

    }, inhaleTime);
  }

  const startBreathing = () => {
    breathAnimation();
    intervalId = setInterval(breathAnimation, totalTime); // repeat
  }

  const pauseBreathing = () => {
    clearInterval(intervalId);
  }

  const startBreathingCountdown = (maxCount, audioTriggerId = null) => {
    startBreathingCountdownAux(1, maxCount, audioTriggerId);
  }

  const startBreathingCountdownAux = (startCount, maxCount, audioTriggerId = null) => {
    topText.innerHTML = ` ${startCount} `;

    // decrement the current breath count
    currentCount--;

    if (audioTriggerId !== null) {
      playAudio(audioTriggerId);
    } else {
      playAudio("num" + startCount);
    }

    if (maxCount > startCount) {
      setTimeout(() => {
        startBreathingCountdownAux(startCount + 1, maxCount, null);
      }, COUNT);
    }
  }

  return (
    <div class="audio-guided-container">
      <div class="guided-breath-container" id="guided-breath-container">
        <div id="top-text"></div>
        <div id="text" class="texty"></div>
        <div id="start-message"></div>
        <div class="guided-circle">
          <div class="flare1"></div>
          <div class="flare2"></div>
          <div class="flare3"></div>
          <div class="flare4"></div>
        </div>
        <p id="text"></p>
        <div id="guided-pointer-container" class="guided-pointer-container">
          <span id="guided-pointer" class="guided-pointer" >
          </span>
        </div>
        <div class="guided-gradient-circle"></div>
      </div>
    </div>
  )
};

export default VisualGuidedBoxBreath;