import cross from "../assets/icons/close.svg"
import binauralinfographic from "../assets/icons/binaural-infographic.svg"
import binauralwaves from "../assets/icons/binaural-waves.svg"
import { KeyCode, KeyId } from "./UI/KeyEnum";
import { isKeyIn, preventDefault, stopPropagation } from "./UI/KeyPressHelper";
import { getText, TextId } from "./Util/TextHelper";
import { MoodId } from "./Data/constants";
import { Interweave } from "interweave";
import { useEffect, useRef, useState } from "react";
import { handleTabKey } from "./Util/ModalAccessibilityHelper";

const LearnWhyPopup = (props) => {

    // set focus to the confirm end button when the dialog shows
    const modalRef = useRef();
    const [btnStartFocus, setStartFocusButton] = useState(null);

    useEffect(() => {
        if (btnStartFocus != null) {
            btnStartFocus.focus();
        }
    },[btnStartFocus]);

    //handle tab key press
    
    useEffect(() => {
        function keyListener(e) {
            const listener = 
            e.keyCode === KeyCode.Tab ?
            handleTabKey(e, modalRef,'img, p') :
            e.keyCode === KeyCode.Esc ? 
            closePopup(e) :
            null;
            return listener && listener(e, modalRef,'img, p');
          }
        document.addEventListener("keydown", keyListener);
        return () => document.removeEventListener("keydown", keyListener);
    },[]);

    const closePopup = (e) => {
        preventDefault(e);
        props.setShowLearnWhyPopup(false);
    }

    // handle return key presses on the popup close button
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar, KeyId.Esc])) {
            closePopup(e);
        }
    }

    return (
        <div className="binaural-popup-container"
            id="binaural-popup-container"
            role="dialog"
            aria-modal="true"
            ref={modalRef}
            >
            <div className='popup-header'>
                <img
                    id="btn-learn-why-popup-close"
                    src={cross}
                    className="popup-close-icon"
                    onClick={(e) => closePopup(e)}
                    tabIndex="0"
                    role="button"
                    alt="Close Learn Why"
                    aria-label="Close Learn Why"
                    onKeyDown={(e) => _handleKeyDownClose(e)} />
            </div>
            <h1 className="binaural-popup-heading">
                <Interweave
                    content={getText(TextId.homeWelcomeLearnWhyPopupHeading)}
                />
            </h1>
            <div
                className='binaural-popup-content'
                id="binaural-popup-content">

                <div className="binaural-popup-images">
                    <img
                        src={binauralinfographic}
                        className="binaural-waves"
                        ref={(button) => { setStartFocusButton(button); }}
                        alt={getText(TextId.learnWhyBinauralBeatsFrequencies)}
                        aria-label={getText(TextId.learnWhyBinauralBeatsFrequencies)}
                        tabIndex="0"
                    />
                    <img
                        src={binauralwaves}
                        className="binaural-infographic"
                        alt={getText(TextId.learnWhyBinauralBeatsAudioDifferential)}
                        aria-label={getText(TextId.learnWhyBinauralBeatsAudioDifferential)}
                        tabIndex="0"
                    />
                </div>
                <p
                    className='binaural-popup-instruction'
                    alt={getText(props.mood === MoodId.Focused
                        ? TextId.homeWelcomeLearnWhyPopupTextFocus
                        : TextId.homeWelcomeLearnWhyPopupTextRelax
                    )}
                    aria-label={getText(props.mood === MoodId.Focused
                        ? TextId.homeWelcomeLearnWhyPopupTextFocus
                        : TextId.homeWelcomeLearnWhyPopupTextRelax
                    )}
                    tabIndex="0"
                    role="contentinfo"
                >
                    <Interweave
                        content={getText(props.mood === MoodId.Focused
                            ? TextId.homeWelcomeLearnWhyPopupTextFocus
                            : TextId.homeWelcomeLearnWhyPopupTextRelax
                        )}
                    />
                </p>
            </div>
        </div>
    );
};

export default LearnWhyPopup;