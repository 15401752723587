import React, { useEffect, useState } from "react";
import "../../styles/review.css"
import happy_face from "../../assets/icons/smiley-white.svg"
import sad_face from "../../assets/icons/sad-white.svg"
import cross from "../../assets/icons/close.svg"
import logo from "../../assets/icons/zensory_logo.svg"
import check from "../../assets/icons/check-white.svg"
import { ZensoryModule } from "../../ZensoryModule";
import { withScorm } from 'react-scorm-provider';
import Tooltip from "../Tooltip";
import { AudioDurationSecs, MoodId } from "../Data/constants";
import { AnalyticsScreenName, EndExperienceWithRatingAnalyticsEvent } from "../API/AnalyticsApi";
import { LOGGER, LogLevel } from "../Util/Logger";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import CloseAndExitPopup from "../Close&ExitPopup";
import { GlobalAppState } from "../AppState";

const ReviewPage = (props) => {

    useEffect(() => {
        // ensure neither the Yes/No tiles are not showing as selected when the page loads
        let thumbsUpViewRef = document.getElementById("thumbs-up");
        let thumbsDownViewRef = document.getElementById("thumbs-down");

        if (thumbsUpViewRef && thumbsDownViewRef) {
            thumbsUpViewRef.classList.add("no-click");
            thumbsDownViewRef.classList.add("no-click");
            setTimeout(() => {
                thumbsUpViewRef.classList.remove("no-click");
                thumbsDownViewRef.classList.remove("no-click");
            }, 100);
        }
    }, []);

    useEffect(() => {
        ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenAfterRating);
    },[]);

    const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
    const isExperienceCompleted = ZensoryModule.getSessionHandler().isCompleted();
    const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);
    const [active, setActive] = useState();

    const trackEndExperienceWithRating = () => {
        let isUnlimited = props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP;
        const event = new EndExperienceWithRatingAnalyticsEvent(
            props.mood,
            props.zense,
            props.timeLimit,
            isUnlimited,
            props.experienceDuration,
            props.beforeRating,
            props.afterRating,            
            GlobalAppState.getExperienceAnalyticsHolder(),
            props.subZense,
            props.experience
        );
        LOGGER.log(LogLevel.DEBUG, `trackEndExperienceWithRating, event=${JSON.stringify(event.toPlainObj())}`);
        ZensoryModule.getAnalytics().trackEndExperienceWithRating(event);
    };

    const onContinue = (e) => {
        preventDefault(e);

        LOGGER.log(LogLevel.DEBUG, `Review finish() Review experienceDuration=${props.experienceDuration}`);

        // send the experience event with the rating to the analytics
        trackEndExperienceWithRating();

        ZensoryModule.getNavigationHandler().navigate(window, RouteId.AfterExperience, props.setAfterExperience, props.onReview);
    }

    const onClickRatingButton = (e, ratingValue, id) => {
        preventDefault(e);
        props.setAfterRating(ratingValue);
        setActive(id);
    }

    const close = (e) => {
        preventDefault(e);
        setShowCloseAndExitPopup(true);
        ZensoryModule.getNavigationHandler().exit(window, props.sco);
    }

    // handle return key presses on the close and exit button
    const _handleKeyDownCloseExit = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            close();
        }
    }

    // handle return key presses on the thumbs up or down value buttons
    const _handleKeyDownThumbsUpDown = (e, ratingValue, id) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            onClickRatingButton(e, ratingValue, id);
        }
    }

    return (
        <div className="review-container">
            <div className="review-content-container">
                {showCloseAndExitPopup ?
                    <CloseAndExitPopup setShowCloseAndExitPopup={setShowCloseAndExitPopup} />
                    : null
                }
                <div className="header-row-review">
                    {isShowExitButton
                        ? <div className="close"
                            id="btn-review-close"
                            onClick={(e) => close(e)}
                            tabIndex="0"
                            onKeyDown={(e) => _handleKeyDownCloseExit(e)}>
                            <img
                                src={cross}
                                style={{ marginTop: "2px" }}
                                alt="Close and exit"
                            />
                            <span>Close & Exit</span>
                        </div>
                        : <div style={{ height: "80px" }}></div>
                    }
                    <div className="logo-container">
                        <img
                            className="logo"
                            src={logo}
                            alt="Zensory logo" />
                    </div>
                </div>
                {isExperienceCompleted && isShowExitButton
                    ? <img
                        src={check}
                        className="checkmark-review"
                        alt="check/tick icon" />
                    : null
                }
                {isExperienceCompleted
                    ? <h1 className="major-heading-review">
                        <Interweave
                            content={getText(TextId.reviewTitleComplete)}
                        />
                    </h1>
                    : <h1 className="major-heading-review-notcompleted">
                        <Interweave
                            content={getText(TextId.reviewTitleIncomplete)}
                        />
                    </h1>
                }
                {isExperienceCompleted
                    ? <div className="subheading1">
                        {getText(props.mood === MoodId.Focused
                            ? TextId.reviewSubTitle1Focus
                            : TextId.reviewSubTitle1Relax)}
                    </div>
                    : null}
                {isExperienceCompleted
                    ? <div className="subheading2">
                        {getText(TextId.reviewSubtitle2Complete)}
                    </div>
                    : <div className="subheading2-notcompleted">
                        {getText(TextId.reviewSubtitle2Incomplete)}
                    </div>
                }
                {isExperienceCompleted ?
                    <div className="review-rating-row">
                        <div
                            className={active === "btn-negative-rating" ? "rating-box-active" : "rating-box"}
                            id="btn-negative-rating"
                            onClick={(e) => onClickRatingButton(e, "-1", "btn-negative-rating")}
                            tabIndex={0}
                            onKeyDown={(e) => _handleKeyDownThumbsUpDown(e, "-1", "btn-negative-rating")}>

                            <img
                                alt="sad face"
                                src={sad_face}
                                id="btn-negative-rating-img"
                                onClick={(e) => onClickRatingButton(e, "-1", "btn-negative-rating")}
                            />

                            No

                        </div>
                        <div
                            className={active === "btn-positive-rating" ? "rating-box-active" : "rating-box"}
                            id="btn-positive-rating"
                            onClick={(e) => onClickRatingButton(e, "1", "btn-positive-rating")}
                            tabIndex={0}
                            onKeyDown={(e) => _handleKeyDownThumbsUpDown(e, "1", "btn-positive-rating")}>

                            <img
                                alt="happy face"
                                id="btn-positive-rating-img"
                                onClick={(e) => onClickRatingButton(e, "1", "btn-positive-rating")}
                                src={happy_face}
                            />

                            Yes

                        </div>
                    </div> : null}
                <Tooltip content={getText(TextId.reviewTooltipText)} direction="top" rating={props.afterRating}>
                    <div className="continue-button-container">
                        <button
                            id="btn-review-continue"
                            disabled={props.afterRating == null}
                            className="next-button-review"
                            onClick={(e) => onContinue(e)}>
                            Continue
                        </button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

const Review = withScorm()(ReviewPage);

export default Review;
