import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getConfiguration } from './components/Util/ConfigHelper';
import { DeploymentType } from './components/Data/Models/configuration';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (getConfiguration().deploymentType === DeploymentType.Web) {
   root.render(
      <BrowserRouter>
      <App />
      </BrowserRouter>
   );
} else {
   root.render(
      <App />
   );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
