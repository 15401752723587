import expand_fullscreen from "../assets/icons/expand_full_screen.svg"
import reduce_fullscreen from "../assets/icons/reduce_full_screen.svg"
import cross from "../assets/icons/close.svg"
import levels from "../assets/icons/levels@3x.png"
import MultiTrackPlayer from "../components/MultiTrackPlayer";
import { isKeyIn, isSubmitKey, preventDefault, stopPropagation } from "../components/UI/KeyPressHelper";
import { isIOS, isMobile } from "../components/Util/DeviceUtil";
import { getText, TextId } from "../components/Util/TextHelper";
import VolumeSetting from "../components/VolumeSetting";
import { getExperienceButtonTabIndex, isShowExperienceButton, isShowExperienceButtonAux } from "./ExperienceUIHelper";
import { KeyId } from "../components/UI/KeyEnum";

const SoundscapeExperience = (props) => {

    // handle return key presses on fullscreen icon
    const _handleKeyDownFullScreen = (e) => {
        stopPropagation(e);
        if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
            props.toggleFullScreen(e);
        }
    }

    const close = (e) => {
        preventDefault(e);
        props.setCloseExperiencePopup(true);
    }

    // handle return key presses on the close and exit button
    const _handleKeyDownCloseExit = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            close(e);
        }
    }

    // handle return key presses on the close and exit button
    const _handleKeyDownVolume = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            preventDefault(e);
            props.setShowVolumeSetting(true);
        }
    }

    return (
        <>
            {!props.closeExperiencePopup
                ? <div className="close"
                    id="btn-soundscape-exp-close"
                    onClick={(e) => close(e)}
                    tabIndex={getExperienceButtonTabIndex(props)}
                    onKeyDown={(e) => _handleKeyDownCloseExit(e)}
                    role="button"
                    alt={getText(TextId.endExperienceAriaLabel)}
                    aria-label={getText(TextId.endExperienceAriaLabel)}
                >
                    <img
                        src={cross}
                        style={{ marginTop: "2px" }}
                        alt="Close icon"
                    />
                    {!isMobile()
                        ? <span>{getText(TextId.endExperienceText)}</span>
                        : null
                    }
                </div>
                : null
            }
            <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                {props.showVolumeSetting
                    ? <VolumeSetting
                        setShowVolumeSetting={props.setShowVolumeSetting}
                        volume={props.volume}
                        zense={props.zense}
                        subZense={props.subZense}
                        experience={props.experience}
                        binauralVolume={props.binauralVolume}
                        musicVolume={props.musicVolume}
                        naturescapeVolume={props.naturescapeVolume}
                        audioBreathVolume={props.audioBreathVolume}
                        setVolume={props.setVolume}
                        setBinauralVolume={props.setBinauralVolume}
                        setAudioBreathVolume={props.setAudioBreathVolume}
                        setMusicVolume={props.setMusicVolume}
                        setNaturescapeVolume={props.setNaturescapeVolume}
                    />
                    : null
                }
            </div>
            <div className={props.isFullScreen || props.barHeight <= 0
                ? "trackplayer-fullscreen"
                : "trackplayer"} 
            >
                <div className={isMobile() || !isShowExperienceButton(props)
                    ? "track-controls-not-visible"
                    : "track-controls-randomize"}
                >
                    <img
                        id="btn-soundscape-exp-volume"
                        src={levels}
                        height={60}
                        width={60}
                        className="play"
                        onClick={() => props.setShowVolumeSetting(true)}
                        alt="volume"
                        aria-label="volume"
                        role="button"
                        tabIndex={getExperienceButtonTabIndex(props)}
                        onKeyDown={(e) => _handleKeyDownVolume(e)}
                    />
                    <div className="timer">
                        Volume
                    </div>
                </div>
                <MultiTrackPlayer
                    mood={props.mood}
                    zense={props.zense}
                    subZense={props.subZense}
                    experience={props.experience}
                    timeLimit={props.timeLimit}
                    expired={props.expired}
                    isPlaying={props.isPlaying}
                    hasStarted={props.hasStarted}
                    setHasStarted={props.setHasStarted}
                    experienceDuration={props.experienceDuration}
                    volume={props.volume}
                    showVolumeSetting={props.showVolumeSetting}
                    closeExperiencePopup={props.closeExperiencePopup}
                    binauralVolume={props.binauralVolume}
                    musicVolume={props.musicVolume}
                    naturescapeVolume={props.naturescapeVolume}
                    audioBreathVolume={props.audioBreathVolume}
                    setBinauralVolume={props.setBinauralVolume}
                    setAudioBreathVolume={props.setAudioBreathVolume}
                    setMusicVolume={props.setMusicVolume}
                    setNaturescapeVolume={props.setNaturescapeVolume}
                    setVolume={props.setVolume}
                    setIsPlaying={props.setIsPlaying}
                    setExpired={props.setExpired}
                    setExperienceDuration={props.setExperienceDuration}
                    hasLoaded={props.hasLoaded}
                    setHasLoaded={props.setHasLoaded}
                    isFullScreen={props.isFullScreen}
                    setIsFullScreen={props.setIsFullScreen}
                    trackStartExperience={props.trackStartExperience}
                />
                <div className={!isShowExperienceButtonAux(props.isPlaying, props.hasLoaded, props.hasStarted, props.showVolumeSetting, props.closeExperiencePopup) || isIOS()
                    ? "track-controls-not-visible"
                    : "track-controls-randomize"}
                >
                    <img
                        id="btn-soundscape-exp-fullscreen"
                        className="play"
                        tabIndex={0}
                        onClick={(e) => props.toggleFullScreen(e)}
                        onKeyDown={(e) => _handleKeyDownFullScreen(e)}
                        src={props.isFullScreen
                            ? reduce_fullscreen
                            : expand_fullscreen
                        }
                        alt={props.isFullScreen ? "Minimise full screen" : "Expand to full screen"}
                    />
                    <div className="timer">
                    {props.isFullScreen
                        ? "Minimize"
                        : "Full Screen"
                    }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SoundscapeExperience;