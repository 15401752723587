import { getFirebaseHelper } from "../../../components/Util/FirebaseHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";
import { ZensoryCloudFunctionNames } from "../../Data/Models/zensory_api";
import { LicenseApi } from "../../API/LicenseApi";
import { License, UserLicense } from "../../Data/Models/app";
import { getConfiguration } from "../../Util/ConfigHelper";
import { Platform } from "../../Data/constants";

export interface GetUserLicensesRequest {
  userId: string;
}

export interface CompleteSubscriptionRequest {
  userId: string;
  paymentMethod: string;
  plan: string;
  zensoryLicense: string;
  coupon?: string;
}

export interface CancelSubscriptionRequest {
  userId:string,
  subscriptionId: string;
}

export interface OnUserCancelledRequest {
  userId: string,
  licenseId: string,
  platform: Platform
}

export interface CompleteSubscriptionResponse {
  result: {
    clientSecret: string;
    subscriptionId: string;
  }
}

export interface CancelSubscriptionResponse {
  result: {
    subscriptionId: string;
  }
}

export interface OnUserCancelledResponse {
  result: {
    licenses: UserLicense[];
  }
}
export interface GetAvailableLicensesRequest {
  userId: string;
  platform: string;
  version: string
}
export interface GetStripeCouponRequest {
  userId: string;
  coupon: string;
  plan: string
}

export interface OnUserSubsrcibedRequest {
  userId: string;
  platform: string;
  licenseId: string;
  subscriptionId: string;
}

export interface RedeemCodeRequest {
  userId: string;
  redeemCode: string;
  platform: Platform;
}

export interface GetAvailableLicensesResponse {
  result: {
    licenses: License[];
  }
}

export interface GetUserLicensesResponse {
  result: {
    licenses: UserLicense[];
  }
}

export interface OnUserSubsrcibedResponse {
  result: {
    licenses: UserLicense[];
  }
}

export interface RedeemCodeResponse {
  result: {
    licenses: UserLicense[];
  }
}

export class FirebaseLicensesImpl implements LicenseApi
{
  async getStripeCoupon(
    userId:string,
    coupon: string,
    plan: string
  ): Promise<any>
  {
    const payload: GetStripeCouponRequest = {
      userId,
      coupon,
      plan
    };
    
    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.GetStripeCoupon,
          payload
        )
      );

      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.getStripeCoupon() response=${JSON.stringify(response)}`
      );
      return response;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `onUserSubsrcibed error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to check the coupon right now. Please try again later.");
    }
  }

  async onUserSubsrcibed(
    userId: string,
    platform: string,
    licenseId: string,
    subscriptionId: string
  ): Promise<UserLicense[]>
  {
    const payload: OnUserSubsrcibedRequest = {
      userId,
      platform,
      licenseId,
      subscriptionId
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.OnUserSubscribed,
          payload
        )
      ) as OnUserSubsrcibedResponse;

      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.onUserSubsrcibed() response=${JSON.stringify(response)}`
      );
      return response.result.licenses;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `onUserSubsrcibed error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to complete your subscriptions right now. Please try again later.");
    }
  }

  async completeSubscription(
    userId: string,
    paymentMethod: string,
    plan: string,
    zensoryLicense: string,
    coupon: string
  ): Promise<CompleteSubscriptionResponse>
  {
    const payload: CompleteSubscriptionRequest = {
      userId,
      paymentMethod,
      plan,
      zensoryLicense,
      coupon
    };  
    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.CompleteSubscription,
          payload
        )
      ) as CompleteSubscriptionResponse;
      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.completeSubscription() response=${JSON.stringify(
          response
        )}`
      );
      return { result: {"clientSecret": response.result.clientSecret, "subscriptionId": response.result.subscriptionId} };

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `completeSubscription error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to complete your subscriptions right now. Please try again later.");
    }
  }

  async cancelSubscription(
    userId:string,
    subscriptionId:string
  ): Promise<CancelSubscriptionResponse>
  {
    const payload: CancelSubscriptionRequest = {
      userId,
      subscriptionId,
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.CancelSubscription,
          payload
        )
      ) as CancelSubscriptionResponse;
      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.cancelSubscription() response=${JSON.stringify(response)}`
      );
      return { 
        result: {
          "subscriptionId": response.result.subscriptionId
        }
      };

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `cancelSubscription error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to complete your subscriptions right now. Please try again later.");
    }
  }

  async onUserCancelled(
    userId:string,
    platform: Platform,
    licenseId: string
  ): Promise<UserLicense[]>
  {
    const payload: OnUserCancelledRequest = {
      userId,
      platform,
      licenseId
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.OnUserCancelledSubscription,
          payload
        )
      ) as OnUserCancelledResponse;
      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.cancelSubscription() response=${JSON.stringify(response)}`
      );
      return response.result.licenses;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `cancelSubscription error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to complete your subscriptions right now. Please try again later.");
    }
  }

  async getAvailableLicenses(
    userId: string
  ): Promise<License[]>
  {
    const payload: GetAvailableLicensesRequest = {
      userId,
      platform: "web",
      version: getConfiguration().version
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.GetAvailableLicences,
          payload
        )
      ) as GetAvailableLicensesResponse;

      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.getAvailableLicenses() response=${JSON.stringify(response)}`
      );
      return response.result.licenses;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `getAvailableLicenses error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to check your subscriptions right now. Please try again later.");
    }
  }

  async getUserLicense(
    userId: string
  ): Promise<UserLicense[]>
  {
    const payload: GetUserLicensesRequest = {
      userId
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.GetUserLicensesWeb,
          payload
        )
      ) as GetUserLicensesResponse;

      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.getUserLicense() response=${JSON.stringify(response)}`
      );

      let active = [];
      if (response) {
        for (var userLicense of response.result.licenses) {
          if (userLicense.expiresAt > Date.now()) {
            active.push(userLicense);
          }
        }
      }
      return active;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `getUserLicense error${JSON.stringify(error)}`
      );
      throw new Error("Sorry. Unable to check your subscriptions right now. Please try again later.");
    }
  }

  async redeemCode(
    userId: string,
    redeemCode: string,
    platform: Platform
  ): Promise<UserLicense[]>
  {
    const payload: RedeemCodeRequest = {
      userId,
      redeemCode,
      platform
    };

    try {
      const response = (await getFirebaseHelper()
        .callCloudFunction(
          ZensoryCloudFunctionNames.RedeemCode,
          payload
        )
      ) as RedeemCodeResponse;

      LOGGER.log(
        LogLevel.DEBUG,
        `FirebaseLicensesImpl.redeemCode() response=${JSON.stringify(response)}`
      );
      return response.result.licenses;

    } catch (error) {
      LOGGER.log(
        LogLevel.ERROR,
        `redeemCode error${JSON.stringify(error)}`
      );
      throw new Error(`${error}`);
    }
  }

}
