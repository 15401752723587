import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import cross from "../assets/icons/close.svg";
import stripePower from "../assets/icons/stripeLogo.svg";
import {
  isSubmitKey,
  preventDefault,
  stopPropagation,
} from "./UI/KeyPressHelper";
import { useRef, useState } from "react";
import { GlobalAppState, getGlobalAppState } from "./AppState";
import { ZensoryModule } from "../ZensoryModule";
import { TextId, getText } from "./Util/TextHelper";
import { RouteId } from "../Routes";
import SpinnerLoader from "./SpinnerLoader";
import { AnalyticMainEventName, AnalyticsScreenName } from "./API/AnalyticsApi";
import { EnvId, Platform } from "./Data/constants";
import { isNullOrEmptyTrimmed } from "./Data/Util/util";
import { getConfiguration } from "./Util/ConfigHelper";
import { LOGGER, LogLevel } from "./Util/Logger";

const PaymentPopup = (props) => {
  const [success, setSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);

  const [coupon, setCoupon] = useState();
  const [couponResponse, setCouponResponse] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const createSubscription = async () => {
    setLoading(true);
    setSubscriptionError(null);
    try {
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: GlobalAppState.getUser().name,
        },
      });

      // call the backend to create subscription
      const responseSubscription =
        await ZensoryModule.getLicenses().completeSubscription(
          getGlobalAppState().getUser().userId,
          paymentMethod?.paymentMethod?.id,
          props.plan.id,
          props.selectedZenLicense,
          couponResponse && couponResponse.result && couponResponse.result.success ? coupon: null
        );

      // confirm the payment by the user
      const confirmPayment = await stripe?.confirmCardPayment(
        responseSubscription.result.clientSecret
      );

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        const response = await ZensoryModule.getLicenses().onUserSubsrcibed(
          getGlobalAppState().getUser().userId,
          Platform.Web,
          props.selectedZenLicense,
          responseSubscription.result.subscriptionId
        );
        if (response) {
          ZensoryModule.getAnalytics().trackEvent(
            AnalyticMainEventName.UserStartSubscription,
            {
              subscriptionID: responseSubscription.result.subscriptionId,
              type: props.plan.recurring.interval,
              licenseId: response.licenseId,
              amount: props.plan.unit_amount / 100,
              currency: props.plan.currency,
              source: AnalyticsScreenName.ScreenSubscribe,
            }
          );
          setSuccess(true);
        }
      }
    } catch (error) {
      ZensoryModule.getErrorHandler().handleError(error);
      LOGGER.log(LogLevel.ERROR, error);
      setSubscriptionError(
        `Sorry, something went wrong. Please try again later.${
          getConfiguration().envId === EnvId.DEV
            ? `[Server ${error}]`
            : ""
          }`
      );
      setLoading(false);
      setSuccess(false);
    }
  };

  const processCoupon = async ()=>
  {
    try {
      if (isNullOrEmptyTrimmed(coupon)) {     
        setCouponResponse({ error: true, message: "Coupon is empty" });
        return;
      }

      setCouponLoading(true);
      const response = await ZensoryModule.getLicenses().getStripeCoupon(
        getGlobalAppState().getUser().userId,
        coupon,
        props.plan.id
      );
      setCouponResponse(response);
    } catch (e) {
      LOGGER.log(LogLevel.ERROR, e);
    }
    setCouponLoading(false);
  }

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const modalRef = useRef();

  const navigateToApp = () => {
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
  };

  const closePopup = (e) => {
    preventDefault(e);
    props.setShowPaymentPopup(false);
  };

  // handle return key presses on the close popup icon
  const _handleKeyDownClose = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      closePopup(e);
    }
  };

  if (success)
    return (
      <div className="popup-container-payment" ref={modalRef}>
        <div className="popup-header">
          <img
            id="btn-auth-error-popup-close"
            src={cross}
            className="popup-close-icon"
            onClick={(e) => closePopup(e)}
            alt="Close popup"
            tabIndex="0"
            onKeyDown={(e) => _handleKeyDownClose(e)}
          />
        </div>
        <div className="popup-success-container">
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <div>{getText(TextId.subscribeSuccess)}</div>
        </div>

        <button
          disabled={!stripe}
          id="btn-auth-error-popup-okay"
          tabIndex="0"
          className="okay-button"
          onClick={navigateToApp}
        >
          Continue
        </button>
      </div>
    );

  return (
    <div className="popup-container-payment" ref={modalRef}>
      <div className="popup-header">
        <img
          id="btn-auth-error-popup-close"
          src={cross}
          className="popup-close-icon"
          onClick={(e) => closePopup(e)}
          alt="Close popup"
          tabIndex="0"
          onKeyDown={(e) => _handleKeyDownClose(e)}
        />
      </div>
      <div className="popup-instruction-payment">
        {getText(TextId.subscribePayment)}
      </div>
      <div className="payment-field">
        <label>Card Number</label>
        <div className="card-entry">
          <CardNumberElement options={cardElementOptions} />
        </div>
      </div>
      <div className="expiry-cvc-entry">
        <div className="payment-field">
          <label>Expiry Date</label>
          <div className="card-entry">
            <CardExpiryElement options={cardElementOptions} />
          </div>
        </div>
        <div className="payment-field">
          <label>CVC</label>
          <div className="card-entry">
            <CardCvcElement options={cardElementOptions} />
          </div>
        </div>
      </div>
      <div className="expiry-cvc-entry">
        <div className="coupon-entry">
          <input 
            onChange={(e)=>{setCoupon(e.target.value)}}
            value={coupon}
            type="text"
            placeholder="Coupon Code"
          />
        </div>
        <div onClick={processCoupon} className="apply-button">
          {couponLoading
            ? <div style={{position:"relative"}}>
                <SpinnerLoader/>
              </div>
            : "Apply Code"
          }
        </div>
      </div>
      {couponResponse && couponResponse.error 
        ? <div className="couponerror">
          { !isNullOrEmptyTrimmed(couponResponse.message)
            ? couponResponse.message
            : "Coupon code not valid"
          }
          </div>
        : couponResponse && couponResponse.result?.success
          ? <div className="couponsuccess">
            Success! Coupon applied when you subscribe. {couponResponse.result.name}
            </div>
          : null
      }
      {loading 
        ? (
          <div
            style={{
              position: "relative",
              marginBottom: "40px",
              marginTop: "10px",
            }}
          >
            <SpinnerLoader />
          </div>
        ) 
        : (
          <button
            disabled={!stripe || loading}
            id="btn-auth-error-popup-okay"
            tabIndex="0"
            className="subscribe-button"
            onClick={createSubscription}
          >
            Subscribe
          </button>
        )}
        <div className="couponerror">
          { subscriptionError
            ? subscriptionError
            : null
          }
        </div>

      <div>
        <img src={stripePower} alt="Powered by Stripe" />
      </div>
    </div>
  );
};

export default PaymentPopup;
