import React, { useEffect } from "react";
import "../styles/cones.css"

const Cones = (props) => {
  const url = `cones.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('startCones');
    startDiv.click();
    const stopDiv = document.getElementById('stopCones');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);
  
  return (
    <>
      <div id="stopCones"></div>
      <div id="startCones"></div>
      <canvas id="fg" className="cones"></canvas>
    </>
  );
}

export default Cones;