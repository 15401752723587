import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "react-circular-progressbar/dist/styles.css";
import { MoodColors, MoodId } from "./Data/constants";

export const MoodInsights = ({ moodStats }) => {
  const gradientTransform = `rotate(90)`;

  // return CircularProgressbarWithChildren nested object with grident applied and icon and tag
  const getSingleProgressBar = (
    mood,
    startColor,
    endColor,
    progress
  ) => {
    return (
      <div className="single-progress-container">
        <svg style={{ height: 0, width: 0 }}>
          <defs>
            <linearGradient
              id={mood + "progress"}
              gradientTransform={gradientTransform}
            >
              <stop offset="0%" stopColor={startColor} />
              <stop offset="100%" stopColor={endColor} />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgressbarWithChildren
          strokeWidth={12}
          value={progress}
          maxValue="1"
          styles={{
            path: { stroke: `url(#${mood}progress)`, height: "100%" },
            trail: { stroke: "#FFFFFF33" },
          }}
        >
          <img
            className="mood-stat-icon"
            src={require(`../assets/icons/ic_mood_${mood}.png`)}
            alt={mood + " icon"}
            height={50}
          />
        </CircularProgressbarWithChildren>
        <h3 className="mood-tag">{mood}</h3>
      </div>
    );
  };

  // setting for Slider library
  var settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  };

  return (
    <div className="profile-box">
      <h1 className="profile-box-heading">Mood Insights</h1>
      <div className="slider-container">
        <Slider {...settings}>
          <div className="period-container">
            <div className="insight-stats-container">
              {getSingleProgressBar(
                MoodId.Positive,
                MoodColors.PostiveStart,
                MoodColors.PositiveEnd,
                moodStats.statsByDay.positive,
              )}
              {getSingleProgressBar(
                MoodId.Relax,
                MoodColors.RelaxStart,
                MoodColors.RelaxEnd,
                moodStats.statsByDay.relax,
              )}
              {getSingleProgressBar(
                MoodId.Focused,
                MoodColors.FocusedStart,
                MoodColors.FocusedEnd,
                moodStats.statsByDay.focused,
              )}
              {getSingleProgressBar(
                MoodId.Creative,
                MoodColors.CreativeStart,
                MoodColors.CreativeEnd,
                moodStats.statsByDay.creative,
              )}
            </div>
            <div>
              <h3>Today</h3>
            </div>
          </div>
          <div className="period-container">
            <div className="insight-stats-container">
              {getSingleProgressBar(
                MoodId.Positive,
                MoodColors.PostiveStart,
                MoodColors.PositiveEnd,
                moodStats.statsByWeek.positive,
              )}
              {getSingleProgressBar(
                MoodId.Relax,
                MoodColors.RelaxStart,
                MoodColors.RelaxEnd,
                moodStats.statsByWeek.relax,
              )}
              {getSingleProgressBar(
                MoodId.Focused,
                MoodColors.FocusedStart,
                MoodColors.FocusedEnd,
                moodStats.statsByWeek.focused,
              )}
              {getSingleProgressBar(
                MoodId.Creative,
                MoodColors.CreativeStart,
                MoodColors.CreativeEnd,
                moodStats.statsByWeek.creative,
              )}
            </div>
            <div>
              <h3>This Week</h3>
            </div>
          </div>
          <div className="period-container">
            <div className="insight-stats-container">
              {getSingleProgressBar(
                MoodId.Positive,
                MoodColors.PostiveStart,
                MoodColors.PositiveEnd,
                moodStats.statsByMonth.positive,
              )}
              {getSingleProgressBar(
                MoodId.Relax,
                MoodColors.RelaxStart,
                MoodColors.RelaxEnd,
                moodStats.statsByMonth.relax,
              )}
              {getSingleProgressBar(
                MoodId.Focused,
                MoodColors.FocusedStart,
                MoodColors.FocusedEnd,
                moodStats.statsByMonth.focused,
              )}
              {getSingleProgressBar(
                MoodId.Creative,
                MoodColors.CreativeStart,
                MoodColors.CreativeEnd,
                moodStats.statsByMonth.creative,
              )}
            </div>
            <div>
              <h3>This Month</h3>
            </div>
          </div>
          <div className="period-container">
            <div className="insight-stats-container">
              {getSingleProgressBar(
                MoodId.Positive,
                MoodColors.PostiveStart,
                MoodColors.PositiveEnd,
                moodStats.allTime.positive
              )}
              {getSingleProgressBar(
                MoodId.Relax,
                MoodColors.RelaxStart,
                MoodColors.RelaxEnd,
                moodStats.allTime.relax
              )}
              {getSingleProgressBar(
                MoodId.Focused,
                MoodColors.FocusedStart,
                MoodColors.FocusedEnd,
                moodStats.allTime.focused
              )}
              {getSingleProgressBar(
                MoodId.Creative,
                MoodColors.CreativeStart,
                MoodColors.CreativeEnd,
                moodStats.allTime.creative
              )}
            </div>
            <div>
              <h3>All Time</h3>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};
