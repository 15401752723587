import { Binaural, Colour, Experience, ExperienceLabels, ExperienceProperties, InfoPage, InspirationalQuote, Mood, Music, Sense, SenseContent, Soundscape, Touchpad } from "../Models/app";
import { ColourId, ExperienceCategory, ZensoryExperienceId, SoundscapeId, AudioDurationSecs, ColourValues, EnvId, SenseId, getSenseTitle, SenseContentClickAction, MoodId, MoodTitle, InfoPageId, getMoodIdFromExperienceId, ContentSetupId, getSoundscapeTitle, isMoodZoneIn, TouchpadId } from "../constants";
import { getExperienceSense } from "./data_experience_senses";
import { getExperienceExpert } from "./data_experts";
import { getEnumValues, listContains } from "../Util/util";

///////////
// Moods //
///////////

export function getCreativeExperience(envId: EnvId): Experience {
    const experienceId = ZensoryExperienceId.Creative;
    const moodId = getMoodIdFromExperienceId(experienceId);

    const labels: ExperienceLabels = {
        name: "Creative",
        noun: "creative",
        adjective: "creativity",
        endQuestionTitle: "How creative are you feeling right now?",
        questionTitleHtml: "Get creative",
        shortDesc: "This experience will allow you to boost your creativity and tap into your inner artist",
    };
    const props: ExperienceProperties = {categoryId: ExperienceCategory.Mood, colourId: ColourId.Green, orderNum: 0, iconFileUrlOverride: null, isZoneIn: isMoodZoneIn(moodId), moodImageUrl: getMoods()[moodId].iconWithColorUrl};
    const affirmations = ['Creative possibilities are all around me and I embrace them', 'My senses ignite and ideas come to me easily', 'I am proud of everything that I achieve', 'Creative inspiration and ideas always surround me', 'I am the architect of my life; I create whatever I choose', 'I generate wonderful creative ideas and projects full of inspiration'];

    const binaurals: {[key in AudioDurationSecs]?: Binaural} = getBinauralForExperience(experienceId);
    const music: {[key in AudioDurationSecs]?: Music} = getMusicForExperience(experienceId);

    return {
        experienceId: experienceId,
        enabled: true, 
        labels: labels,
        properties: props, 
        affirmations: affirmations,
        binaurals: binaurals,
        music: music,
        senses: getExperienceSense(envId, getMoodIdFromExperienceId(experienceId)),
        experts: getExperienceExpert(getMoodIdFromExperienceId(experienceId)),
        urlQueryArgs: getQueryArgsForExperience(experienceId),
    };
}

export function getFocusedExperience(envId: EnvId): Experience {
    const experienceId = ZensoryExperienceId.Focus;
    const moodId = getMoodIdFromExperienceId(experienceId);

    const labels: ExperienceLabels = {
        name: "Focus",
        noun: "focused",
        adjective: "focusing",
        endQuestionTitle: "How focused are you feeling right now?",
        questionTitleHtml: "Get focused",
        shortDesc: "This experience will allow you to get into deep focus and boost your performance",
    };
    const props: ExperienceProperties = {categoryId: ExperienceCategory.Mood, colourId: ColourId.Red, orderNum: 1, iconFileUrlOverride: null, isZoneIn: isMoodZoneIn(moodId), moodImageUrl: getMoods()[moodId].iconWithColorUrl};
    const affirmations = ['I create the life that I want with even the smallest steps', 'I am effective in achieving all that I want in my life', 'I can do and have anything I put my mind to', 'I know my intuition will always take me in the right direction.', 'My potential to succeed is infinite', 'Today, I only take up new, positive habits', 'I am free of all regrets'];

    const binaurals: {[key in AudioDurationSecs]?: Binaural} = getBinauralForExperience(experienceId);
    const music: {[key in AudioDurationSecs]?: Music} = getMusicForExperience(experienceId);

    return {
        experienceId: experienceId,
        enabled: true, 
        labels: labels,
        properties: props, 
        affirmations: affirmations,
        binaurals: binaurals,
        music: music,
        senses: getExperienceSense(envId, getMoodIdFromExperienceId(experienceId)),
        experts: getExperienceExpert(getMoodIdFromExperienceId(experienceId)),
        urlQueryArgs: getQueryArgsForExperience(experienceId),
    };
}

export function getRelaxExperience(envId: EnvId): Experience {
    const experienceId = ZensoryExperienceId.Relax;
    const moodId = getMoodIdFromExperienceId(experienceId);

    const labels: ExperienceLabels = {
        name: "Relax",
        noun: "relax",
        adjective: "relaxing",
        endQuestionTitle: "How relaxed are you feeling right now?",
        questionTitleHtml: "Get relaxed",
        shortDesc: "This experience will allow you to relax deeply and recalibrate",
    };
    const props: ExperienceProperties = {categoryId: ExperienceCategory.Mood, colourId: ColourId.Blue, orderNum: 2, iconFileUrlOverride: null, isZoneIn: isMoodZoneIn(moodId), moodImageUrl: getMoods()[moodId].iconWithColorUrl};
    const affirmations = ['I trust that I am truly taken care of', 'I embrace the rhythm of life and let it unfold easily and joyously', 'I relax more and more every day', 'I am free of any worries and doubts - I am positive and strong', 'I am full of energy and vitality - I am calm and peaceful', 'My day unfolds with ease and grace'];

    const binaurals: {[key in AudioDurationSecs]?: Binaural} = getBinauralForExperience(experienceId);
    const music: {[key in AudioDurationSecs]?: Music} = getMusicForExperience(experienceId);

    return {
        experienceId: experienceId,
        enabled: true, 
        labels: labels,
        properties: props, 
        affirmations: affirmations,
        binaurals: binaurals,
        music: music,
        senses: getExperienceSense(envId, getMoodIdFromExperienceId(experienceId)),
        experts: getExperienceExpert(getMoodIdFromExperienceId(experienceId)),
        urlQueryArgs: getQueryArgsForExperience(experienceId),
    };
}

export function getPositiveExperience(envId: EnvId): Experience {
    const experienceId = ZensoryExperienceId.Positive;
    const moodId = getMoodIdFromExperienceId(experienceId);

    const expPositiveLabels: ExperienceLabels = {
        name: "Positive",
        noun: "positive",
        adjective: "positivity",
        endQuestionTitle: "How positive are you feeling right now?",
        questionTitleHtml: "Feel positive",
        shortDesc: "This experience will allow you to boost your mood and feel uplifted",
    };
    const expPositiveProps: ExperienceProperties = {categoryId: ExperienceCategory.Mood, colourId: ColourId.Orange, orderNum: 3, iconFileUrlOverride: null, isZoneIn: isMoodZoneIn(moodId), moodImageUrl: getMoods()[moodId].iconWithColorUrl};
    const expPositiveAffirmations = ['My body is healthy, my mind tranquil and my soul vibrant', 'I release any negativity & simply let it go. I attract positivity.', 'Tоdау, I аm energised and joyful', 'I attract only good relationships in my life', 'I appreciate the little things in life', 'Good things happen all around me', 'I have joy into my life', 'Today, I appreciate all the good things around me'];

    const expPositiveBinaurals: {[key in AudioDurationSecs]?: Binaural} = getBinauralForExperience(experienceId);
    const music: {[key in AudioDurationSecs]?: Music} = getMusicForExperience(experienceId);

    return {
        experienceId: experienceId,
        enabled: true, 
        labels: expPositiveLabels,
        properties: expPositiveProps, 
        affirmations: expPositiveAffirmations,
        binaurals: expPositiveBinaurals,
        music: music,
        senses: getExperienceSense(envId, getMoodIdFromExperienceId(experienceId)),
        experts: getExperienceExpert(getMoodIdFromExperienceId(experienceId)),
        urlQueryArgs: getQueryArgsForExperience(experienceId),
    };
}

export function getExperiencesArray(envId: EnvId): Experience[] {
    return [ 
        getCreativeExperience(envId), 
        getFocusedExperience(envId), 
        getPositiveExperience(envId), 
        getRelaxExperience(envId)
    ];
}

export function getQueryArgsForExperience(expId: ZensoryExperienceId): {[arg: string]: string} {
    const args: {[arg: string]: string} = {};
    args['moodId'] = getMoodIdFromExperienceId(expId);
    return args;
}

/////////////////
// Soundscapes //
/////////////////

const DEV_SOUNDSCAPE_SELECT_IMAGES: {[soundscapeId in SoundscapeId]: string} = {
    none: "",
    noNature: "", // TODO
    beach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fbeach_select.jpeg?alt=media&token=a47fe9c6-9728-47dd-a5de-7116e29501fd",
    forest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fforest_select.jpeg?alt=media&token=d0113c12-d8fb-447c-9eef-ced50e9b5cfa",
    tropicalBeach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ftropical_beach_select.jpeg?alt=media&token=ec4faaad-33e9-4c54-a78d-4b79528fdd3e",
    meadow: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fmeadow_select.jpeg?alt=media&token=bd6963ce-ec0c-4952-b7e5-401c1769a2fa",
    rainforest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frainforest_select.jpeg?alt=media&token=3931e9f3-40c4-4207-bd77-6821bb644fdc",
    fireplace: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ffireplace_select.jpg?alt=media&token=dffcf112-65e7-4e57-b904-d53a0a075227",
    garden: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fgarden_select.jpg?alt=media&token=7713f1a7-3291-4c5b-9ddd-665de1ae976f",
    rain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frain_select.jpg?alt=media&token=c3006070-5536-49c9-9ed7-7a72f89a65ab",
    whiteNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fwhitenoise_select.jpg?alt=media&token=f0a81da1-e35e-4b25-bbd8-1250ce9edf83",
    brownNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2FbrownNoise.jpg?alt=media&token=1aa3d620-9804-45e0-8c52-cfc7b8ba1a2b",
    train: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_train_select.jpg?alt=media&token=b65987c6-6e28-4f9e-b52f-c1bf631509d7",
    ocean: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_ocean_select.jpg?alt=media&token=56a690f6-c345-4b57-b99e-ac12e955d8e6",
    mountain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_mountain_select.jpg?alt=media&token=72ee6be3-5c7e-4a80-9b22-98491ff85e13",
    babblingBrook: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_babbling_brook_select.jpg?alt=media&token=a6c39bad-eea2-4da7-b743-61b1593a868a",
    lakeAtNight: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_lake_at_night_select.jpg?alt=media&token=30f9b1d3-10a9-45a6-8a6a-0805acf46afc",
    desert: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_desert_select.jpg?alt=media&token=4cc46dd3-2341-439f-9f59-8064ba36c5f5"

};

const SOUNDSCAPE_SELECT_IMAGES: {[envId in EnvId]: {[soundscapeId in SoundscapeId]: string}} = {
    DEV: DEV_SOUNDSCAPE_SELECT_IMAGES,
    RESEARCH_UCL: DEV_SOUNDSCAPE_SELECT_IMAGES,
    PROD: DEV_SOUNDSCAPE_SELECT_IMAGES,
}

export function getMusicForExperience(experienceId: ZensoryExperienceId): {[key in AudioDurationSecs]?: Music} {
    switch (experienceId) {
        case ZensoryExperienceId.Creative:
            return {
                180: { musicId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Fcreativity--music-03min_v03.mp3?alt=media&token=2ba6cc1d-e62b-4cd3-b1a9-454593b357ba'},
                600: { musicId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Fcreativity--music-10min_v02.mp3?alt=media&token=0e7ea28d-50cd-498d-8b13-0523815e3f82'},
                1200: { musicId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Fcreativity--music-20min_v02.mp3?alt=media&token=e209a434-7e42-498d-b37c-f9ddfb3c2379'}
            };
        case ZensoryExperienceId.Focus:
            return {
                180: { musicId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Ffocus--music-3min_v02.mp3?alt=media&token=40c02e2e-cca4-45c2-b77d-3702621aa3db'},
                600: { musicId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Ffocus--music-10min_v02.mp3?alt=media&token=1d183fa9-fefa-4eeb-ace4-6f04647a133c'},
                1200: { musicId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Ffocus-music-20min-v02.mp3?alt=media&token=c6798c85-d14d-4e94-bb28-1f34e8036d05'}
            };
        case ZensoryExperienceId.Relax:
            return {
                180: { musicId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Frelax-music-3min-v02.mp3?alt=media&token=b8fd7fa7-6357-4070-93f6-5c9c0cb7bdc8'},
                600: { musicId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Frelax-music-10min-v02.mp3?alt=media&token=a958ccf9-9ae8-41a1-8ba7-8262855a21d7'},
                1200: { musicId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Frelax-music-20min-v02.mp3?alt=media&token=d3680eb1-068e-477e-bf60-371caa21375c'}
            };
        case ZensoryExperienceId.Positive:
            return {
                180: { musicId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2FPositivity--music-3min_v02.mp3?alt=media&token=980d03d0-5d8b-4881-85ae-bd7628ea5be4'},
                600: { musicId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Fpositivity-music-10min-v03.mp3?alt=media&token=ad4efb45-15ab-494e-aaad-5e173e5e66d9'},
                1200: { musicId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fmusic%2Fpositivity-music-20min-v02.mp3?alt=media&token=25a3645d-b607-4698-858e-14ada426de26'}
            };
    }
}

export function getBinauralForExperience(experienceId: ZensoryExperienceId): {[key in AudioDurationSecs]?: Binaural} {
    switch (experienceId) {
        case ZensoryExperienceId.Creative:
            return {
                180: { binauralId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fcreativity_binaural_03min.mp3?alt=media&token=25224086-32d6-4c8f-a51b-8d676a7f79ef'},
                600: { binauralId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fcreativity_binaural_10min.mp3?alt=media&token=5120ae35-86b1-41f0-9351-20174a5bb256'},
                1200: { binauralId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fcreativity_binaural_20min.mp3?alt=media&token=43da210c-d9ba-480f-885b-9b283257571e'},
            };
        case ZensoryExperienceId.Focus:
            return {
                180: { binauralId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Ffocus--binaural-03min--v02.mp3?alt=media&token=1b5078d9-8a4e-4437-b5d6-d63d1b84c272'},
                600: { binauralId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Ffocus--binaural-10min--v02.mp3?alt=media&token=2f5bcc22-f5d6-43a9-b02d-692e5f15e45c'},
                1200: { binauralId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Ffocus--binaural-20min--v02.mp3?alt=media&token=b2bc6f3f-2f34-4165-b72a-111dbb6e7bfa'},
            };
        case ZensoryExperienceId.Relax:
            return {
                180: { binauralId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Frelax_binaural_3min.mp3?alt=media&token=2eb3e9e0-05da-4373-8008-6a38fa0fe701'},
                600: { binauralId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Frelax--binaural-10min--v02.mp3?alt=media&token=db941edf-3d3f-4d2e-bfe7-e0f91a291a0d'},
                1200: { binauralId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Frelax--binaural-20min--v02.mp3?alt=media&token=50131307-4b18-48ce-bd5f-fb35768c41e7'}
            };
        case ZensoryExperienceId.Positive:
            return {
                180: { binauralId: "standard3min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.THREE_3_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fpositivity--binaural-3min--v20-3-4.mp3?alt=media&token=187e77b8-68ed-4653-b84f-b4624e488123'},
                600: { binauralId: "standard10min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TEN_10_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fpositivity-binaural-10min.mp3?alt=media&token=a0ac9e6f-8ac4-4f8d-89d9-44ddc82de2b3'},
                1200: { binauralId: "standard20min", experienceId: experienceId, enabled: true, lengthSecs: AudioDurationSecs.TWENTY_20_MINS, audioUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExact%2Fbinaural%2Fpositivity_binaural_20min.mp3?alt=media&token=ca879be9-5321-4887-b4fd-00ca4273f928'},
            };
    }
}

const DEV_SOUNDSCAPE_BACKGROUND_IMAGES: {[soundscapeId in SoundscapeId]: string} = {
    none: "",
    noNature: "", // TODO
    beach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fbeach_background.jpeg?alt=media&token=314bf9eb-04a3-45b5-aee8-50f26f29cbd8",
    forest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fforest_background.jpg?alt=media&token=8c56b02a-7953-4231-b015-d8a04e920bab",
    tropicalBeach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ftropical_beach_background.jpg?alt=media&token=8aa6573a-5568-4965-aaf7-c25f718041d4",
    meadow: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fmeadow_background.jpg?alt=media&token=2a926592-357c-487b-81e0-f86ac16772c3",
    rainforest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frainforest_background.jpg?alt=media&token=fb8436a8-d43a-49cf-943f-adf8176445f9",
    fireplace: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ffireplace_background.jpg?alt=media&token=a65d0f43-6156-4f2a-b14c-3879372f45fd",
    garden: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fgarden_background.jpg?alt=media&token=5ef189ee-bd71-41b2-9c37-a7b45aad2539",
    rain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frain_background.jpg?alt=media&token=210d4337-f022-470f-b7c6-831cccfd4560",
    whiteNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fwhitenoise_background.jpg?alt=media&token=e1390321-6c41-467b-a321-a3ce9fd495f0",
    brownNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2FbrownNoise.jpg?alt=media&token=1aa3d620-9804-45e0-8c52-cfc7b8ba1a2b",
    train: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_train_background.jpg?alt=media&token=fc899264-8d92-4599-9b5f-c0675a72a091",
    ocean: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_ocean_background.jpg?alt=media&token=8c1094f8-82a5-4743-90d7-24c20cd5aaa8",
    mountain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_mountain_background.jpg?alt=media&token=cf72495b-c986-425b-bd8a-9fd0237236f5",
    babblingBrook: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_babbling_brook_background.jpg?alt=media&token=d21f9a75-e661-4e27-8af4-e5fdaea81c4a",
    lakeAtNight: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_lake_at_night_background.jpg?alt=media&token=a2aa3143-1077-4fbf-8abd-a702c8af098f",
    desert: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_desert_background.jpg?alt=media&token=35ff4b58-6490-4219-84b4-90a0c9355347"
};

const DEV_SOUNDSCAPE_BACKGROUND_LANDSCAPE_IMAGES: {[soundscapeId in SoundscapeId]: string} = {
    none: "",
    noNature: "", // TODO:
    beach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fbeach_background_landscape.jpg?alt=media&token=04e01a32-a2d2-4321-99a3-2302122f4a3c",
    forest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fforest_background_landscape.jpg?alt=media&token=9a8ac804-c3c3-44cf-bdf4-95c785f31e3c",
    tropicalBeach: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ftropical_beach_background_landscape.jpg?alt=media&token=f1ea3d6d-a29e-46a8-9da4-715fcda940e0",
    meadow: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fmeadow_background_landscape.jpg?alt=media&token=55a6f23c-a928-4571-a1b5-5f4d6e280adc",
    rainforest: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frainforest_background_landscape.jpg?alt=media&token=2c3a5465-2403-4fe7-b5c7-387f14284448",
    fireplace: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Ffireplace_background_landscape.jpg?alt=media&token=dba909da-ecc2-41c4-8ef1-97e816259d88",
    garden: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fgarden_background_landscape.jpg?alt=media&token=a29100ed-bd59-4254-acf2-07a65cf4d634",
    rain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Frain_background_landscape.jpg?alt=media&token=f1bb6d2d-2f63-4cb5-b86d-f29068bb18ff",
    whiteNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fwhitenoise_select.jpg?alt=media&token=f0a81da1-e35e-4b25-bbd8-1250ce9edf83",
    brownNoise: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2FbrownNoise.jpg?alt=media&token=1aa3d620-9804-45e0-8c52-cfc7b8ba1a2b",
    train: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_train_background_landscape.jpg?alt=media&token=23ad85c1-37eb-480c-81eb-46c94c8ffaeb",
    ocean: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_ocean_background_landscape.jpg?alt=media&token=2350b1d6-9608-4aa3-9046-f064c43cec24",
    mountain: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_mountain_background_landscape.jpg?alt=media&token=7bb91324-81b7-4af4-9a83-6deb43ab2c1c",
    babblingBrook: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_babbling_brook_background_landscape.jpg?alt=media&token=902b443c-d89f-4fac-be31-75afe40e9413",
    lakeAtNight: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_lake_at_night_background_landscape.jpg?alt=media&token=ff4ecf76-5b19-4977-bbfa-578daa0baaa1",
    desert: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/naturescape%2Fnaturescape_desert_background_landscape.jpg?alt=media&token=f460a9ef-1996-498b-937d-a0ac5e50212d"
};

export const NATURESCAPE_AUDIOS: {[soundscapeId in SoundscapeId]: {[audioDuration in AudioDurationSecs]?: string}} = {
    none: {},
    noNature: {},
    beach: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbeach--soundscape-03min_v02.mp3?alt=media&token=9a6dd099-bfa3-4207-9292-6d3b5d836cf7",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbeach--soundscape-10min_v02.mp3?alt=media&token=48da2ab5-1939-4444-b771-014464e66624",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbeach-soundscape-20min_v03.mp3?alt=media&token=08893e9a-0c7c-4431-ba1f-0f31b38371c5",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbeach_25mins.mp3?alt=media&token=0f9d1fde-209b-42dc-8f91-79cb1e6f04f5"
    },
    fireplace: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ffireplace--soundscape-03min_v02.mp3?alt=media&token=0f37f4ef-630e-46b2-a781-6a067f7a5e3a",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ffireplace--soundscape-10min_v02.mp3?alt=media&token=9c02c504-a82e-49ed-9a29-36874d5cb937",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ffireplace--soundscape-20min_v03.mp3?alt=media&token=9cc3527a-5275-4cd9-9596-e8e572c17adf",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ffireplace_25mins.mp3?alt=media&token=bb04ec5a-5b74-427b-a7b0-a8a20c8a5914"
    },
    forest: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fforest--soundscape-03min_v02.mp3?alt=media&token=a0fda35d-f5d5-43be-8b23-46995ec5abe5",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fforest--soundscape-10min_v02.mp3?alt=media&token=cb921595-6ac4-4ccb-b077-f9a881a08ae4",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fforest--soundscape-20min_v03.mp3?alt=media&token=04f0836b-1ff8-4e83-a823-fb531dc724c0",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fforest_25mins.mp3?alt=media&token=704073b5-fed3-4215-8ec5-94f741a3ac41"
    },
    garden: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FgardenAmbience--soundscape-03min_v03.mp3?alt=media&token=a1e923ea-e948-4cd3-b8a5-18882ff6c152",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FgardenAmbience--soundscape-10min_v02.mp3?alt=media&token=b246ed04-161f-49f6-8d42-c779d0533dad",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FgardenAmbience--soundscape-20min_v03.mp3?alt=media&token=036ef1d8-c21e-4d63-b29b-f46b2a3ace48",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FgardenAmbience_25mins.mp3?alt=media&token=a11d56f0-6aa8-4e35-8d58-28aaf3ef6240"
    },
    meadow: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmeadow--soundscape-03min_v02.mp3?alt=media&token=a1d2ed3c-50e9-497b-b390-b51718525f4b",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmeadow--soundscape-10min_v02.mp3?alt=media&token=b6449ad1-dc00-4d96-94a3-e9d354293000",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmeadow--soundscape-20min_v03.mp3?alt=media&token=7954b4fc-e678-47bc-8baa-ddb56ba771c1",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmeadow_25mins.mp3?alt=media&token=d4f2bce3-9d18-4b75-bdf0-c44949225939"
    },
    rain: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frain--soundscape-03min_v03.mp3?alt=media&token=7bf557b8-7ff3-40c6-8a5e-65041ed34807",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frain--soundscape-10min_v01.mp3?alt=media&token=cc628193-8a18-473d-b4d7-68c7092c0eb6",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frain--soundscape-20min_v03.mp3?alt=media&token=628e649e-2df0-474e-8ca9-2005449e56dc",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frain_25mins.mp3?alt=media&token=e34f8530-3104-4398-b2e4-90afcf5fab90"
    },
    rainforest: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frainforest--soundscape-03min_v02.mp3?alt=media&token=4c1a4bb6-2987-4229-ba8b-3d8073e842e4",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frainforest--soundscape-10min_v02.mp3?alt=media&token=23e59581-7fce-4e4e-b045-2f8ec4cf8b42",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frainforest--soundscape-20min_v03.mp3?alt=media&token=3882b933-08c8-48ec-959e-7d794a2521a2 ",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Frainforest_25mins.mp3?alt=media&token=be1beb44-6418-44b4-adc7-8f242006117e"
    },
    tropicalBeach: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FtropicalBeach--soundscape-03min_v02.mp3?alt=media&token=50e6500d-575d-4984-8fcc-5595befa57dc",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FtropicalBeach--soundscape-10min_v02.mp3?alt=media&token=8cd53203-a141-4590-9e3f-a7b2fecf34a4",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FtropicalBeach--soundscape-20min_v03.mp3?alt=media&token=3b4d0987-8dc3-46be-9f50-3d4a2e8ae43a",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FtropicalBeach_25mins.mp3?alt=media&token=98f5cdb1-10eb-4195-8d69-5f96f41b4c0e"
    },
    whiteNoise: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audio%2Fsoundscapes%2FWhitenoise_3mins_Zensory_v02.mp3?alt=media&token=efe7f28e-686d-4f93-bba5-26f77aa4c943",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audio%2Fsoundscapes%2FWhitenoise_10mins_Zensory_v02.mp3?alt=media&token=dc56c4fc-9178-4eb5-8ada-729a66ec15d9",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audio%2Fsoundscapes%2FWhitenoise_20mins_Zensory_v02.mp3?alt=media&token=ee693458-ffd1-43f2-9923-50b5b1cf9e87",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FwhiteNoise_25mins.mp3?alt=media&token=b4374256-b1a2-479e-9774-fe307d8a9fbe"
    },
    brownNoise: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FbrownNoise--soundscape-3min_v01.mp3?alt=media&token=034cdae8-71f7-4c08-aabf-4e9ca70ff063",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FbrownNoise--soundscape-10min_v01.mp3?alt=media&token=6167bc6a-f209-45b2-9077-94ef35b9f17b",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2FbrownNoise--soundscape-20min_v01.mp3?alt=media&token=a876a32d-f52d-400c-ac81-d85452461f20",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audio%2FfocusSession%2FbrownNoise-soundscape-25min-v01-20220830.mp3?alt=media&token=c5282bd6-4d17-4a2d-9398-3bdafda32763"
    },
    train: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ftrain-soundscape-3min.mp3?alt=media&token=03ac4b10-3ebf-43fe-a0c2-59241ca1cb9c",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ftrain-soundscape-10min.mp3?alt=media&token=d9459de9-b02b-4479-9b5c-477b0c5f47ae",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ftrain-soundscape-25min.mp3?alt=media&token=ce24127f-290f-4de1-9269-52b40d4d5b1d",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Ftrain-soundscape-25min.mp3?alt=media&token=ce24127f-290f-4de1-9269-52b40d4d5b1d"
    },
    ocean: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Focean-soundscape-3min.mp3?alt=media&token=00ad7c51-1097-4bb1-a98b-9ba45779f7a5",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Focean-soundscape-10min.mp3?alt=media&token=73766f5e-5150-43c2-b1dc-2132301df0eb",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Focean-soundscape-25min.mp3?alt=media&token=9563f772-5b69-4b08-844c-4fad333dd54a",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Focean-soundscape-25min.mp3?alt=media&token=9563f772-5b69-4b08-844c-4fad333dd54a"
    },
    mountain: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmountain-soundscape-3min.mp3?alt=media&token=189de71e-cf0d-4bcc-8ddf-cac07c06f36d",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmountain-soundscape-10min.mp3?alt=media&token=2dd5a03a-55cf-497c-b1de-6f50fedaae54",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmountain-soundscape-25min.mp3?alt=media&token=8671875f-e86b-4859-968c-9a0fc94a038b",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fmountain-soundscape-25min.mp3?alt=media&token=8671875f-e86b-4859-968c-9a0fc94a038b"
    },
    babblingBrook: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbabblingbrook-soundscape-3min.mp3?alt=media&token=8c2e7b08-0c67-4c5d-be64-a74ee1376e41",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbabblingbrook-soundscape-10min.mp3?alt=media&token=dd9d20fc-be5e-418e-ae35-2a1167541c45",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbabblingbrook-soundscape-25min.mp3?alt=media&token=7d71fa99-4303-4a09-8a7e-c89772bf19c7",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fbabblingbrook-soundscape-25min.mp3?alt=media&token=7d71fa99-4303-4a09-8a7e-c89772bf19c7"
    },
    lakeAtNight: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Flakeatnight-soundscape-3min.mp3?alt=media&token=14137fef-b639-4bf7-aa15-a108e0646e1b",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Flakeatnight-soundscape-10min.mp3?alt=media&token=fb186218-ba75-404c-9de0-a32c08c31210",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Flakeatnight-soundscape-25min.mp3?alt=media&token=15a77a8d-e974-4cab-bcf0-7742faebeeec",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Flakeatnight-soundscape-25min.mp3?alt=media&token=15a77a8d-e974-4cab-bcf0-7742faebeeec"
    },
    desert: {
        180: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fdesert-soundscape-3min.mp3?alt=media&token=3c740ff3-b52b-4e09-b498-cef02a53beaa",
        600: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fdesert-soundscape-10min.mp3?alt=media&token=9e854ae4-665c-4fd7-821e-90bcc271ff89",
        1200: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fdesert-soundscape-25min.mp3?alt=media&token=56cceb47-f2dd-4c8c-9954-501080ec288b",
        1500: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/audioExactUncompressed%2Fnaturescape%2Fdesert-soundscape-25min.mp3?alt=media&token=56cceb47-f2dd-4c8c-9954-501080ec288b"
    }
};

const SOUNDSCAPE_BACKGROUND_IMAGES: {[envId in EnvId]: {[soundscapeId in SoundscapeId]: string}} = {
    DEV: DEV_SOUNDSCAPE_BACKGROUND_IMAGES,
    RESEARCH_UCL: DEV_SOUNDSCAPE_BACKGROUND_IMAGES,
    PROD: DEV_SOUNDSCAPE_BACKGROUND_IMAGES,
};

function getSoundscapeObject(envId: EnvId, soundscapeId: SoundscapeId) {
    return {
        soundscapeId: soundscapeId,
        name: getSoundscapeTitle(soundscapeId),
        selectImageUrl: SOUNDSCAPE_SELECT_IMAGES[envId][soundscapeId],
        backgroundImageUrl: SOUNDSCAPE_BACKGROUND_IMAGES[envId][soundscapeId],
        backgroundLandscapeImageUrl: DEV_SOUNDSCAPE_BACKGROUND_LANDSCAPE_IMAGES[soundscapeId],
        audios: {
            180: NATURESCAPE_AUDIOS[soundscapeId][AudioDurationSecs.THREE_3_MINS],
            600:  NATURESCAPE_AUDIOS[soundscapeId][AudioDurationSecs.TEN_10_MINS],
            1200:  NATURESCAPE_AUDIOS[soundscapeId][AudioDurationSecs.TWENTY_20_MINS],
            1500:  NATURESCAPE_AUDIOS[soundscapeId][AudioDurationSecs.TWENTY_FIVE_25_MINS],
        },
        animatedBackgroundImageUrl: null,
    };
}

export function getSoundscapesMap(envId: EnvId): {[soundscapeId in SoundscapeId]?: Soundscape} {
    const retval: {[soundscapeId in SoundscapeId]?: Soundscape} = {};
    const soundscapeIds = getEnumValues(SoundscapeId);
    for (const soundscapeId of soundscapeIds) {
        // skip none
        if (soundscapeId === SoundscapeId.None) {
            continue;
        }
        retval[soundscapeId] = getSoundscapeObject(envId, soundscapeId);
    }
    return retval;
}

export function getSoundscapes(envId: EnvId, soundscapeIdsFilter: SoundscapeId[] = getEnumValues(SoundscapeId)): Soundscape[] {
    const map = getSoundscapesMap(envId);
    const arr: Soundscape[] = [];
    var index = 0;
    var soundscapeId;
    for (var key in map) {
        soundscapeId = key as SoundscapeId;
        
        // skip ones we should filter
        if (!listContains(soundscapeId, soundscapeIdsFilter)) {
            continue;
        }

        var val = map[soundscapeId] as Soundscape;
        val.index = index;
        index++; 
        arr.push(val);
    }
    return arr; 
}

////////////////
// Global App //
////////////////

export function getInspirationalQuotes(): InspirationalQuote[] {
    return [
        { text: "Act as if what you do makes a difference. It does." },
        { text: "Success is not final, failure is not fatal: it is the courage to continue that counts." },
        { text: "Never bend your head. Always hold it high. Look the world straight in the eye." },
        { text: "What you get by achieving your goals is not as important as what you become by achieving your goals." },
        { text: "Believe you can and you're halfway there." },
        { text: "When you have a dream, you've got to grab it and never let go." },
        { text: "I can't change the direction of the wind, but I can adjust my sails to always reach my destination." },
        { text: "No matter what you're going through, there's a light at the end of the tunnel." },
        { text: "It is our attitude at the beginning of a difficult task which, more than anything else, will affect its successful outcome." },
        { text: "Life is like riding a bicycle. To keep your balance, you must keep moving." }
    ];
}

export function getColours(envId: EnvId): Colour[] {
    return [
        getColour(ColourId.Purple, envId),
        getColour(ColourId.Blue, envId),
        getColour(ColourId.Green, envId),
        getColour(ColourId.Orange, envId),
        getColour(ColourId.Red, envId),
    ];
}

export function getColourForMood(moodId: MoodId): string {
    switch (moodId) {
        case MoodId.Creative: return ColourValues.Green;
        case MoodId.Focused: return ColourValues.Red;
        case MoodId.Positive: return ColourValues.Orange;
        case MoodId.Relax: return ColourValues.Blue;
        default: return ColourValues.Purple;
    }
}

function getColour(colourId: ColourId, envId: EnvId): Colour {
    switch (colourId) {
        case ColourId.Purple:
        return {
            colourId: colourId,
            mainHex: ColourValues.Purple,
            shade1Hex: ColourValues.PurpleShade1,
            shade2Hex: ColourValues.PurpleShade2,
            shade3Hex: ColourValues.PurpleShade3,
            shade4Hex: ColourValues.PurpleShade4,
        }

        case ColourId.Blue:
        return {
            colourId: colourId,
            mainHex: ColourValues.Blue,
            shade1Hex: ColourValues.BlueShade1,
            shade2Hex: ColourValues.BlueShade2,
            shade3Hex: ColourValues.BlueShade3,
            shade4Hex: ColourValues.BlueShade4,
        }

        case ColourId.Green:
        return {
            colourId: colourId,
            mainHex: ColourValues.Green,
            shade1Hex: ColourValues.GreenShade1,
            shade2Hex: ColourValues.GreenShade2,
            shade3Hex: ColourValues.GreenShade3,
            shade4Hex: ColourValues.GreenShade4,
        }

        case ColourId.Orange:
        return {
            colourId: colourId,
            mainHex: ColourValues.Orange,
            shade1Hex: ColourValues.OrangeShade1,
            shade2Hex: ColourValues.OrangeShade2,
            shade3Hex: ColourValues.OrangeShade3,
            shade4Hex: ColourValues.OrangeShade4,
        }

        case ColourId.Red:
        return {
            colourId: colourId,
            mainHex: ColourValues.Red,
            shade1Hex: ColourValues.RedShade1,
            shade2Hex: ColourValues.RedShade2,
            shade3Hex: ColourValues.RedShade3,
            shade4Hex: ColourValues.RedShade4,
        }
    }
}

export function getTouchpads(envId: EnvId): Touchpad[] {
    const map: {[touchpadId in TouchpadId]?: Touchpad} = getTouchpadsMap(envId);
    const arr: Touchpad[] = [];
    for (var key in map) {
        arr.push(map[key as TouchpadId] as Touchpad);
    }
    return arr; 
}

export function getTouchpadsMap(envId: EnvId): {[touchpadId in TouchpadId]?: Touchpad} {
    var map: {[touchpadId in TouchpadId]?: Touchpad} = {
        "smoke": {
            touchpadId: TouchpadId.Smoke,
            name: 'Smoke',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_smoke.png?alt=media&token=d2596255-e583-48c6-8c84-c1c0d36b0fc1',
            screenPrompt: "SWIPE THE SCREEN",
            backgroundTransparency: 1,
        },
        "cones": {
            touchpadId: TouchpadId.Cones,
            name: 'Cones',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_cones.png?alt=media&token=45b9314c-a7e8-4c7b-8816-554c393c692d',
            screenPrompt: "SWIPE THE SCREEN",
            backgroundTransparency: 1,
        },
        "squiggles": {
            touchpadId: TouchpadId.Squiggles,
            name: 'Squiggles',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_squiggles.png?alt=media&token=e8401717-286a-4bc3-a2c0-e98fd4d07099',
            screenPrompt: "SWIPE THE SCREEN",
            backgroundTransparency: 1,
        },
        "paintSpill": {
            touchpadId: TouchpadId.PaintSpill,
            name: 'Paint Spill',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_paint.png?alt=media&token=0bb79022-fca0-4c6a-958e-9b86b8f0be72',
            screenPrompt: "TAP THE SCREEN",
            backgroundTransparency: 1,
        },
        "bubbles": {
            touchpadId: TouchpadId.Bubbles,
            name: 'Bubbles',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_bubbles.png?alt=media&token=93b91787-7a55-4744-92e2-fcb5ed83384c',
            screenPrompt: "TAP THE SCREEN",
            backgroundTransparency: 1,
        },
        "littleBubbles": {
            touchpadId: TouchpadId.LittleBubbles,
            name: 'Little Bubbles',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_little_bubbles.png?alt=media&token=920604e8-27ff-4265-8c99-fed9cd8eb009',
            screenPrompt: "SWIPE THE SCREEN",
            backgroundTransparency: 1,
        },
    };

    if (envId !== EnvId.PROD) {
        map["waterIntense"] = {
            touchpadId: TouchpadId.WaterIntense,
            name: 'Intense Water',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_water_intense.jpg?alt=media&token=f3ca15c5-90c5-4be2-af14-b4229c8ccfdc',
            screenPrompt: "SWIPE & TAP THE SCREEN",
            backgroundTransparency: 1,
        };
        map["waterGentle"] = {
            touchpadId: TouchpadId.WaterGentle,
            name: 'Gentle Water',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fsenses_touchpads%2Fsense_touchpad_water_gentle.jpg?alt=media&token=d3977f8e-ff4c-48d5-a06e-30134d005d23',
            screenPrompt: "SWIPE & TAP THE SCREEN",
            backgroundTransparency: 1,
        };
    }
    
    // Not stable enough on Mobile yet
    // touchCreate({
    //     touchpadId: 'psychadellicWaves',
    //     name: 'Psychadellic Waves',
    //     url: 'https://zensoryanimations.netlify.app/psychedelic-waves'
    // }),

    return map;
}

export function getTouchpadSensesArray(envId: EnvId): SenseContent[] {
    const touchpads = getTouchpadsMap(envId);
    var arr = [
        getTouchpadSenseContentFromTouchpad(touchpads.smoke!!),
        getTouchpadSenseContentFromTouchpad(touchpads.squiggles!!),
        getTouchpadSenseContentFromTouchpad(touchpads.bubbles!!),
        getTouchpadSenseContentFromTouchpad(touchpads.littleBubbles!!),
        getTouchpadSenseContentFromTouchpad(touchpads.paintSpill!!),
        getTouchpadSenseContentFromTouchpad(touchpads.cones!!),
    ];

    if (envId !== EnvId.PROD) {
        arr.push(getTouchpadSenseContentFromTouchpad(touchpads.waterGentle!!));
        arr.push(getTouchpadSenseContentFromTouchpad(touchpads.waterIntense!!));
    }

    return arr;
}

export function getSmellSensesArray(): SenseContent[] {
    const angelicaRoot = getSmellSenseContent(
        "angelicaRoot", 
        "Angelica Root", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fangelica_root.jpg?alt=media&token=30e67ba8-d1ff-4311-80b4-d956437de4a1", 
        "Angelica root has been historically used for traumas, this emotionally uplifting oil helps to release negative, chronic overthinking and worry. Angelica root is useful when overstressed to address anxiety, fear and depression.", 
        getMoodsMap(false, false, true, true)
    );
    const babyPowder = getSmellSenseContent(
        "babyPowder", 
        "Baby Powder", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fbaby_powder.jpg?alt=media&token=4436eb82-0574-454c-84c5-b780ae14b382", 
        "Experts say that the baby powder smell reminds us of the safety and security we felt as children; for parents, it invokes memories of the happiness they felt when their children were young.", 
        getMoodsMap(false, false, true, false)
    );
    const balsamCopaiba = getSmellSenseContent(
        "balsamCopaiba", 
        "Balsam Copaiba", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fbalsam_copaiba.jpg?alt=media&token=167ee135-426e-4e72-bdce-b3532ea3b47f", 
        "Balsam Copaiba is grown in the depths of the Brazilian forests and has an earthy, radiant aroma. It has been shown to be beneficial for both anxiety and depression due to its high levels of B-caryophyllene. It is also incredibly useful for sleep as it calms the nervous system.", 
        getMoodsMap(false, false, true, false)
    );
    const bergamot = getSmellSenseContent(
        "bergamot", 
        "Bergamot", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fbergamot.jpg?alt=media&token=0641e8b4-545e-42d7-adb2-a9c4501d1287", 
        "Shown to reduce stress, anxiety, and mental fatigue. Bergamot has an enlivening effect on the mood and when applied topically on healthy skin where there is pain, it can help to reduce pain. The scent stimulates hormones that reduce the nerve sensitivity to pain.", 
        getMoodsMap(true, false, true, false)
    );
    const cardamon = getSmellSenseContent(
        "cardamon", 
        "Cardamon", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fcardamon.jpg?alt=media&token=658abe6e-dcea-437a-8c6d-7bd2ef8a2853", 
        "This spicy and enticing scent has been shown to be incredibly soothing and nurturing during times of sadness and depression, including those gray, gloomy days. ", 
        getMoodsMap(false, false, true, false)
    );
    const chamomile = getSmellSenseContent(
        "chamomile", 
        "Chamomile", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fchamomile.jpg?alt=media&token=ba2eaf2d-86cc-4140-b993-56710115f175", 
        "One of the most relaxing of all of the essential oils, this gentle aroma is perfect for soothing your temperament and mind. Perfect if you find yourself feeling gittery, anxious, irritable or angry.", 
        getMoodsMap(false, false, true, false)
    );
    const cinnamon = getSmellSenseContent(
        "cinnamon", 
        "Cinnamon", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fcinnamon.jpg?alt=media&token=ae156279-de87-47bc-980b-c2300acff4aa", 
        "Researchers have found that the scent of cinnamon improves performance on tasks that require heightened attention. It also increases response speed and improves memory.", 
        getMoodsMap(false, true, false, false)
    );
    const citrus = getSmellSenseContent(
        "citrus", 
        "Citrus", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fcitrus.jpg?alt=media&token=3387a560-64de-447d-aa1a-bb63c1b5b5a6", 
        "The smell of the vitamin C packed fruits has been shown to boost energy and alertness, and studies have revealed that lemon scents in particular can reduce stress and leave a positive impression on others.", 
        getMoodsMap(false, false, false, true)
    );
    const coffee = getSmellSenseContent(
        "coffee", 
        "Coffee", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fcoffee.jpg?alt=media&token=2adad765-984e-443b-848f-5beb12ca1798", 
        "It’s well known that ingesting coffee can promote wakefulness, but its scent can also increase alertness. In fact, students who smelled coffee performed better on analytical reasoning tasks.", 
        getMoodsMap(false, true, false, false)
    );
    const cypress = getSmellSenseContent(
        "cypress", 
        "Cypress", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fcypress.jpg?alt=media&token=0c2f464e-b963-4036-9f9f-00a3653fa743", 
        "Known for its fresh and herbaceous aroma, cypress eliminates mental fog and nervous tension. It also has an affinity to the endocrine system and helps bring balance and stabilisation to the thyroid and adrenal glands.", 
        getMoodsMap(true, false, false, false)
    );
    const davana = getSmellSenseContent(
        "davana", 
        "Davana", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fdavana.jpg?alt=media&token=17e1e1a2-cbe2-48c9-a0b9-552a28e36c83", 
        "Look to davana to lift your moods and put a smile on your face. In the same way that sage is beneficial for hormonal fluctiations, davana also has this balancing effect on the body for PMS or menopausal issues.", 
        getMoodsMap(false, false, true, false)
    );
    const eucalyptus = getSmellSenseContent(
        "eucalyptus", 
        "Eucalyptus", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Feucalyptus.jpg?alt=media&token=2ca41c97-e5c2-4d8e-8279-19bd787e1541", 
        "Inhaling this scent is incredible for clearing the body when it is blocked, especially during times of feeling run down. It has been shown to boost enthusiasm, creativity and mental acuity.", 
        getMoodsMap(true, true, false, false)
    );
    const frankincense = getSmellSenseContent(
        "frankincense", 
        "Frankincense", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Ffrankincense.jpg?alt=media&token=d01634a9-ba65-461b-9691-08253cc41796", 
        "Known for its bright, warm, resinous and earthy aroma, frankincense is perfect for meditation and purification.", 
        getMoodsMap(true, false, true, false)
    );
    const freshlyCutGrass = getSmellSenseContent(
        "freshlyCutGrass", 
        "Freshly-Cut Grass", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Ffreshly_cut_grass.jpg?alt=media&token=b1aca5c5-916b-4e66-a139-d7ab395c6521", 
        "Researchers in Australia have found that a chemical released by freshly cut grass can cause people to become more relaxed and even feel joy.", 
        getMoodsMap(false, false, true, true)
    );
    const ginger = getSmellSenseContent(
        "ginger", 
        "Ginger", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fginger.jpg?alt=media&token=66a071a3-9c33-4c1f-945c-3b5ea3414d8b", 
        "The sharp, aromatic smell of ginger fights fatigue and eases pain. It’s the perfect combination to help you power through a deadline.", 
        getMoodsMap(false, true, false, false)
    );
    const jasmine = getSmellSenseContent(
        "jasmine", 
        "Jasmine", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fjasmine.jpg?alt=media&token=41cc26f1-7fcf-43c9-9f3b-85b3bd5ee67f", 
        "With delicate white petals, this flower’s sweet smell packs a surprising sleep punch. People who inhaled the scent while they slept experienced greater sleep efficiency, less movement during slumber, and overall better quality sleep. Surprisingly, its effects were even more powerful than those of lavender!", 
        getMoodsMap(true, false, true, false)
    );
    const lavender = getSmellSenseContent(
        "lavender", 
        "Lavender", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Flavendar.jpg?alt=media&token=10c94a25-4478-4bf2-84dd-5e79306ed34e", 
        "One of the most popular scents all over the globe due to its aroma and versitility. Lavendar is ideal for times of stress or anxiety and is perfect for balancing and sofening, providing a sense of ease, assurance and contentment.", 
        getMoodsMap(true, false, true, true)
    );
    const lemongrass = getSmellSenseContent(
        "lemongrass", 
        "Lemongrass", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Flemongrass.jpg?alt=media&token=c5ab2a8d-25f5-4086-a464-6b972d2ff94f", 
        "Helpful for winding down after a long day, lemongrass eases anxiety and reduces stress. Using it allows the brain to relax and prepare for the next day.", 
        getMoodsMap(false, false, true, false)
    );
    const orangeCitrus = getSmellSenseContent(
        "orangeCitrus", 
        "Orange Citrus", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Forange_citrus.jpg?alt=media&token=391ff9e4-02c7-428c-be13-661cd5c9d61b", 
        "Sweet orange stimulates joy, eases anxiety and worry. Studies have shown that when inhaled before dental procedures, anxiety was significantly reduced.", 
        getMoodsMap(true, false, true, true)
    );
    const peppermint = getSmellSenseContent(
        "peppermint", 
        "Peppermint", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fpeppermint.jpg?alt=media&token=9c0da81e-642d-49e1-973d-269cf69485f9", 
        "Oil from peppermint is known for its ability to elevate your mood and stimulate your mind and body. Studies have even shown that athletes who smell peppermint have improved athletic performance and better breathing.", 
        getMoodsMap(true, true, false, true)
    );
    const pine = getSmellSenseContent(
        "pine", 
        "Pine", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fpine.jpg?alt=media&token=87181166-a999-495b-b8d5-a06a4322b3b9", 
        "Research has found that this festive aroma helps to reduce stress.", 
        getMoodsMap(false, false, true, false)
    );
    const rosemary = getSmellSenseContent(
        "rosemary", 
        "Rosemary", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Frosemary.jpg?alt=media&token=5dde2405-ada8-49ac-9902-0ddcf300e94a", 
        "Rosemary has been shown to amplify brain power and the smell enhances the ability to remember complex events and tasks.", 
        getMoodsMap(true, true, false, false)
    );
    const sage = getSmellSenseContent(
        "sage", 
        "Sage", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fsage.jpg?alt=media&token=4014cb02-2e06-4295-9111-d363ef2da87c", 
        "This has been shown to rebalance the mind when we are hormonally fluctuating. Sage has also been shown to rebalance PMS symptoms such as headaches, hot flushes and insomnia.", 
        getMoodsMap(false, false, true, false)
    );
    const sunscreen = getSmellSenseContent(
        "sunscreen", 
        "Sunscreen", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fsunscreen.jpg?alt=media&token=dfa7d3dc-8a44-4609-ac0d-9193164f0382", 
        "This scent’s positive effects are rooted in its association with stress-free holiday time, when you are typically more relaxed and happy than usual.", 
        getMoodsMap(false, false, false, true)
    );
    const sweetBasil = getSmellSenseContent(
        "sweetBasil", 
        "Sweet Basil", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fbasil.jpg?alt=media&token=8ec94886-b74d-40e6-9e5f-eb4e79548f96", 
        "This energising scent is perfect if you are dealing with mental fatigue or if you're looking for a boost in endurance to get through the day. If you're looking to feel more energetic, confident and motivated, sweet basil is the perfect solution.", 
        getMoodsMap(false, false, false, true)
    );
    const vanilla = getSmellSenseContent(
        "vanilla", 
        "Vanilla", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fvanilla.jpg?alt=media&token=795cfbdb-7fac-4cea-b4c2-8a93093d8743", 
        "This fragrance is famous for being a potent relaxer. In fact, people who smelled vanilla while completing a stress test had more stable heart rates and better blood pressure readings than those who took the stress test in an unscented room.", 
        getMoodsMap(false, false, true, false)
    );
    const vetiver = getSmellSenseContent(
        "vetiver", 
        "Vetiver", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fsmells%2Fvetiver.jpg?alt=media&token=dc705414-92c7-488c-973e-6279793c1cd4", 
        "A type of fragrant grass that contains components that increase oxygenation of the cells in the brain. ADHD participants who inhaled it regularly experienced improved brain patterns and decreased symptoms, suggesting it may aid in concentration.", 
        getMoodsMap(false, true, false, true)
    );

    return [ angelicaRoot, babyPowder, balsamCopaiba, bergamot, cardamon, chamomile, cinnamon, citrus, coffee, cypress, davana, eucalyptus, frankincense, freshlyCutGrass, ginger, jasmine, lavender, lemongrass, orangeCitrus, peppermint, pine, rosemary, sage, sunscreen, sweetBasil, vanilla, vetiver ];
}

export function getTasteSensesArray(): SenseContent[] {
    const amaranth = getTasteSenseContent(
        "amaranth", 
        "Amaranth", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FAmaranth.jpg?alt=media&token=71a0315c-2157-4453-b412-679f8a8d73ef", 
        "Bet you've never heard of this little power grain Amaranth -  but it's going to become a trendy little food soon as the world wakes up to its super power!  Known as the “food of immortality” by the Aztecs, (yup it goes back a long way), a bit like baby quinoa it's unusually high in protein (unusual for grains).  You can boil it up, pop it like popcorn and mix it with squash, corn, sesame and even chocolate  - your brain is going to love it - guaranteed to get it dancing!", 
        getMoodsMap(true, false, false, false)
    );
    const avocados = getTasteSenseContent(
        "avocados", 
        "Avocados", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FAvocado.jpg?alt=media&token=195b58e8-ca0b-40e5-a5dc-4b60098888bb", 
        "Lutein is the magical ingredient that can be found in our favourite fruit (yup Avos are not a veggies) and in a study at Illinois University they found that adults performed tasks far more efficiently when they had one avocado a day for 12 days. It's high in fatty and folic acids, which helps build up the coating of insulation found in the white matter of the brain all useful in boost our creativity and productivity. Add avocado to your salads or mash it up and place on a rye bread with a sprinkle of sunflower seeds and eat - yum!", 
        getMoodsMap(true, false, false, false)
    );
    const bananas = getTasteSenseContent(
        "bananas", 
        "Bananas", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBananas.jpg?alt=media&token=b0b7bd17-cac1-4772-99f4-a30454531758", 
        "Bananas may help turn your frown upside down. Why because they contain tryptophan, an amino acid which the body converts to the feel-good brain chemical serotonin. We LOVE serotonin as this  chemical is a super power at getting you to relax, feel less anxious and generally helps you feel brighter. Super versatile, eat them on their own,  wizz them up as a smoothie, cook them in banana bread or pop them in a fruit salad with their berry friends.", 
        getMoodsMap(false, false, false, true)
    );
    const beansAndLentils = getTasteSenseContent(
        "beansAndLentils", 
        "Beans & Lentils", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FLentils.jpg?alt=media&token=7595ed51-1ada-4e27-937d-007e6b3dfbce", 
        "Ever wondered why vegetarians have such broad smiles? Probably because they're packed full of feel-good nutrients like B vitamins, which help improve mood by increasing levels of neurotransmitters like serotonin, dopamine, norepinephrine, and gamma aminobutyric acid (GABA), all of which are important for regulating mood. Pop these into a salad with avocados, nuts and some tuna and you'll be ready to take on the world!", 
        getMoodsMap(false, false, false, true)
    );
    const beetroots = getTasteSenseContent(
        "beetroots", 
        "Beetroots", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBeetroot.jpg?alt=media&token=aafd6951-2df4-49d3-bf21-2b8bf39242fe", 
        "One cup of beets supplies over 30% of your folic or Vit B daily dose. This loveable veg will help to boost your mental energy, make you less forgetful and generally more on the ball - it's also proven to help with sleep, relaxation and feeling generally better. It's also an aphrodisiac - so get eating in salads, as a side dish and delicious roasted with honey and nuts.", 
        getMoodsMap(false, false, true, false)
    );
    const berriesCreative = getTasteSenseContent(
        "berriesCreative", 
        "Berries", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBerries.jpg?alt=media&token=6e7beb47-1977-4cce-817b-368fc1c5e2be", 
        "Next time your want to be at your best, eat some berries like blueberries or raspberries. Studies have shown that berries boost “concentration and memory” for up to five hours because “the antioxidants in blueberries stimulate the flow of blood and oxygen to your brain keeping you alert and creative. Have these on your desk as a healthy snack or throw over your cereals at breakfast to get you going for the day.", 
        getMoodsMap(true, false, false, false)
    );
    const berriesPositive = getTasteSenseContent(
        "berriesPositive", 
        "Berries", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBerries.jpg?alt=media&token=6e7beb47-1977-4cce-817b-368fc1c5e2be", 
        "Curiously, eating more fruits and vegetables is linked to lower rates of depression. Although the mechanism isn’t clear, a diet rich in antioxidants may help manage inflammation associated with depression and other mood disorders. Berries pack a wide range of antioxidants and phenolic compounds, which play a key role in combatting oxidative stress — an imbalance of harmful compounds in your body.", 
        getMoodsMap(false, false, false, true, false)
    );
    const blueberries = getTasteSenseContent(
        "blueberries", 
        "Blueberries", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBlueberries.jpg?alt=media&token=26fa6074-f85f-4fa5-a92c-0ddfb3f54208", 
        "Not only are blueberries packed full or flavour and vitamins but these deeply colored berries deliver anthocyanins, a group of plant compounds with anti-inflammatory and antioxidant effect. Some of the antioxidants in blueberries have been found to accumulate in the brain and help improve communication between brain cells. So in other words, use blueberries as a power food to power through your day and keep you on track.", 
        getMoodsMap(false, true, false, false)
    );
    const brazilNuts = getTasteSenseContent(
        "brazilNuts", 
        "Brazil Nuts", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBrazil_Nuts.jpg?alt=media&token=65a42d0e-564b-4b16-b7df-a924bd87f33d", 
        "Looking for a good snack to take the edge off… well brazil nuts could offer just that! Studies have shown that because the nut is high in selenium this may improve your mood by reducing inflammation, which is often associated with anxiety. So brazil nuts can contribute to regulating the emotions making you feel more in control and balanced. Lovely as a snack, thrown in salads and if your feeling naughty covered in dark chocolate.", 
        getMoodsMap(false, false, true, false)
    );
    const Broccoli = getTasteSenseContent(
        "broccoli", 
        "Broccoli", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2Fbrocolli.jpg?alt=media&token=5b838d1e-f209-456a-9c71-68e4dc21cf1f", 
        "Eat your greens! Yes that's right, your mother was correct for telling your to guzzle down the green stuff growing up...broccoli is packed with powerful plant compounds, including antioxidants and vitamin k, which has been linked to better memory and cognitive status. We love it fried with garlic and soya sauce.", 
        getMoodsMap(false, true, false, false)
    );
    const brownRice = getTasteSenseContent(
        "brownRice", 
        "Brown Rice", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FBrown%20Rice.jpg?alt=media&token=259f5cf9-e988-485f-8aaa-92bdadded34b", 
        "Forget the white rice and swap it for brown! It's a good source of B-vitamins like riboflavin, which help produce energy for brain cells keeping you creative for hours, loved by bodybuilders because it kick starts energy and is also great for muscle production. So don't think twice, go for brown rice!", 
        getMoodsMap(true, false, false, false)
    );
    const cashews = getTasteSenseContent(
        "cashews", 
        "Cashews", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FCashews.jpg?alt=media&token=93a7fbb8-9ee1-4337-8dba-143796603d14", 
        "Just one ounce of this buttery nut packs 11% of the daily recommended value of zinc, an essential mineral that may help reduce anxiety. After giving individuals with anxiety cashew nuts, researchers saw a 31% decrease in anxiety. It's also one of the most delicious nuts in the world!", 
        getMoodsMap(false, false, true, false)
    );
    const chamomileTea = getTasteSenseContent(
        "chamomileTea", 
        "Chamomile Tea", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FChamomile%20Tea.jpg?alt=media&token=32c22bea-2721-4083-8e08-edf4d7e8013a", 
        "If you're looking for a drink to help you relax, chamomile tea is the one! Many people around the world use chamomile tea as a herbal remedy because of its anti-inflammatory, antibacterial, antioxidant, and relaxant properties. Chamomile has been used for generations to help with sleep.", 
        getMoodsMap(false, false, true, false)
    );
    const Cherries = getTasteSenseContent(
        "cherries", 
        "Cherries", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FCherries.jpg?alt=media&token=801a1c32-da87-40ec-bd64-854a88c2985a", 
        "Cherries help your brain release serotonin, as well as the hormone melatonin, which promotes healthy sleep patterns and with more sleep you'll feel more positive  and upbeat which in turns helps you be more productive. They also are good for digestion and boosting energy levels so enjoy them with some Greek yoghurt in the morning, with a little drizzle of honey for sweetness.", 
        getMoodsMap(false, false, false, true)
    );
    const Coffee = getTasteSenseContent(
        "coffee", 
        "coffee", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FCoffee.jpg?alt=media&token=3b357c0f-b7b3-4b50-8f49-1fc04e88a7d0", 
        "No shit sherlock, Coffee is the world’s most popular drink, and it may make the world a bit happier, too.\n\nThe caffeine in coffee prevents a naturally occurring compound called adenosine from attaching to brain receptors that promote tiredness and therefore boosts your alertness and attention span. Too much can make you jittery and anxious so avoid it if you have a tendency to feel uptight and depressed.", 
        getMoodsMap(false, false, false, true)
    );
    const darkChocolate = getTasteSenseContent(
        "darkChocolate", 
        "Dark Chocolate", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FDark%20Chocolate.jpg?alt=media&token=970280f4-6424-44c7-81cc-e24a67a74e7d", 
        "Yes, lucky you, we're telling you to eat more chocolate! Dark chocolate makes for a brilliant work snack as it's packed with brain-boosting compounds, including flavonoids, caffeine, and antioxidants. The flavonoids in chocolate gather in the areas of the brain that deal with learning and memory. Research has shown that dark chocolate (above 70% cocoa) can reduce stress hormones, including cortisol. Dark chocolate can create a sense of euphoria so worth eating to feel upbeat, focused and even relaxed.", 
        getMoodsMap(false, true, true, true)
    );
    const Eggs = getTasteSenseContent(
        "eggs", 
        "Eggs", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FEggs.jpg?alt=media&token=f2f1d65f-e4bd-4d8d-8318-0d5d4b056bd9", 
        "Fried, boiled or scrambled, however you like them, eggs are a great source of several nutrients tied to brain health, including vitamins B6 and B12, folate, vitamin D and choline. This nutrient is linked to several important health benefits, including better immune function, anti-inflammation and mood regulation. Eggs can help improve memory, brain speed, and boost the processing of  sensory experiences. So grab an egg and get cooking - it's one of the most versatile foods in the world!", 
        getMoodsMap(true, true, true, false)
    );
    const FattyFish = getTasteSenseContent(
        "fattyFish", 
        "Fatty Fish", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FFatty%20Fish.jpg?alt=media&token=ae694d2f-5dae-40d6-bdef-96abc2bfa327", 
        "Omega-3 fatty acids are a group of essential fats that you must obtain through your diet because your body can’t produce them on its own.\n\nFatty fish like salmon and albacore tuna are rich in omega-3s that have been proven in multiple studies to lower levels of depression and stablise moods - eating plenty of fish or taking Omega 3 supplements will boost your mood and put get you feeling positive and eager to do well throughout the day.", 
        getMoodsMap(false, false, false, true)
    );
    const FermentedFoods = getTasteSenseContent(
        "fermentedFoods", 
        "Fermented Foods", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FFermented%20Foods.jpg?alt=media&token=3fe0de35-f45a-45ce-90ed-24569f61246d", 
        "Fermented foods, which include kimchi, yogurt, kefir, kombucha, and sauerkraut, may improve gut health and mood.\n\nThe fermentation process allows live bacteria to thrive in foods that are then able to convert sugars into alcohol and acids.\n\nDuring this process, probiotics are created. These live microorganisms support the growth of healthy bacteria in your gut and may increase serotonin levels leaving you far more positive and ready to face the day ahead.", 
        getMoodsMap(false, false, false, true)
    );
    const greenLeafyVegetables = getTasteSenseContent(
        "greenLeafyVegetables", 
        "Leafy Vegetables", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FLeafy%20Greens.jpg?alt=media&token=b9abc009-13bd-453f-9078-0f22852269b0", 
        "Time for some greens! Green leafy vegetables like spinach contain folate, which produces dopamine, a pleasure-inducing brain chemical that helps to contribute to keeping you calm and contented.", 
        getMoodsMap(false, false, true, true)
    );
    const greenTea = getTasteSenseContent(
        "greenTea", 
        "Green Tea", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FGreen%20Tea.jpg?alt=media&token=be116952-8c16-4fcc-864f-f2f02feb402c", 
        "If you're looking for a drink to increase your focus, green tea is the one! The caffeine in green tea boosts brain function and improves alertness, performance, memory, and focus. A recent study with over 40,000 individuals, found that levels of psychological stress were 20% lower in those who drank at least five cups of green tea per day compared to those who drank less than one cup per day.  The dose of caffeine sharpens it further, and the amino acid, theanine, ensures it releases gradually, eliminating creativity-killing crashes. On a bad day, sipping a cup of tea can be just the thing to soothe your senses, calm your nerves and brighten a dark mood. If you're bored of drinking it try making it into an ice-cream.", 
        getMoodsMap(true, true, false, true)
    );
    const Kale = getTasteSenseContent(
        "kale", 
        "Kale", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FKale.jpg?alt=media&token=ed784831-ceff-4b4d-a986-e55642a27468", 
        "This ‘super food’ is known for many health benefits, including its ability to help the body with hydration and the transport of oxygen – vital for optimal brain function.", 
        getMoodsMap(true, false, false, false)
    );
    const Mushrooms = getTasteSenseContent(
        "mushrooms", 
        "Mushrooms", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FMushrooms.jpg?alt=media&token=5a62891c-121d-41f8-b36b-5093ef83b468", 
        "Researchers are discovering that vitamin D plays a key role in mental health and depression, because it increases serotonin, one of the neurotransmitters responsible for mood.  The Vit D in mushrooms will give you that extra boost of positivity mixed in with your other postiive foods like nuts, leafy greens and nuts - try them altogether in a stir fry and you'll sit there with a big grin with others around you wondering why you're feeling so happy!", 
        getMoodsMap(false, false, false, true)
    );
    const Nuts = getTasteSenseContent(
        "nuts", 
        "Nuts", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FNuts.jpg?alt=media&token=e3e736c4-e67d-44da-a071-547c0c44f30e", 
        "Time to go nuts. Research has shown that eating nuts can improve heart-health, and having a healthy heart is linked to having a healthy brain. While all nuts are good for your brain, walnuts may have an extra edge, aside from looking like a brain they're naturally high in Omega-3 fatty acids proven to improve cognitive performance  prevent or ameliorate age-related brain deterioration. All nuts will help with performance and focus so nibble on them whenever you fancy!`", 
        getMoodsMap(false, true, false, false)
    );
    const NutsAndSeeds = getTasteSenseContent(
        "nutsAndSeeds", 
        "Nuts & Seeds", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FNuts%20and%20Seeds.jpg?alt=media&token=50293af1-ddbe-45e3-8fc4-bd93eddadb1e", 
        "Nuts and seeds are high in plant-based proteins, healthy fats, and fiber.\n\nAdditionally, they provide tryptophan, an amino acid responsible for producing mood-boosting serotonin. Almonds, cashews, peanuts, and walnuts, as well as pumpkin, sesame, and sunflower seeds, are excellent sources. All hugely versatile and can be eaten with almost every food at anytime of the day!", 
        getMoodsMap(false, false, false, true)
    );
    const Oatmeal = getTasteSenseContent(
        "oatmeal", 
        "Oatmeal", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FOatmeal.jpg?alt=media&token=def56dc5-8980-4b86-a843-075963f21aef", 
        "Not only are oats a brilliant way to start the day and keep you full, sustained and energised but according to research from MIT, carbohydrates found in oats can help the brain produce serotonin, the same brain chemical that is regulated by certain antidepressants. Complex carbs like oatmeal help the body to avoid sugar spikes and keeps the body regulated and energised.", 
        getMoodsMap(false, false, true, false)
    );
    const Oats = getTasteSenseContent(
        "oats", 
        "Oats", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FOats.jpg?alt=media&token=53602131-f504-4981-a771-342dd0b9d04a", 
        "A brilliant thinking food, oats deliver vitamin B6, protein and complex carbs, which power our entire body, especially the brain. So when you're about to kick off your next brainstorm, long work session or presentation, have a bowl of porridge at hand! Oats are a whole grain that can keep you in good spirits all morning. You can enjoy them in many forms, such as overnight oats, oatmeal, muesli, and granola.  Fiber helps slow your digestion of carbs, allowing for a gradual release of sugar into the bloodstream to keep your energy levels remain stable.", 
        getMoodsMap(true, false, true, false)
    );
    const Oranges = getTasteSenseContent(
        "oranges", 
        "Oranges", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FOranges.jpg?alt=media&token=b0a9175a-0930-48f7-bdb0-327e272dae12", 
        "You can get almost all the vitamin C you need in a day by eating one medium orange. Doing so is important for brain health since vitamin C is a key factor in preventing mental decline. According to one study, having higher levels of vitamin C in the blood was associated with improvements in tasks involving focus, memory, attention, and decision speed. One of the top sources of vitamin C, oranges (so are kiwis) are thought to be a great way to relax and lower stress levels. In addition to supporting immune function, which can be weakened by stress, this key nutrient helps reduce levels of the stress hormone cortisol.", 
        getMoodsMap(false, true, true, false)
    );
    const Popcorn = getTasteSenseContent(
        "popcorn", 
        "Popcorn", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FPopcorn.jpg?alt=media&token=b4f48d78-bb05-41c5-b4fa-b2ae01788aab", 
        "These whole grains help keep you mentally alert longer by regulating glucose flow, while a one-two punch of B6 and B12 vitamins boost short-term memory and concentration.", 
        getMoodsMap(true, false, false, false)
    );
    const PumpkinSeeds = getTasteSenseContent(
        "pumpkinSeeds", 
        "Pumpkin Seeds", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FPumpkin%20Seeds.jpg?alt=media&token=9fbbea35-3f57-4f72-b0d0-b08fc705915e", 
        "Pumpkin seeds contain powerful antioxidants that protect the body and brain from free-radical damage. Pumpkin seeds are rich in many micronutrients that are important for brain function, including copper, iron, magnesium, and zinc. Tasty tip, toast these seeds in the oven for added flavour and add to granola and salads for extra brain power.", 
        getMoodsMap(false, true, false, false)
    );
    const Quinoa = getTasteSenseContent(
        "quinoa", 
        "Quinoa", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FQuinoa.jpg?alt=media&token=f6132430-3b58-4ed9-8463-013855aee6f5", 
        "Quinoa is a great brain food to get you thinking creatively as it is high in iron, which is vital for brain function.", 
        getMoodsMap(true, false, false, false)
    );
    const Salmon = getTasteSenseContent(
        "salmon", 
        "Salmon", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FSalmon.jpg?alt=media&token=ea3e55c1-6c41-44ae-924a-b6214b280bd0", 
        "Salmon is a great brain food to eat because of the omega 3 fatty acids, which improves memory and mood and also help build the grey matter that processes information and signals to the brain. The nutrients in Salmon and other fatty fishes can help with creativity and focus. It's very versatile can is easy to poach, roast, fry or make in fish cakes.", 
        getMoodsMap(true, false, false, false)
    );
    const Seaweed = getTasteSenseContent(
        "seaweed", 
        "Seaweed", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FSeaweed.jpg?alt=media&token=c203f33f-6cf3-40b4-8a85-63858344cc96", 
        "Time to channel some ocean vibes. Seaweed a great food for energetic and emotional balancing as it is rich in iodine and one of the few sources of this important mineral. Too little iodine can trigger fatigue and depression, but just a quarter cup of seaweed salad can boost your mood and make you feel more upbeat and motivated. Seaweed delivers essential amino acid tyrosine, which helps in the production of neurotransmitters like dopamine, known to drive your cognitive control and creativity.", 
        getMoodsMap(true, false, true, true)
    );
    const Seeds = getTasteSenseContent(
        "seeds", 
        "Seeds", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FSeeds.jpg?alt=media&token=cdb4b929-b5af-4196-9e2d-f3febf9f7237", 
        "Flaxseed, pumpkin seeds, and sunflower seeds are all great sources of magnesium (as are leafy greens, yogurt, nuts, and fish). Loading up on magnesium may help regulate emotions and can help alleviate depression, fatigue, and irritability. Tasty tip, toast your seeds in the oven for added flavour and add to granola and salads for an extra boost.", 
        getMoodsMap(false, false, true, false)
    );
    const Spinach = getTasteSenseContent(
        "spinach", 
        "Spinach", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FSpinach.jpg?alt=media&token=9f546d8b-6497-4af2-be89-3fe15c831073", 
        "Packed with iron, this leafy vegetable helps to improve memory, concentration and mental functioning.", 
        getMoodsMap(true, true, false, false)
    );
    const tumeric = getTasteSenseContent(
        "tumeric", 
        "Tumeric", 
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FTumeric.jpg?alt=media&token=fece2d0f-0cb8-4d7c-b2bf-4a2b719a2357", 
        "This deep-yellow spice has a number of benefits for the brain. Curcumin, the active ingredient in turmeric, has been shown to cross the blood-brain barrier, meaning it can directly enter the brain and benefit the cells there. So sprinkle this into your curries, soups and teas for a power punch for the brain.", 
        getMoodsMap(false, true, false, false)
    );
    const yogurt = getTasteSenseContent(
        "yogurt", 
        "Yogurt",
        null,
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Ftastes%2FYogurt.jpg?alt=media&token=1e991b29-924f-4bd3-a6eb-ea10fce088f4", 
        "Yogurt is a brilliant support for the gut health and overall wellbeing as it contains healthy bacteria including Lactobaccilus and Bifidobacteria. We don't expect you to remember those ones so don't worry, but there is in fact emerging evidence that these bacteria and fermented products have positive effects on brain health and general wellbeing. Consuming probiotics in yogurt can actually reduce stress and make you feel more level headed!", 
        getMoodsMap(false, false, true, true)
    );

    return [ amaranth, avocados, bananas, beansAndLentils, beetroots, berriesCreative, berriesPositive, blueberries, brazilNuts, Broccoli, 
        brownRice, cashews, chamomileTea, Cherries, Coffee, darkChocolate, Eggs, FattyFish, FermentedFoods, greenLeafyVegetables, greenTea, 
        Kale, Mushrooms, Nuts, NutsAndSeeds, Oatmeal, Oats, Oranges, Popcorn, PumpkinSeeds, Quinoa, Salmon, Seaweed, Seeds, Spinach, tumeric, yogurt ];
}

export function getPressureSensesArray(): SenseContent[] {
    const eyebrow = getPressurePointSenseContent(
        "eyebrow", 
        "Eyebrow", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FEyebrow%20Pressure%20Square.png?alt=media&token=08ba37cb-7e8c-46cf-8a1c-5fa4f7cd70b9", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FEyebrow%20Pressure.png?alt=media&token=1c364654-25da-435d-84f5-a6c9e6d81d86", 
        "Sit back comfortably and place your right thumb or forefinger between your eyebrows.\n\nApply gentle pressure in a circular motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.", 
        InfoPageId.SensePressureEyebrow,
        getMoodsMap(false, true, true, false)
    );
    const lip = getPressurePointSenseContent(
        "lip", 
        "Lip", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FLip%20Pressure%20Point%20Square.png?alt=media&token=318ffd55-1b0a-430a-acdc-9d245eb51a56", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FLip%20Pressure%20Point.png?alt=media&token=29b9e521-de67-4025-92ed-0fe9d127519b", 
        "This point is located between your nose and the upper lip.\n\nApply gentle pressure in a circular motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.", 
        InfoPageId.SensePressureLip,
        getMoodsMap(false, true, false, false)
    );
    const topOfHead = getPressurePointSenseContent(
        "topOfHead", 
        "Top of Head", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FTop%20of%20head%20sq.png?alt=media&token=a5a47300-8505-4a7f-9a98-a9927fc64f94", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FTop%20of%20head%20.png?alt=media&token=675cb5d9-e832-4f55-9e39-8f9ed6bb8658", 
        "You will find this point on the top of your head.\n\nApply gentle pressure in a circular motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.", 
        InfoPageId.SensePressureTopOfHead,
        getMoodsMap(true, true, false, true),
    );
    const neck = getPressurePointSenseContent(
        "neck", 
        "Neck", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FNeck%20pressure%20point%20square.png?alt=media&token=fd8920c1-1062-4c91-ac4d-bd392178d829", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FNeck%20pressure%20point.png?alt=media&token=14eec365-d1df-4c4b-aa2b-d1c4822a1093", 
        "You will find this point just below your skull’s base, on both sides of the spine.\n\nApply gentle pressure in a circilar motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.", 
        InfoPageId.SensePressureNeck,
        getMoodsMap(false, true, true, false)
    );
    const temple = getPressurePointSenseContent(
        "temple", 
        "Temple", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FTemple%20sq.png?alt=media&token=2870caca-7dc5-4438-8a2f-1774c25c5f80", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FTemple.png?alt=media&token=5039c55f-3803-4397-998a-dd98fbf1db36", 
        "This point can be found in the depression of your temples.\n\nApply gentle pressure in a circilar motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.",
        InfoPageId.SensePressureTemple,
        getMoodsMap(false, true, true, false)
    );
    const underCollarBone = getPressurePointSenseContent(
        "underCollarBone", 
        "Under Collar Bone", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FUnder%20collar%20sq.png?alt=media&token=6a1d57b4-24d9-45b4-a241-5d94e18d1403", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FUnder%20collar.png?alt=media&token=8c7a4486-7c66-4014-8ac6-a112f30f995a", 
        "To find these points, place your fingers just below your collar bone.\n\nApply gentle pressure in a circilar motion on this point as you take 5 deep slow breaths.\n\nMassage this point for 45 seconds - 1 minute whenever you feel you need it during your day.", 
        InfoPageId.SensePressureUnderCollarBone,
        getMoodsMap(true, false, true, true)
    );
    const Thymus = getPressurePointSenseContent(
        "thymus", 
        "Thymus", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FThymus%20sq.png?alt=media&token=8e23e391-5eeb-4f64-84d1-d0d635e4dfae", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FThymus.png?alt=media&token=ed5c8adb-790e-4e6f-bbc6-3a58d512f1c6", 
        "Place your fingers in the center of your sternum. Use your thumb and fingers to massage or tap your thymus as you take 5 slow, deep breaths.", 
        InfoPageId.SensePressureThymus,
        getMoodsMap(true, false, false, true)
    );
    const spleen = getPressurePointSenseContent(
        "spleen", 
        "Spleen", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FSpleen%20square.png?alt=media&token=c5c38288-669c-43d0-bc65-4507b372ee04", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fpressure_points%2FSpleen.png?alt=media&token=4fcbaed1-0ea9-4c3a-9d49-2cc25caeef14", 
        "The neurolymphatic spleen points are just beneath the breast.\n\nMassage or tap the points firmly while taking 5 deep breaths.\n\nIf any spot is tender, take a little extra time to massage it.", 
        InfoPageId.SensePressureSpleen,
        getMoodsMap(true, false, true, true)
    );

    return [ lip, topOfHead, neck, eyebrow, temple, underCollarBone, Thymus, spleen ];
}

function getPressurePointSenseContent (
    id: string, 
    title: string, 
    backgroundImageUrl: string,
    contentImageUrl: string,
    description: string,
    infoPageId: InfoPageId,
    moods: {[moodId in MoodId]: boolean}
): SenseContent {
    return {
        contentId: id,
        moods: moods,
        title: title,
        subTitle: null,
        contentImageUrl: contentImageUrl,
        backgroundImageUrl: backgroundImageUrl,
        description: description,
        longDescription: null,
        onContentClickAction: SenseContentClickAction.MoreInfo,
        buttonText: null,
        breathingCodepenUrl: null,
        breathingDurationSecs: null,
        videoUrl: null,
        moreInfoUrl: null,
        hideBgImgOnMoreInfoPage: true,
        moreInfoButtonText: null,
        audioUrl: null,
        infoPageId: infoPageId,
    }
}

export function getMovementSensesArray(): SenseContent[] {
    const chairTricepDips = getMovementSenseContent(
        "chairTricepDips", 
        "Chair Tricep Dips", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchair_tricep_dips_sq.png?alt=media&token=2cfc7db5-472f-486f-a64b-57241171cff9", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchair_tricep_dips.png?alt=media&token=a0b93426-d441-4fba-841f-dc280306ea0a", 
        "Feeling sluggish and chained to your desk?  Why not change it into a piece of cool gym equipment?  Make sure your chair is stationary not got wheels? Scoot to the front of the chair, with both hands facing forward. Place palms flat on chair, bend your elbows straight back, and lower yourself straight down several inches, keeping your back as close to the chair as possible. Lift your body for 20 reps, try this between meetings, your shoulders and triceps will thank you and you'll be feeling as bright as a button!", 
        getMoodsMap(false, true, false, true)
    );
    const deskPushUps = getMovementSenseContent(
        "deskPushUps", 
        "Desk Push Ups", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdesk_push_ups_sq.png?alt=media&token=748870c3-a5b9-4f22-b4d0-2134da36333e", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdesk_push_ups.png?alt=media&token=b720295e-b6ef-48db-acde-6334f9b31715", 
        "Make sure your desk is sturdy enough to support your body weight! Then, take a few steps back, so you can place your hands flat on your desk, a little wider than shoulder-width. Lower yourself down toward your desk, keeping your core tight. Then push back up until arms are straight but not locked.\n\nThis can also be done on the floor. Repeat 20 times for toning the arms and abdominals. Breath deeply and slower and you'll feel totally energized.", 
        getMoodsMap(false, true, false, true),
    );
    const plank = getMovementSenseContent(
        "plank", 
        "Plank", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fplank_sq.png?alt=media&token=12d94a26-58e9-4586-b69f-47485cce1c1d", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fplank.png?alt=media&token=5a9e030e-85b9-4723-9a85-ef4a32ac6aac", 
        "Sneak this one in just before a meeting to get your blood pumping!  It's just one minute and you'll tone your tummy too! With your body flat on the foor and facing downwards use your lower arms to support you in a plank position for one minute, you can gradually increase the time as you increase strength. Washboard abs in no time!", 
        getMoodsMap(false, true, false, true),
    );
    const deskSquats = getMovementSenseContent(
        "deskSquats", 
        "Desk Squats", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdesk_squats_sq.png?alt=media&token=753ca2fa-eba7-4167-8beb-b299c22b5731",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdesk_squats.png?alt=media&token=ad3ccc26-8d27-417b-b035-e651d3d5de21",
        "Try to bust these out between meetings, on a call, any time. All you have to do is stand up from your chair, lower your body back down, stopping right before you sit back down. (Keep your weight in your heels to work those glutes). Then stand back up again.", 
        getMoodsMap(false, true, false, true),
    );
    const joggingOnTheSpot = getMovementSenseContent(
        "joggingOnTheSpot", 
        "Jogging on the spot", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fjogging_running_sq.png?alt=media&token=1f2a6438-2631-49c6-856b-887bdb9a4b2e",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fjogging_running.png?alt=media&token=a92aef83-2a34-423c-a6f8-beebfcca1c51",
        "It's time to step away from your desk and get moving or you can stand at your desk and do this! Jogging is excellent to get oxygen and blood to your muscles more efficiently and helps you burn calories and lower your cholesterol. So what are you waiting for!", 
        getMoodsMap(false, true, false, true),
    );
    const running = getMovementSenseContent(
        "running", 
        "Running", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fjogging_running_sq.png?alt=media&token=1f2a6438-2631-49c6-856b-887bdb9a4b2e",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fjogging_running.png?alt=media&token=a92aef83-2a34-423c-a6f8-beebfcca1c51",
        "We know it can be difficult to keep the motivation up sometimes, but running can significantly improve both physical and mental health. As a form of aerobic exercise, running can reduce stress, improve heart health, and even help alleviate stress and even depression. One 30 minute run is guaranteed to burn between 200-500 calories, so get moving!", 
        getMoodsMap(true, true, true, true),
    );
    const dance = getMovementSenseContent(
        "dance", 
        "Dance", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdance_sq.png?alt=media&token=1dc71dab-e407-41a8-949e-a96e2fc98c78",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fdance.png?alt=media&token=b933c1c1-a77f-4a39-a433-3caea7ec69bb",
        "Go on put on your favourite music and have a couple of minutes of dancing around your desk - it's shown to boost your memory and prevent you from developing dementia. It also increases flexibility, improves balance, increases energy, flexibility and significantly reduces stress. Plus you're guaranteed to feel uplifted. No-one's looking - what's stopping you!", 
        getMoodsMap(true, false, false, true),
    );
    const shoulderRolls = getMovementSenseContent(
        "shoulderRolls", 
        "Shoulder Rolls", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fshoulder_rolls_sq.png?alt=media&token=1ab992c6-01d7-4b72-91ea-3b82e26d6dfe",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fshoulder_rolls.png?alt=media&token=0c3b2130-78da-4436-bb2a-ebf572c5ea6c",
        "Easiest exercise of them all!! Roll your shoulder forward 15 circulations and then inhale and exhale gently and roll your shoulders backwards for 15 circulations. Let the stress fall away from you now...phewoooooo", 
        getMoodsMap(false, true, true, false),
    );
    const shoulderLifts = getMovementSenseContent(
        "shoulderLifts", 
        "Shoulder Lifts", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fshoulder%20lifts_sq.png?alt=media&token=237f7dcc-deeb-47d8-9a8d-ae74e406ac9e",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fshoulder%20lifts.png?alt=media&token=ec2fbc46-6838-4fd1-a052-64ec7825b25a",
        "Another no brainer exercise to help the worries and pressures drain away. Bring your shoulders up to you ears and then drop them and exhale your breath. Repeat 5 times!", 
        getMoodsMap(false, true, true, false),
    );
    const NeckRolls = getMovementSenseContent(
        "neckRolls", 
        "Neck Rolls", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fneck_rolls_sq.png?alt=media&token=e643980f-eda1-4b95-9a9b-7876a867f274",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fneck_rolls.png?alt=media&token=8c702429-8736-4fa2-bd9a-d3ff6509edd0",
        "We love this one, a nice low energy stretch here. We know your neck will thank you. Staring at our screens all day can get tiresome, so take some time to roll your neck left and right to stretch the entire spine and improve spinal mobility as you go about your day.",
        getMoodsMap(false, true, true, false),
    );
    const Chintucks = getMovementSenseContent(
        "chinTucks", 
        "Chin Tucks", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchin_tucks_sq.png?alt=media&token=c17f47cd-d838-4c54-b3a8-2bfb95c879ec",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchin_tucks.png?alt=media&token=b7c5b562-438e-4169-b5ed-c159e56c9d91",
        "Bring your chin down into your neck and let the stretch move down the back of your neck. This helps your strengthen your neck muscles to increase posture and good posture indicates improved mood, increased self-confidence and better digestion. So don't wait, sit up straight!",
        getMoodsMap(false, true, true, false),
    );
    const Yoga = getMovementSenseContent(
        "yoga", 
        "Yoga",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2FYoga_sq.png?alt=media&token=6ec5cdcc-6f9b-4402-b483-c0c13c6564d1",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2FYoga.png?alt=media&token=e8c3598f-92e8-45b8-87aa-62c47d48e172",
        "These three ancient arts combine rhythmic breathing with a series of postures or flowing movements. The physical aspects of these practices offer a mental focus that can help distract you from racing thoughts. They can also enhance your flexibility and balance. But if you are not normally active, have health problems, or a painful or disabling condition, these relaxation techniques might be too challenging. Check with your doctor before starting them.",
        getMoodsMap(true, true, true, true),
    );
    const walkingInNature = getMovementSenseContent(
        "walkingInNature", 
        "Walking in Nature",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fwalk_in_nature_sq.png?alt=media&token=43c3bed9-8ee4-4834-8c46-54886200df88",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fwalk_in_nature.png?alt=media&token=685dd3dd-3e81-4062-bc25-46b92bdc34d3",
        "The benefits of making time to walk in nature are endless... immediately when we are in nature, our blood pressure decreases, our stress hormone cortisol is lowered and we feel instantly calmer. Walking in nature has also been proven to improve cognition, memory, attention and improve our immune system. We are also able achieve mindfulness and gratitude in natural environments, leading to increased happiness and wellbeing.",
        getMoodsMap(true, true, true, true),
    );
    const fistClenches = getMovementSenseContent(
        "fistClenches", 
        "Fist Clenches", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Ffist_clenches_sq.png?alt=media&token=afc2a7a2-a31c-4abf-b98b-36db79c5387c",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Ffist_clenches.png?alt=media&token=f3a0a9ee-feed-4211-b4b5-18ebc165599e",
        "This exercise is wonderful for relieving stress.  First inhale and then tense your fists and with your exhale let the hands release.Repeat 5 times to release physical tension.",
        getMoodsMap(false, true, true, true),
    );
    const progressiveMuscleRelaxation = getMovementSenseContent(
        "progressiveMuscleRelaxation", 
        "Progressive Muscle Relaxation", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fprogressive_muscle_relaxation_sq.png?alt=media&token=c60dab78-e25e-46bd-9b31-70f4f0325a85",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fprogressive_muscle_relaxation.png?alt=media&token=1ff325e0-5807-40d2-9ed9-26c57c38d01f",
        "This is a form of therapy that involves tightening and relaxing your muscle groups, one at a time, in a specific pattern. Start by lying or sitting down, relax your entire body. Take five deep, slow breaths. Start with your toes and tense them tightly, hold and let go. Now slowly move up your calves, buttocks, abs, arms, neck and face with this same tense and release motion.",
        getMoodsMap(true, true, true, true),
    );
    const practiceStillness = getMovementSenseContent(
        "practiceStillness", 
        "Practice Stillness", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpractice_stillness_sq.png?alt=media&token=0b8e73a7-8e72-4cfc-897a-6a3f5ddd5a7b",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpractice_stillness.png?alt=media&token=638b8e39-9583-4b58-adc3-e4d35defafd5",
        "Sit in a comfortable chair and see how still you can keep. This is not as easy as it seems. You will have to center your attention on sitting still. Watch and see that you are not making any involuntary muscular movements. Mindfully pay attention to everything around you, your smell, pressure beneath your feet, and let your muscles relax from your feet to the top of your head. You can increase this from 5-10 mins daily.",
        getMoodsMap(true, true, true, true),
    );
    const childsPose = getMovementSenseContent(
        "childsPose", 
        "Child's Pose", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchilds_pose_sq.png?alt=media&token=56d06b89-6a4f-4840-90bc-450440ba7f10",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fchilds_pose.png?alt=media&token=f9b59bdd-9115-4948-a40a-579a0bdb3da1",
        "Get into a kneeling position on a comfortable flooring or mat, take a deep inhale and place your torso over your thighs, reaching your hands forward. Rest your forehead on the mat and feel a glorious stretch through your hips and back. Hold for up to a minute.",
        getMoodsMap(true, true, true, true),
    );
    const catCow = getMovementSenseContent(
        "catCow", 
        "Cat Cow", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fcatcow%20sq.png?alt=media&token=bf6b4c02-9218-46d1-95b5-b4720c9ac143",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fcatcow.png?alt=media&token=571c02da-eb41-41c3-b167-bcdf76a64f11",
        "Start on all fours on a comfortable floor or mat. Take a slow inhale and on the exhale round your spine and drop your head towards the floor (cat posture). On the inhale, lift your head, chest and tailbone toward the ceiling as you arch your back (cow pose). Continue this movement with gentle breaths for up to one minute.",
        getMoodsMap(true, true, true, true),
    );
    const puppyPose = getMovementSenseContent(
        "puppyPose", 
        "Puppy Pose", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpuppy_pose_sq.png?alt=media&token=05d89200-8c93-4cdf-ba40-03a4e5706973",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpuppy_pose.png?alt=media&token=e378fc1d-cbb7-4d38-9fb9-6d57bb3cbc44",
        "On a comfortable floor or mat, get onto all fours and as you exhale, walk your arms forward and bring your chin to the floor with your knees bent onto the floor. You should feel the stretch across your arms, chest and core. Hold for up to a minute.",
        getMoodsMap(true, true, true, true),
    );
    const hipFlexorStretch = getMovementSenseContent(
        "hipFlexorStretch", 
        "Hip-Flexor Stretch", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fhip_flexor_stretch_sq.png?alt=media&token=b1362aa7-e233-4a4b-a6b3-875b7bdff2e7",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fhip_flexor_stretch.png?alt=media&token=4b9d7995-148d-4903-9933-ce2387a96f09",
        "Make sure you are on a comfortable floor or mat. Kneel onto one knee and place the opposite foot in front of you. Hold this position or for a deeper stertch, you can lean forward and stretch your hip to the floor. Hold for 30-60 seconds. Switch sides and repeat. We hold a lot of our stress in our hips, so releasing this tension can be incredibly relaxing for the body.",
        getMoodsMap(true, true, true, true),
    );
    const forwardFold = getMovementSenseContent(
        "forwardFold", 
        "Forward Fold", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2FForward%20Fold_sq.png?alt=media&token=fe091149-dfac-4bec-9cda-2c8529dd840e",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2FForward%20Fold.png?alt=media&token=d6b5507f-ede1-4e98-8139-e9a6ef0a27f6",
        "This stretch can be deeply relaxing and can help us to rebalance and recentre in our day. Stand with your feet shoulder-width apart. Straighten your legs without locking your knees and let your torso hang down. Tuck your chin into your chest, relax your sholders and drop your body to the ground. Hold onto your elbows and let the stretch pass through your back and hamstrings. Ahhhhhh...",
        getMoodsMap(true, true, true, true),
    );
    const seatedSpinalTwist = getMovementSenseContent(
        "seatedSpinalTwist", 
        "Seated Spinal Twist", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fseated_spinal_twist_sq.png?alt=media&token=8c85be8f-180e-4a05-b617-afe2f86dbf91",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fseated_spinal_twist.png?alt=media&token=e76d73d0-946c-4d66-a395-635ed0ced29a",
        "Need to stretch and get rid of all that pent up frustration then twisting is great relief. Start by sitting on the floor, with your legs straight out in front of you and your arms behind your back. Place your hands on the floor behind you with your fingers facing away from you. Take your left foot and place it flat on the ground on the outside of your right knee.",
        getMoodsMap(true, true, true, true),
    );
    const pigeonPose = getMovementSenseContent(
        "pigeonPose", 
        "Pigeon Pose", 
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpigeon_pose_sq.png?alt=media&token=01b00542-954c-4a32-b050-7f46ca330612",
        "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fmovements%2Fpigeon_pose.png?alt=media&token=86cfd42a-607f-4964-b9a6-78fb64a4a771",
        "Sitting too long? Then find the Pigeon within you, perfect for relieving stress and stretching out.  Bend your right knee and bring that leg forward as if you were going to step into a lunge. Instead of placing your foot down as you would for a lunge, bring your right knee to the floor on the outside of your right hand. The right shin may angle back towards the left hip or be more parallel to the floor or mat.",
        getMoodsMap(true, true, true, true),
    );
    
    return [ chairTricepDips, deskPushUps, plank, deskSquats, joggingOnTheSpot, running, dance, shoulderRolls,
         shoulderLifts, NeckRolls, Chintucks, Yoga, walkingInNature, fistClenches, progressiveMuscleRelaxation, 
         practiceStillness, childsPose, catCow, puppyPose, hipFlexorStretch, forwardFold, seatedSpinalTwist, pigeonPose ];
}

function getMovementSenseContent (
    id: string, 
    title: string, 
    backgroundImageUrl: string,
    contentImageUrl: string,
    description: string,
    moods: {[moodId in MoodId]: boolean}
): SenseContent {
    return {
        contentId: id,
        moods: moods,
        title: title,
        subTitle: null,
        contentImageUrl: contentImageUrl,
        backgroundImageUrl: backgroundImageUrl,
        description: description,
        longDescription: null,
        onContentClickAction: SenseContentClickAction.MoreInfo,
        buttonText: null,
        breathingCodepenUrl: null,
        breathingDurationSecs: null,
        videoUrl: null,
        moreInfoUrl: null,
        hideBgImgOnMoreInfoPage: true,
        moreInfoButtonText: null,
        audioUrl: null,
        infoPageId: null,
    }
}

export function getSensesMap(envId: EnvId): {[senseId in SenseId]: Sense} {
    const senses: Sense[] = getSenses(envId);
    const map: any = {};
    for (var sense of senses) {
        map[sense.senseId] = sense;
    }
    return map;
}

export function getMoodToMusicMapForBreath(): {[moodId in MoodId]?: string} {
    return {
        "creative": getMusicForExperience(ZensoryExperienceId.Creative)[AudioDurationSecs.TEN_10_MINS]!!.audioUrl,
        "focused": getMusicForExperience(ZensoryExperienceId.Focus)[AudioDurationSecs.TEN_10_MINS]!!.audioUrl,
        "positive": getMusicForExperience(ZensoryExperienceId.Positive)[AudioDurationSecs.TEN_10_MINS]!!.audioUrl,
        "relax": getMusicForExperience(ZensoryExperienceId.Relax)[AudioDurationSecs.TEN_10_MINS]!!.audioUrl,
    };
}

export function getBreathingSenses(envId: EnvId) {
    const moodToMusicMap10minsForBreath = getMoodToMusicMapForBreath();
    return getBreathSensesArray(moodToMusicMap10minsForBreath);
}

export function getSenses(envId: EnvId): Sense[] {
    
    const arr: Sense[] = [
        {
            senseId: SenseId.Touch,
            title: getSenseTitle(SenseId.Touch),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_touch.png?alt=media&token=4bed769d-67b6-4a3c-8e3a-1920ff3f87b2",
            hideInSenses: false,
            contentAndSetup: {
                "touchpads": {
                    contentSetupId: ContentSetupId.Touchpads,
                    toggleButtonText: null,
                    hideMoodSelector: true,
                    addAllInMoodSelector: false,
                    globalContentTitle: "Select a touchpad...",
                    globalContentSubTitle: "to help you focus, recalibrate and unwind.",
                    content: getTouchpadSensesArray(envId),
                    infoPageId: InfoPageId.SenseTouchpads,
                }
            }
        },
        {
            senseId: SenseId.Smell,
            title: getSenseTitle(SenseId.Smell),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_smell.png?alt=media&token=ef9b5773-734f-4527-b396-39e0d8fcf6a3",
            hideInSenses: false,
            contentAndSetup: {
                "smells": {
                    contentSetupId: ContentSetupId.Smells,
                    toggleButtonText: null,
                    hideMoodSelector: false,
                    addAllInMoodSelector: true,
                    globalContentTitle: "Choose a scent...",
                    globalContentSubTitle: "they have been scientifically proven to get you into your ideal mood",
                    content: getSmellSensesArray(),
                    infoPageId: InfoPageId.SenseSmell,
                }
            }
        },
        {
            senseId: SenseId.Taste,
            title: getSenseTitle(SenseId.Taste),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_taste.png?alt=media&token=84742c45-b1cb-45e4-b2ab-8da5c9374178",
            hideInSenses: false,
            contentAndSetup: {
                "tastes": {
                    contentSetupId: ContentSetupId.Tastes,
                    toggleButtonText: null,
                    hideMoodSelector: false,
                    addAllInMoodSelector: true,
                    globalContentTitle: "Choose a taste...",
                    globalContentSubTitle: "they're all known for their mood boosting ingredients, recipes soon to follow!",
                    content: getTasteSensesArray(),
                    infoPageId: InfoPageId.SenseTaste,
                }
            }
        },
        {
            senseId: SenseId.Breath,
            title: getSenseTitle(SenseId.Breath),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath_200.png?alt=media&token=aa5b1fd4-8325-4304-b8f8-37b2a12e2fd2",
            hideInSenses: false,
            contentAndSetup: {
                "breathingExercises": {
                    contentSetupId: ContentSetupId.BreathingExercises,
                    toggleButtonText: null,
                    hideMoodSelector: false,
                    addAllInMoodSelector: false,
                    globalContentTitle: null,
                    globalContentSubTitle: null,
                    content: getBreathingSenses(envId),
                    infoPageId: InfoPageId.senseBreathCreative,
                }
            }
        },
        {
            senseId: SenseId.Pressure,
            title: getSenseTitle(SenseId.Pressure),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_pressure.png?alt=media&token=775e8b4b-e2fa-4bbb-a217-21433146c466",
            hideInSenses: false,
            contentAndSetup: {
                "pressurePoints": {
                    contentSetupId: ContentSetupId.PressurePoints,
                    toggleButtonText: null,
                    hideMoodSelector: false,
                    addAllInMoodSelector: true,
                    globalContentTitle: "Choose a pressure point...",
                    globalContentSubTitle: null,
                    content: getPressureSensesArray(),
                    infoPageId: null,
                }
            }
        },
        {
            senseId: SenseId.Movement,
            title: getSenseTitle(SenseId.Movement),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_movement.png?alt=media&token=2d58f37c-60ab-43b5-9773-ee569cf73397",
            hideInSenses: false,
            contentAndSetup: {
                "movements": {
                    contentSetupId: ContentSetupId.Movements,
                    toggleButtonText: null,
                    hideMoodSelector: false,
                    addAllInMoodSelector: true,
                    globalContentTitle: "Mood boosting moves...",
                    globalContentSubTitle: null,
                    content: getMovementSensesArray(),
                    infoPageId: InfoPageId.SenseMovement,
                }
            }
        },
        {
            senseId: SenseId.Sound,
            title: getSenseTitle(SenseId.Sound),
            subTitle: null,
            iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            hideInSenses: true,
            contentAndSetup: {
                "natureBasic": {
                    contentSetupId: ContentSetupId.NatureBasic,
                    toggleButtonText: null,
                    hideMoodSelector: true,
                    addAllInMoodSelector: false,
                    globalContentTitle: "Select a naturescape...",
                    globalContentSubTitle: null,
                    content: getNatureSensesArray(envId),
                    infoPageId: null,
                }
            }
        }
    ];

    // only add to Development project for now
    if (envId === EnvId.DEV) {
        
    }

    return arr;
}

export function getSense(envId: EnvId, senseId: SenseId): Sense {
    const senses = getSensesMap(envId);
    return senses[senseId];
}

export function getSenseContentItemsForMoodAndSense(senses: {[senseId in SenseId]: Sense}, moodId: MoodId, senseId: SenseId, contentSetupId: ContentSetupId): SenseContent[] {
    const sense = senses[senseId];
    const senseContent: SenseContent[] = [];

    // get all the content and then filter by the mood
    const allContent = sense.contentAndSetup[contentSetupId]?.content!!;
    for (var content of allContent) {
        if (content.moods[moodId]) {
            senseContent.push(content);
        }
    }

    return senseContent;
}

export function getNatureSensesArray(envId: EnvId): SenseContent[] {
    const soundscapes: Soundscape[] = getSoundscapes(envId);
    const natures: SenseContent[] = [];
    for (var soundscape of soundscapes) {
        natures.push(getNatureSenseContent(soundscape.selectImageUrl, soundscape.name, soundscape.backgroundImageUrl, ""));
    }
    return natures; 
}

export function getBreathSensesForMood(moodId: MoodId, moodToMusicMap: {[moodId in MoodId]?: string}): SenseContent {
    switch (moodId) {
        case MoodId.Focused:
            return getBreathSenseContent(
                "breathFocus", 
                "Box Breathing", 
                "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2Fbreathing_img_focus.png?alt=media&token=5c4ba150-c877-4e25-9d94-7e59e48a1629", 
                "1. Inhale through your nose for the count of 4\n2. Hold your breath for the count of 4\n3. Exhale through your mouth for the count of 4\n4. Again, hold the breath out for another count of 4\nRepeat this pattern and imagine with each breath and hold you are drawing around a box and feeling more centered in the process.", 
                InfoPageId.SenseBreathFocus,
                "https://thezensory.web.app/breathing/boxFocus.html",
                288,
                MoodId.Focused,
                moodToMusicMap[MoodId.Focused]!!,
            );
        
        case MoodId.Positive:
            return getBreathSenseContent(
                "breathPositive", 
                "Short Inhale Breathing", 
                "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2Fbreathing_img_positive.png?alt=media&token=af0b91b8-6e60-4652-8e34-505b350ea25e",
                "1. Take 5 sharp breaths in through the nose\n2. Exhale 5 strong and short exhales through the mouth as you contract your abdominals.\n3. As you repeat this action you will feel the oxygen fill your lungs and energy fill your body\n4. If you'd like an extra tip, shake out the arms and legs afterwards to release any unwanted energy or stress.", 
                InfoPageId.senseBreathPositive,
                "https://thezensory.web.app/breathing/shortInhalePositive.html",
                50,
                MoodId.Positive,
                moodToMusicMap[MoodId.Positive]!!
            );

        case MoodId.Creative:
            return getBreathSenseContent(
                "breathCreative", 
                "Alternate Nostril Breathing", 
                "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2Fbreathing_img_creative.png?alt=media&token=7e6dfeb2-62fe-4e95-8146-1e8456150e26",
                "1. Close your right nostril with your thumb. Breathe in through your left nostril for 4 counts.\n2. Close your left nostril and open right and breathe out for 6 counts.\n3. Breathe into your right nostril for 4 counts.\n4. Close your right nostril and open left and breathe out for 6 counts.\n5. Then repeat the entire practice again and breathe into your left nostril for 4 counts.\n6. Once you have completed your practice, observe your ideas and allow for creativity to flow - write, draw, paint, play music, or dance.", 
                InfoPageId.senseBreathCreative,
                "https://thezensory.web.app/breathing/alternateNostrilCreative.html",
                250,
                MoodId.Creative,
                moodToMusicMap[MoodId.Creative]!!
            );
        
        case MoodId.Relax:
            return getBreathSenseContent(
                "breathRelax", 
                "Three Part Breathing",
                "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2Fbreathing_img_relax.png?alt=media&token=6f674a4c-e249-45c8-b489-5306cab2e3a9",
                "1. Place one hand on your tummy and one hand on your chest.\n2. Slowly start to fill your lower tummy up partially with breath and gently exhale.\n3. On your second inhale now inhale to your stomach and then your chest and gently exhale all the breath from your chest and tummy.\n4. On your third inhale, breathe into your stomach, then your chest and then your shoulders and exhale.",
                InfoPageId.senseBreathRelax,
                "https://thezensory.web.app/breathing/threePartRelax.html",
                200,
                MoodId.Relax,
                moodToMusicMap[MoodId.Relax]!!
            );

        default:
            throw new Error(`getBreathSensesForMood() Invalid moodId=${moodId}`);
    }
}


export function getBreathSensesArray(moodToMusicMap: {[moodId in MoodId]?: string}): SenseContent[] {
    const breathFocus = getBreathSensesForMood(MoodId.Focused, moodToMusicMap);
    const breathPositive = getBreathSensesForMood(MoodId.Positive, moodToMusicMap);
    const breathCreative = getBreathSensesForMood(MoodId.Creative, moodToMusicMap);
    const breathRelax = getBreathSensesForMood(MoodId.Relax, moodToMusicMap);

    return [ breathRelax, breathCreative, breathFocus, breathPositive ];
}

function getTouchpadSenseContentFromTouchpad (touchpad: Touchpad): SenseContent {
    return getTouchpadSenseContent(touchpad.touchpadId, touchpad.name, touchpad.imageUrl);
}

function getTouchpadSenseContent (id: TouchpadId, title: string, imageUrl: string): SenseContent {
    return {
            contentId: id,
            moods: getDefaultMoodsMap(),
            title: title,
            subTitle: null,
            contentImageUrl: null,
            backgroundImageUrl: imageUrl,
            description: null,
            longDescription: null,
            onContentClickAction: SenseContentClickAction.Touchpad,
            buttonText: null,
            videoUrl: null,
            breathingCodepenUrl: null,
            breathingDurationSecs: null,
            moreInfoUrl: null,
            hideBgImgOnMoreInfoPage: false,
            moreInfoButtonText: null,
            audioUrl: null,
            infoPageId: null,
        }
}


function getNatureSenseContent (
    id: string, 
    title: string, 
    imageUrl: string,
    description: string,
): SenseContent {
return {
        contentId: id,
        moods: getDefaultMoodsMap(),
        title: title,
        subTitle: null,
        contentImageUrl: null,
        backgroundImageUrl: imageUrl,
        description: description,
        longDescription: null,
        onContentClickAction: SenseContentClickAction.MoreInfo,
        buttonText: null,
        videoUrl: null,
        breathingCodepenUrl: null,
        breathingDurationSecs: null,
        moreInfoUrl: null,
        hideBgImgOnMoreInfoPage: false,
        moreInfoButtonText: null,
        audioUrl: null,
        infoPageId: null,
    }
}

function getSmellSenseContent (
        id: string, 
        title: string, 
        url: string | null | undefined, 
        imageUrl: string,
        description: string,
        moods: {[moodId in MoodId]: boolean}
    ): SenseContent {
    return {
            contentId: id,
            moods: moods,
            title: title,
            subTitle: null,
            contentImageUrl: null,
            backgroundImageUrl: imageUrl,
            description: description,
            longDescription: null,
            onContentClickAction: SenseContentClickAction.MoreInfo,
            buttonText: null,
            videoUrl: null,
            breathingCodepenUrl: null,
            breathingDurationSecs: null,
            moreInfoUrl: null,
            hideBgImgOnMoreInfoPage: false,
            moreInfoButtonText: null,
            audioUrl: null,
            infoPageId: null,
        }
}

function getTasteSenseContent (
    id: string, 
    title: string, 
    url: string | null | undefined, 
    imageUrl: string,
    description: string,
    moods: {[moodId in MoodId]: boolean}
): SenseContent {
return {
        contentId: id,
        moods: moods,
        title: title,
        subTitle: null,
        contentImageUrl: null,
        backgroundImageUrl: imageUrl,
        description: description,
        longDescription: null,
        onContentClickAction: SenseContentClickAction.MoreInfo,
        buttonText: null,
        breathingCodepenUrl: null,
        breathingDurationSecs: null,
        videoUrl: null,
        moreInfoUrl: null,
        hideBgImgOnMoreInfoPage: false,
        moreInfoButtonText: null,
        audioUrl: null,
        infoPageId: null,
    }
}

function getBreathSenseContent (
    id: string, 
    title: string, 
    imageUrl: string,
    description: string,
    infoPageId: InfoPageId,
    breathingCodepenUrl: string,
    breathingDurationSecs: number,
    moodId: MoodId,
    audioUrl: string,
): SenseContent {
return {
        contentId: id,
        moods: getMoodMapForSingleTrue(moodId),
        title: title,
        subTitle: null,
        contentImageUrl: null,
        backgroundImageUrl: imageUrl,
        description: description,
        longDescription: null,
        onContentClickAction: SenseContentClickAction.Breathing,
        buttonText: "Let's go",
        breathingCodepenUrl: breathingCodepenUrl,
        breathingDurationSecs: breathingDurationSecs,
        videoUrl: null,
        moreInfoUrl: null,
        hideBgImgOnMoreInfoPage: false,
        moreInfoButtonText: null,
        audioUrl: audioUrl,
        infoPageId: infoPageId,
    }
}

function getDefaultMoodsMap (): {[moodId in MoodId]: boolean} {
    return {
        creative: true,
        focused: true,
        relax: true,
        positive: true,
        // sleep: true,
        // energise: true,
        all: true,
    }
}

function getMoodMapForSingleTrue (moodId: MoodId): {[moodId in MoodId]: boolean} {
    return {
        creative: moodId === MoodId.Creative,
        focused: moodId === MoodId.Focused,
        relax: moodId === MoodId.Relax,
        positive: moodId === MoodId.Positive,
        // sleep: moodId === MoodId.Sleep,
        // energise: moodId === MoodId.Energise,
        all: false,
    }
}

export function getMoodsMap (creative: boolean, focused: boolean, relax: boolean, positive: boolean, allParam?: boolean): {[moodId in MoodId]: boolean} {
    let allVal = true;
    if (allParam !== null && allParam !== undefined) {
        allVal = allParam;
    }

    return {
        creative: creative,
        focused: focused,
        relax: relax,
        positive: positive,
        // sleep: true,
        // energise: true,
        all: allVal,
    }
}

export function getMoods (): {[moodId in MoodId]: Mood} {
    return {
            "creative": {
                moodId: MoodId.Creative,
                title: MoodTitle.Creative,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_creative.png?alt=media&token=bda9dae1-bcef-4bca-afa9-ea0f398c719e",
                iconWithColorUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_creative_colour.png?alt=media&token=aa266366-81da-4be1-89c4-195ab53022f2",
            },
            "focused": {
                moodId: MoodId.Focused,
                title: MoodTitle.Focused,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_focused.png?alt=media&token=41b571f7-3a58-4e07-8f3b-bf4c4ecfaeb2",
                iconWithColorUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_focus_colour.png?alt=media&token=4a930443-5662-4579-af9f-fe5f849eb166",
            },
            "positive": {
                moodId: MoodId.Positive,
                title: MoodTitle.Positive,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_positive.png?alt=media&token=6377cbba-dded-4cdf-8add-2f053073fc1d",
                iconWithColorUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_positive_colour.png?alt=media&token=eae49700-eaa7-40e5-97d1-d20ba6dc7a44",
            },
            "relax": {
                moodId: MoodId.Relax,
                title: MoodTitle.Relax,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_relax.png?alt=media&token=a09321e7-b57d-4466-8363-5611f2835b58",
                iconWithColorUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fmoods%2Fic_mood_relax_colour.png?alt=media&token=53ec887a-01b8-41ff-91a9-b4721a288a80",
            },
            // "sleep": {
            //     moodId: MoodId.Sleep,
            //     title: MoodTitle.Sleep,
            //     iconUrl: ""
            // },
            // "energise": {
            //     moodId: MoodId.Energise,
            //     title: MoodTitle.Energise,
            //     iconUrl: ""
            // },
            "all": {
                moodId: MoodId.All,
                title: MoodTitle.All,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_all.png?alt=media&token=d3290a66-1f0e-4d3c-96ae-6456c5ca9182",
                iconWithColorUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_all.png?alt=media&token=d3290a66-1f0e-4d3c-96ae-6456c5ca9182"
            }
        }
}

export function getMoodsArray (): Mood[] {
    const moodsMap = getMoods();
    const moods: Mood[] = [];
    for (let moodId in moodsMap) {
        moods.push(moodsMap[moodId as MoodId]);
    }
    return moods;
}

export function getInfoPages (): {[infoPageId in InfoPageId]: InfoPage} {
    return {
        "home": {
            infoPageId: InfoPageId.Home,
            title: "Home",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_home.png?alt=media&token=c073f376-94a2-4f0d-9816-823c5f065b05",
            section1Title: "The Science",
            section1Body: "Our quick start experiences have been built using scientifically backed binaural beats, naturesounds and music. These are combined with multi-sensory tools such as breathwork, movement and pressure points to engage both the body and mind to recentre you.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "If you're looking to turbocharge a particular mood or task, use our homepage to shortcut you to quick start experiences. You can also review your past experiences and read your former intentions and notes to reflect and review.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "experience": {
            infoPageId: InfoPageId.Experience,
            title: "Experience",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_home.png?alt=media&token=c073f376-94a2-4f0d-9816-823c5f065b05",
            section1Title: "The Science",
            section1Body: "On our experience page, we ask you to choose what you are looking to do or which ideal mood you'd like to be in. Setting this intention for what you want to achieve is a brilliant tool for keeping us efficient, motivated and focused. Once we set an intention our brain is then working with us to direct us towards dopamine-releasing achievements.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Take a moment to choose the ideal mood that you'd like to be in right now or you can choose how you'd like to optimise a task!",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senses": {
            infoPageId: InfoPageId.Senses,
            title: "Senses",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_senses.png?alt=media&token=512c774a-f048-4760-b45a-cde0f2c88535",
            section1Title: "The Science",
            section1Body: `"One of the fastest ways to improve your mood is through your senses" (Dr. Rosenthal, 2020). We live our world through the five basic senses of taste, touch, sight, sound, and smell. These receptors feed into all of the networks of how we feel, think, move, see, and communicate.`,
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Here you can choose one sensory modality and we will invite you to utilise this sense for any of your chosen moodstates.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senseTouchpads": {
            infoPageId: InfoPageId.SenseTouchpads,
            title: "Touchpads",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_touch.png?alt=media&token=4bed769d-67b6-4a3c-8e3a-1920ff3f87b2",
            section1Title: "The Science",
            section1Body: "Using our touchpads to doodle and play is powerful. Here's why... research on school children has showed that doodlers significantly outperformed non-doodlers whilst working. (Tadayon et al., 2016) So what are you waiting for - have a play!",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "We recommend that you connect with your sense of touch here. We grant you permission to go play, let your mind roam free and recalibrate. We love using the touchpads whilst thinking about ideas, to decompress or to refocus the mind in a boring zoom meeting!",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senseTaste": {
            infoPageId: InfoPageId.SenseTaste,
            title: "Tastes",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_taste.png?alt=media&token=84742c45-b1cb-45e4-b2ab-8da5c9374178",
            section1Title: "The Science",
            section1Body: "Taste is a phenomenally powerful link to our memories and emotions thanks to an information superhighway that connects chemical receptors in the mouth and nose with parts of the brain devoted to emotions. Scientific studies examining snacking behaviour have shown that we can fast track ourselves into increased states of focus, relaxation and feeling good from the foods that we are eating.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Here you can find a list of foods that have been scientifically proven to get you into your ideal mood state. For now you can use these foods to inspire your snacks or meals. Stay tuned, we have recipes coming soon!",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senseSmell": {
            infoPageId: InfoPageId.SenseSmell,
            title: "Smells",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_smell.png?alt=media&token=ef9b5773-734f-4527-b396-39e0d8fcf6a3",
            section1Title: "The Science",
            section1Body: "Our sense of smell is one of the most powerful senses in the body; unlike our other senses, smells bypass the thalamus and go straight to our limbic system, responsible for emotions and memory. This makes our noses an extraordinarily powerful tool for influencing how we feel, react and process the world around us.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Here you can find a list of scents that have been scientifically proven to get you into your ideal mood state. Stay tuned - we will be offering the ability to purchase essential oils soon!",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senseBreath": {
            infoPageId: InfoPageId.SenseBreath,
            title: "Breathing",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath.png?alt=media&token=42e7b824-cafa-4874-8076-d93e77b51f69",
            section1Title: "The Science",
            section1Body: "", // this will be overridden by SenseContent.infoPageSection1BodyOverride for Breaths
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "senseSound": {
            infoPageId: InfoPageId.SenseSound,
            title: "Sound",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Our ears are incredibly powerful as research has shown that when listening to sounds and music, the entire brain has been seen to light up in EEG scans. Demonstrating just how powerful it can be in impacting our thoughts, perceptions and behaviours.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "There are many ways that you can use our soundscapes, either in a short break to recalibrate, during a work task to drown out your thoughts or distractions or to boost your mood whilst communting.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "naturescapeSelect": {
            infoPageId: InfoPageId.NaturescapeSelect,
            title: "Naturescapes",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_nature.png?alt=media&token=3e920fba-324b-4109-a687-15dc55fe8c84",
            section1Title: "The Science",
            section1Body: "Public health researchers Stamatakis and Mitchell found that just viewing scenes of nature can contribute to your physical and emotional wellbeing, reducing blood pressure, heart rate, muscle tension, and notibly reducing stress, anger and fear.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Enjoy the naturescapes whilst viewing an experience. You can also use the visual to prompt your imagination to transport you away to a similar place in mind. Close your eyes and imagine how this place would feel, smell and look and the sounds you may hear.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "customiseExperience": {
            infoPageId: InfoPageId.CustomiseExperience,
            title: "Customise Experience",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_customise.png?alt=media&token=de7bd28b-efd2-4835-82e8-4c1e12805673",
            section1Title: "The Science",
            section1Body: "INTENTIONS: Intention setting is a brilliant tool for keeping us efficient, motivated and focused. Once we set an intention our brain is then working with us to direct us towards dopamine-releasing achievements.\n\nAFFIRMATIONS: Affirmations have the ability to literally change the shape of our brains...a process is known as neuroplasticity. Once we affirm a positive behaviour to ourselves repeatedly we strengthen the neural pathways in our brains so that this positive affirmation becomes habit and natural behaviour.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "INTENTIONS: We recommend that you take some time to set an intention for how you want to feel during the experience or afterwards. This is a brilliant practice to get into for all tasks, meetings and experiences to gain clarity and direction.\n\nAFFIRMATIONS: You can repeat our affirmations to yourself in your head to solidify the message, you can also leave a reminder of them written on your desk so that you can see them all day long.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "notes": {
            infoPageId: InfoPageId.Notes,
            title: "Notes",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_notes.png?alt=media&token=3d29b14d-152d-4382-a845-d2ac55e491ad",
            section1Title: "The Science",
            section1Body: "Journaling has been found to boost your mood, enhance your sense of well-being and improve your working memory (Baikie & Wilhelm, 2005).",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Here you can make a note of eureka moments, thoughts, ideas.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
        "senseMovement": {
            infoPageId: InfoPageId.SenseMovement,
            title: "Movements",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_movement.png?alt=media&token=2d58f37c-60ab-43b5-9773-ee569cf73397",
            section1Title: "The Science",
            section1Body: "Regular movement does not only have phenomenal impacts on your physical health but it also has powerful benefits for relieving stress, improving memory, helping you sleep better, and boosting your overall mood (Segal et al. 2021)",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "senseBreathCreative": {
            infoPageId: InfoPageId.senseBreathCreative,
            title: "Alternate Nostril Breathing",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath.png?alt=media&token=42e7b824-cafa-4874-8076-d93e77b51f69",
            section1Title: "The Science",
            section1Body: "This breathing technique improves blood circulation to the brain, allowing for improved creative thinking! It also allows us to be more aware and focus inward, so that we're able to connect to our intuition and inner guidance.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "senseBreathFocus": {
            infoPageId: InfoPageId.SenseBreathFocus,
            title: "Box Breathing",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath.png?alt=media&token=42e7b824-cafa-4874-8076-d93e77b51f69",
            section1Title: "The Science",
            section1Body: "Box breathing helps regulate the amount of oxygen and carbon dioxide in our bodies, which can often be out of balance when anxiety is at play. This breath practice has an adaptogenic effect on the nervous system, which means that if you're anxious it can calm you down and if you're tired it can bring you up.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "senseBreathPositive": {
            infoPageId: InfoPageId.senseBreathPositive,
            title: "Short Inhale Breathing",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath.png?alt=media&token=42e7b824-cafa-4874-8076-d93e77b51f69",
            section1Title: "The Science",
            section1Body: "Short inhales ramp up blood flow and increase oxygenation into the blood stream, brain and muscles. In effect we activate an oxygen surge into the body which lends itself to the sense of euphoria that can occur during deeper breathwork practices. This super charges your body and mind when you feel like you're stuck in a slump!",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "senseBreathRelax": {
            infoPageId: InfoPageId.senseBreathRelax,
            title: "Three-Part Breathing",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_breath.png?alt=media&token=42e7b824-cafa-4874-8076-d93e77b51f69",
            section1Title: "The Science",
            section1Body: "Three-Part Breath is one of the most calming, grounding breathing exercises you can do. It really works to help focus your attention on the present moment and get in tune with the sensations of your physical body. Deep breathing will help oxygenate your blood, nourishing your entire body. This technique is taught to relieve stress and even to address panic attacks.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "profile": {
            infoPageId: InfoPageId.Profile,
            title: "Profile",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fprofile_page%2Fic_profile.png?alt=media&token=ad5da07a-27e1-4d9d-b153-55e654b5849e",
            section1Title: "Your Data",
            section1Body: "Streaks are a great way to ensure every day you are reaching your mood goals! You can also see how your moods are improving by week, month or all time.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureEyebrow": {
            infoPageId: InfoPageId.SensePressureEyebrow,
            title: "Eyebrow Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Acupressure is aimed to help release muscle tension and promote blood circulation through working along energy points in the body. This particular point in between the eyebrows promotes relaxation and is great for combatting stress and tension headaches, as it activates one of the brain centers, amygdala nucleus, which affects the human lymphatic system causing elimination of nervous tension. Several studies examining the effects of acupressure found that it notably helped relieve anxiety and stress during times of pain and even prior to surgery.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureLip": {
            infoPageId: InfoPageId.SensePressureLip,
            title: "Lip Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Pressing this point firmly with your finger for a few minutes will help improve concentration and memory, in addition to offering relief from dizziness, fainting, and cramps. If you often find yourself daydreaming during work, or have thousands of distracting thoughts that make it difficult to concentrate, stimulating this point will ground you and bring you back into the moment, thus letting you focus on the task at hand. You can increase the effectiveness of this point by accessing it every day for several weeks.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureTopOfHead": {
            infoPageId: InfoPageId.SensePressureTopOfHead,
            title: "Top Of Head Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Applying gentle pressure to this point improves your concentration and can bring notable mental awareness and clarity. Whether you are suffering from a creative block or headaches, massaging this point for 1 or 2 minutes will offer relief by increasing the body’s vital force and raising the emotional energy.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureNeck": {
            infoPageId: InfoPageId.SensePressureNeck,
            title: "Neck Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "An excellent stress-relieving point, massaging this neck pressure point in a circular motion for a few minutes every day can help beat stress, over-exertion, burnout and unclear thoughts. If these problems are inhibiting your focus and memory, stimulating this point could help relax your neck, thus facilitating better circulation into your brain.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureTemple": {
            infoPageId: InfoPageId.SensePressureTemple,
            title: "Temple Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Applying slight pressure on these two points for 2-3 minutes will not only improve your concentration and memory but also offer relief from mental stress, dizziness, and headaches. However, remember to be gentle when applying pressure here and be consistent by repeating the process 3 times every day to get the best results.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureUnderCollarBone": {
            infoPageId: InfoPageId.SensePressureUnderCollarBone,
            title: "Under Collar Bone Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Acupressure is aimed to help release muscle tension and promote blood circulation through working along energy points in the body. The 'K-27 points' as they are known, are versatile acupressure points that relieve throat, chest and back pain, help you breathe deeply and help release endorphins. These points are also great for balancing hormones and boosting the immune system.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureThymus": {
            infoPageId: InfoPageId.SensePressureThymus,
            title: "Thymus Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Acupressure is aimed to help release muscle tension and promote blood circulation through working along energy points in the body. Your thymus gland supports your immune system, so by engaging the thymus pressure point, you awaken your body’s energies, support your immune system and increase your energy, strength and vitality.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "sensePressureSpleen": {
            infoPageId: InfoPageId.SensePressureThymus,
            title: "Spleen Pressure Point",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "Acupressure is aimed to help release muscle tension and promote blood circulation through working along energy points in the body. The spleen pressure point can aid an increase in energy and regulate your blood sugar, removing toxins and supporting your immune system. Stimulating spleen acupressure points lifts your energy and decreases your stress levels.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: null,
            section2Body: null,
            section2IconUrl: null,
        },
        "focusSessionCustomise": {
            infoPageId: InfoPageId.FocusSessionCustomise,
            title: "Focus Sessions",
            titleIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Fic_sense_sound.png?alt=media&token=00302c43-99ba-4f1f-a4d7-17fdddea95c4",
            section1Title: "The Science",
            section1Body: "The Pomodoro Technique is a time management system that encourages people to work with the time they have—rather than against it.\n\nThe idea behind the technique is that the timer instills a sense of urgency. Rather than feeling like you have endless time in the workday to get things done and then ultimately squandering those precious work hours on distractions, you know you only have 20-25 minutes to make as much progress on a task as possible.",
            section1IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_science.png?alt=media&token=1f152988-7371-43c1-8870-0779bd1d2b1f",
            section2Title: "How to use it",
            section2Body: "Break your workday into 20-25 minute focus chunks separated by 3-minute breaks. The forced breaks help to cure that frazzled, burnt-out feeling most of us experience toward the end of the day. It's impossible to spend hours in front of your computer without even realizing it, as that ticking timer reminds you to get up and take a breather.",
            section2IconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Ficons%2Finfo_pages%2Fic_how_to.png?alt=media&token=93fa8d75-0851-4a86-b212-f704573a6452",
        },
    };
}
