import { getBreathAudioDurationSecsForMood, SenseId } from "../Data/constants"
import { ZensoryModule } from "../../ZensoryModule"
import { getScreenOrientation } from "../Util/ScreenUtil"

export const breathworkLoader = (moodId, subSenseId) => {
    return {
        isShow: ZensoryModule.getExperienceConfig().getMoodZenseConfig(moodId, SenseId.Breath, subSenseId).isShow,
        src: ZensoryModule.getImageLoader().getChooseBreathTileImage(moodId, subSenseId, getScreenOrientation()),
        subZenseId: subSenseId,
        duration: getBreathAudioDurationSecsForMood(moodId, subSenseId)
    }
};

export function getBreathwork(moodId, subSenseId) {
    return breathworkLoader(moodId, subSenseId);
}
