import { TextId, getText } from "./Util/TextHelper";

export const WeeklyStreak = ({ streakStats }) => {

  // turn streak object to array for mapping
  const scheduleArray = Object.entries(streakStats).map(([day, value]) => {
    return { day, value };
  });

  // function to return day status color based on value
  const getDotClassName = (value) => {
    if (value === true) return "green-streak-dot";
    else if (value === false) return "red-streak-dot";
    else if (value === null) return "grey-streak-dot";
  };

  return (
    <div className="profile-box">
      <h1 className="profile-box-heading">{getText(TextId.weeklyStreakTitle)}</h1>
      <div className="days-container">
        {scheduleArray.map((x) => (
          //map the array
          <div className="one-day">
            <div className={getDotClassName(x.value)} />
            <div className="day-indicator">{x.day[0]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
