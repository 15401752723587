import { ImageLoaderApi } from "../API/ImageLoaderApi";
import { ScormImageLoader } from "../Impl/Scorm/ScormImageLoader";
import { BaseProvider } from "./BaseProvider";
import { ProviderApi } from "./ProviderApi";
import { SessionHandlerApi } from "../API/SessionHandlerApi";
import { ScormSessionHandler } from "../Impl/Scorm/ScormSessionHandler";
import { NavigationHandlerApi } from "../API/NavigationHandlerApi";
import { ScormNavigationHandler } from "../Impl/Scorm/ScormNavigationHandler";
import { FeatureUiApi } from "../API/FeatureUiApi";
import { ScormFeatureUi } from "../Impl/Scorm/ScormFeatureUi";
import { LicenseApi } from "../API/LicenseApi";

export class ProviderScorm extends BaseProvider implements ProviderApi {

    provideLicenses(): LicenseApi {
        throw new Error("Method not implemented.");
    }

    createImageLoader(): ImageLoaderApi {
        return new ScormImageLoader();
    }

    createSessionHandler(): SessionHandlerApi {
        return new ScormSessionHandler(this.provideAnalytics());
    }

    createNavigationHandler(): NavigationHandlerApi {
        return new ScormNavigationHandler(this.provideAnalytics(), this.provideSessionHandler());
    }

    createFeatureUi(): FeatureUiApi {
        return new ScormFeatureUi();
    }

}