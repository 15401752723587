import React, { useState } from "react";
import { isNullOrEmpty } from "../Data/Util/util";
import { KeyId, KEY_ID_TO_CODE } from "./KeyEnum";

export const isKey = function (e, keyId) {
  const keyCode = KEY_ID_TO_CODE[keyId];
  return e.key === keyId || e.keyCode === keyCode;
}

export const isSubmitKey = function (e) {
  return isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar]);
}

export const isKeyIn = function (e, keyIds) {
  for (const keyId of keyIds) {
    if (isKey(e, keyId)) {
      return true;
    }
  }

  return false;
}

export const preventDefault = function (e) {
  if (isNullOrEmpty(e)) {
    return;
  }

  try {
    e.preventDefault();
  } catch (ignore) {
  }
}

export const stopPropagation = function (e) {
  if (isNullOrEmpty(e)) {
    return;
  }

  try {
    e.stopPropagation();
  } catch (ignore) {
    // thrown on Edge
  }
}

export const keyToString = function (e) {
  return `key=${e.key}, keyCode=${e.keyCode}`;
}

export const useKeyPress = function(key) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ k }) {
    if (k === key.id || k.keyCode === key.code) {
        setKeyPressed(true);
    }
  }

  const upHandler = ({ k }) => {
    if (k === key.id || k.keyCode === key.code) {
        setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};
