import touchpad from "../assets/icons/touchpad-new.png"

const TouchpadCard = (props) => {
  return (
    <div style={{
        backgroundImage: `url(${touchpad})`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "4px solid #FFFFFF",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        borderRadius: "24px",
        width: "250px",
        height: "230px",
        marginTop: "2%",
        alt: "Touchpads"
    }}>
    </div> 
)};

export default TouchpadCard;