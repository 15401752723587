import { FeatureUiApi } from "../../API/FeatureUiApi";
import { AuthProvider } from "../../Data/Models/configuration";
import { getConfiguration } from "../../Util/ConfigHelper";

export abstract class BaseFeatureUi implements FeatureUiApi {

    abstract isShowExitButton(): boolean;

    isShowAppDownloadConfig(): boolean {
        const endExperienceConfig = getConfiguration().endExperienceConfig;
        return endExperienceConfig.appDownloadConfig.isShow;
    }

    isShowRestartButton(): boolean {
        const endExperienceConfig = getConfiguration().endExperienceConfig;
        return !endExperienceConfig.isSingleExperience; //don't show for single experiences
    }

    isShowFinishButton(): boolean {
        const endExperienceConfig = getConfiguration().endExperienceConfig;
        return !endExperienceConfig.isSingleExperience; //don't show for single experiences
    }

    isShowLogoutButton(): boolean {
        return getConfiguration().authProvider === AuthProvider.Firebase;
    }
    
    isShowBackButton() : boolean {
        return !getConfiguration().isExperienceDeepLinkOnly;
    }
}