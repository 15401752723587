import React from "react";
import LavaLamp from "./Lavalamp";
import "../styles/layout.css"
import { ColourId, MoodId } from "./Data/constants";
const Layout = (props) => {
  const color = props.mood === MoodId.Focused
    ? ColourId.Red
    : props.mood === MoodId.Relax
      ? ColourId.Blue
      : ColourId.Purple;
  
  document.querySelector("meta[name='theme-color']").setAttribute("content", "#9965EE");
  
  return (
    <div className="container">
      {props.children}
      <LavaLamp color={color} />
    </div>
  );
};

export default Layout;