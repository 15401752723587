import { getTabIndex } from "../components/UI/TabIndexHelper";

export function getExperienceButtonTabIndex(props: any) {
    return getTabIndex(props.isPlaying 
        && props.hasLoaded 
        && props.hasStarted
        && !props.showVolumeSetting
        && !props.closeExperiencePopup
    );
}

export function isShowExperienceButton(props: any) {
    return isShowExperienceButtonAux(props.isPlaying, props.hasLoaded, props.hasStarted, props.showVolumeSetting, props.closeExperiencePopup);
}

export function isShowExperienceButtonAux(isPlaying: boolean, hasLoaded: boolean, hasStarted: boolean, showVolumeSetting: boolean, closeExperiencePopup: boolean) {
    return isPlaying 
        && hasLoaded
        && hasStarted
        && !showVolumeSetting
        && !closeExperiencePopup;
}

// Whether to show the start Experience popup to make the user click 
// to start the audio (fixes autoplay not available, seen on Safari, iOS, Chrome, etc.)
export function showStartExperiencePopup(): boolean {
    return true;
}
