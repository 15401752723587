import React, { useEffect } from "react";
import "../styles/colorMix.css"

const ColorMix = (props) => {
  const url = `colorMix.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('startColorMix');
    startDiv.click();
    const stopDiv = document.getElementById('stopColorMix');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);

  const onCheckBoxChanged = () => {
  }

  return (
    <>
      <div id="stopColorMix"></div>
      <div id="startColorMix"></div>
      <div id="menu" style={{ visibility: "hidden" }}>
        <p id="controls">close controls</p>
        <div id="showhide" style={{ visibility: "hidden" }}>
          <hr></hr>
          <p><input type="checkbox" id="colorplan" checked /> fuzzy connections</p>
          <p><input type="range" min={1} max={20} value={12} id="order" /> order</p>
          <p><input type="range" min={1} max={10} value={2} step="1" id="frequency" /> frequency</p>
          <p><input type="range" min={0} max={4} step="any" value={1} id="speed" /> speed</p>
          <p><input type="checkbox" id="interact" onChange={onCheckBoxChanged()} /> mouse / touch interaction</p>
        </div>
      </div>
      <canvas id="color-mix" ></canvas>
    </>
  );
}

export default ColorMix;