import { AudioObj, AudioPlayer } from "../API/AudioPlayerAPI";
import { getCurrentMillis, isNullOrEmpty } from "../Data/Util/util";
import { LOGGER, LogLevel } from "../Util/Logger";
import {Howl, Howler} from 'howler';
import { BaseAudioPlayer } from "./BaseAudioPlayer";

export class HowlerAudioPlayer extends BaseAudioPlayer {

    constructor(
        audios: AudioObj[],
        onLoaded: Function,
        onError: Function
    ) {
        super(AudioPlayer.Howler, audios, onLoaded, onError);

        Howler.autoUnlock = true;

        // increase pool size to cover all the audios we load
        Howler.html5PoolSize = 100;
    }

    async getAudioObj(audioObj: AudioObj) {
        LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() ${JSON.stringify(audioObj)}`);

        const audio = new Howl({
            src: [audioObj.uri],
            loop: audioObj.isUnlimited,
            volume: (audioObj.volume / 100.0),
            html5: true,
            autoplay: false,
            preload: false // Automatically begin downloading the audio file when the Howl is defined
        });

        // save the Howl audio object in the AudioObj
        audioObj.audio = audio;

        // listen for when the audio is loaded
        audio.once('load', () => {
            LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() Howl once.load() audioObj.type=${audioObj.type}, uri=${audioObj.type}, loadTime=${getCurrentMillis() - this.loadStartAt}ms`);
            this.onAudioLoaded(audioObj);         
        });
        // listen for load errors
        audio.once('loaderror', (error) => {
            LOGGER.log(LogLevel.ERROR, `${this.id} getAudioObj() Howl once.loaderror() audioObj.type=${audioObj.type}, uri=${audioObj.type}, error=${error}`);
            this.onAudioError(error);
            // clear all the audios from the cache because the Experiences cannot start if there is a load error
            Howler.unload();
        });
        // listen for play error
        audio.once('playerror', (error) => {
            LOGGER.log(LogLevel.ERROR, `${this.id} getAudioObj() Howl once.playerror() audioObj.type=${audioObj.type}, uri=${audioObj.type}, error=${error}`);
            this.onAudioError(error);
            
            // clear this audio instance only from the cache, which will allow other audios to still be played
            audio.unload();
        });
        
        // log when the audio is stopped, unlocked and ends so we could easily debug issues with any audio files

        audio.once('stop', () => {
            LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() Howl once.stop() audioObj.type=${audioObj.type}, uri=${audioObj.type}`);
        });
        audio.once('unlock', () => {
            LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() Howl once.unlock() audioObj.type=${audioObj.type}, uri=${audioObj.type}`);
        });
        audio.once("end", () => {
            LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() Howl once.end() audioObj.type=${audioObj.type}, uri=${audioObj.type}`);
            // clear this audio instance from the cache
            audio.unload();
        });

        audio.load();
        return audioObj;
    }

    setVolume(volume: number): void {
        // Set the global volume for all sounds, relative to their own volume.
        // https://github.com/goldfire/howler.js#volumevolume
        Howler.volume(volume / 100.0);
    }

    playAudioObj(audioObj: AudioObj): void {
        if (this.isDestroyed) {
            return;
        }
    
        if (isNullOrEmpty(audioObj.audio)) {
            LOGGER.log(LogLevel.ERROR, `${this.id} playAudioObj() Null/empty audio in audioObj.type=${audioObj.type}`);
            return;
        }
        
        const playResult = audioObj.audio.play();
        if (audioObj.isLogSuccess) {
            LOGGER.log(LogLevel.DEBUG, `${this.id} playAudioObj() audioObject.type=${audioObj.type}, playResult=${playResult}`);
        }
    }

    destroy(): void {
        super.destroy();
        Howler.unload();
    }
    
}