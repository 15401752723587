import React, { useEffect, useState } from "react";
import "../styles/tooltip.css";

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (props.rating != null) {
       setActive(false);
    }
}, [props.rating]);

  const showTip = () => {
    if (props.rating != null) {
      setActive(false);
    } else {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 400);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={() => showTip()}
      onMouseLeave={() => hideTip()}
      onTouchStart={() => showTip()}
      onTouchMove={() => hideTip()}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;