import { AudioType } from "../API/AudioPlayerAPI";
import { getGlobalAppState, GlobalAppState } from "../AppState";
import { ExperienceSource } from "../Data/constants";

export interface ExperienceAnalyticsHolder {
    source: ExperienceSource;
    numScreenTouches: number;
    didMuteAudio: boolean;
    changedBinauarlAudioLevel: boolean;
    changedMusicAudioLevel: boolean;
    changedSoundscapeAudioLevel: boolean;
    tookNotes: boolean;
    numTouchpadRandomise: number;
    audioLoadTimeSecs: number;
}

/**
 * @returns a default instance of an ExperienceAnalyticsHolder
 */
export function getExperienceAnalyticsHolderDefault(): ExperienceAnalyticsHolder {
    return {
        source: ExperienceSource.WebChooseZense,
        numScreenTouches: 0,
        didMuteAudio: false,
        changedBinauarlAudioLevel: false,
        changedMusicAudioLevel: false,
        changedSoundscapeAudioLevel: false,
        tookNotes: false,
        numTouchpadRandomise: 0,
        audioLoadTimeSecs: 0,
    } as ExperienceAnalyticsHolder;
}

/**
 * Set the Experience Source the user has taken to get to their Experiences
 * 
 * @param source the ExperienceSource
 */
export function setExperienceSourceGlobal(source: ExperienceSource) { 
    const experienceAnalyticsHolder = getGlobalAppState().getExperienceAnalyticsHolder();
    experienceAnalyticsHolder.source = source;
    GlobalAppState.setExperienceAnalyticsHolder(experienceAnalyticsHolder);
}

/**
 * Set the global changed audio flag
 * 
 * @param audioType The AudioType for the volume level that was changed
 */
export function setExperienceChangeAudioLevelsGlobal(audioType: AudioType) { 
    const experienceAnalyticsHolder = getGlobalAppState().getExperienceAnalyticsHolder();

    switch (audioType) {
        case AudioType.Binaural:
            experienceAnalyticsHolder.changedBinauarlAudioLevel = true;
            break;
        case AudioType.Music:
        case AudioType.AudioBreath:
            experienceAnalyticsHolder.changedMusicAudioLevel = true;
            break;
        case AudioType.Soundscape:
        case AudioType.VisualBreathTrigger:
            experienceAnalyticsHolder.changedSoundscapeAudioLevel = true;
            break;
        default:
            throw new Error(`Invalid audioType=${audioType}`);
    }

    GlobalAppState.setExperienceAnalyticsHolder(experienceAnalyticsHolder);
}

/**
 * Increment the number of clicks on the Randomise/Shuffle Touch Experience button
 */
export function incrementNumTouchpadRandomiseGlobal() {
    const experienceAnalyticsHolder = getGlobalAppState().getExperienceAnalyticsHolder();
    experienceAnalyticsHolder.numTouchpadRandomise++; // have to use ++ notation for the value to actuall increment
    GlobalAppState.setExperienceAnalyticsHolder(experienceAnalyticsHolder);
}

/**
 * Set the audio load time in seconds
 * 
 * @param audioLoadTimeSecs The time it took for the audio to load in seconds
 */
export function setExperienceLoadTimeGlobal(audioLoadTimeSecs: number) {
    const experienceAnalyticsHolder = getGlobalAppState().getExperienceAnalyticsHolder();
    experienceAnalyticsHolder.audioLoadTimeSecs = experienceAnalyticsHolder.audioLoadTimeSecs;
    GlobalAppState.setExperienceAnalyticsHolder(experienceAnalyticsHolder);
}