
export interface MinsAndSeconds {
    mins: string;
    secs: string;
}

/**
 * Convert milliseconds to seconds
 * @param millis the milliseconds to convert
 * @returns the millis in seconds (rounded to nearest second)
 */
export function convertMillisToSeconds(millis: number): number {
    return Math.round(millis / 1000);
}

export function convertMinsToMinsAndSeconds(mins: number): MinsAndSeconds {
    return convertSecondsToMinsAndSeconds(mins * 60);
}

export function convertSecondsToMinsAndSeconds(seconds: number): MinsAndSeconds {
    return {
        mins: String(Math.floor(seconds / 60)).padStart(2, '0'),
        secs: String(seconds % 60).padStart(2, '0'),
    }
}

export function getYYY_MM_DD_HH_mm_SSForTimestamp(timestamp: number): string {
    const d = new Date();
    d.setTime(timestamp!!);
    
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    const dd = String(d.getDate()).padStart(2, '0');
    const hh = String(d.getHours()).padStart(2, '0');
    const mn = String(d.getMinutes()).padStart(2, '0');
    const zz = String(d.getMilliseconds()).padStart(3, '0');
    return `${yyyy}-${mm}-${dd} ${hh}:${mn}:${zz}`;
}

export function getHH_mm_ForTimestamp(timestamp: number): string {
    const d = new Date();
    d.setTime(timestamp!!);
    return String(d.getHours()).padStart(2, '0') + ":" + String(d.getMinutes()).padStart(2, '0');
}

export function getHH_mm_ZZZ_ForTimestamp(timestamp: number): string {
    const d = new Date();
    d.setTime(timestamp!!);
    return String(d.getHours()).padStart(2, '0') 
            + ":" 
            + String(d.getMinutes()).padStart(2, '0')
            + ":" 
            + String(d.getMilliseconds()).padStart(3, '0');
}
