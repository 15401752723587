import steakMeter from "../assets/icons/streak-meter.svg";
import sampleAvatar from "../assets/icons/sampleAvatar.png";
import { TextId, getText } from "./Util/TextHelper";
import { isNullOrEmpty } from "./Data/Util/util";

export const ProfileBox = ({ currentStreak, numStreakDaysRemaining, user }) => {
  return (
    <div className="profile-box-user">
      <div className="avatar-container">
        <div className="profile-avatar">
          <img
            //display a default avatar if profile picture not available
            src={!isNullOrEmpty(user.profilePicture)
              ? user.profilePicture
              : sampleAvatar
            }
            className="profile-avatar-image"
            alt="profile avatar"
          ></img>
          <span class="badge">{currentStreak}</span>
        </div>
        <div className="user-info">
          <h3 className="name-tag">{user.name}</h3>
        </div>
      </div>
      <div className="profile-box-divider"></div>
      <div className="streak-container">
        <img src={steakMeter} alt="Streak Meter"></img>
        <div className="streak-text">
          <h3 className="streak-current">
            {getText(TextId.streakDaysMessage, {
              CURRENTSTREAK: currentStreak,
            })}
          </h3>
          <h4 className="streak-remaining">
            {getText(TextId.remaningStreakDaysMessage, {
              REMAINING: numStreakDaysRemaining,
            })}{" "}
          </h4>
        </div>
      </div>
    </div>
  );
};
