import React, { useEffect, useState } from "react";
import "../styles/timer.css"
import CountDown from "./CountDown";
import { AudioDurationSecs } from "./Data/constants";
import { LOGGER, LogLevel } from "./Util/Logger";

const Timer = (props) => {

    let unlimited = props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP;
    const [countDown, setCountDown] = useState(unlimited ? 0 : props.timeLimit);

    useEffect(() => {
        if (props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP) {
            props.setExperienceDuration(countDown);
        } else {
            props.setExperienceDuration(props.timeLimit - countDown);
        }
    }, [countDown, props.isPlaying]);

    useEffect(() => {
        let timerId;
        if (props.isPlaying && props.hasLoaded && props.hasStarted) {
            timerId = setInterval(() => {
                if (unlimited) {
                    setCountDown((countDown) => countDown + 1);
                } else {
                    setCountDown((countDown) => countDown - 1);
                }
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [props.isPlaying, props.hasLoaded, props.hasStarted]);

    useEffect(() => {
        if (countDown < 0) {
            LOGGER.log(LogLevel.DEBUG, "Timer countDown<0, setting expired to true");
            props.setExperienceDuration(props.timeLimit);
            props.setExpired(true);
            setCountDown(0);
        }
    }, [countDown]);

    return (
        <div style={{
            color: '#ffffff'
        }}>
            <CountDown 
                timeLimit={props.timeLimit} 
                countDown={countDown}
                hasLoaded={props.hasLoaded}
                hasStarted={props.hasStarted}
                closeExperiencePopup={props.closeExperiencePopup}
            />
        </div>
    )
}

export default Timer;