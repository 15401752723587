import { HowlerAudioPlayer } from "../Impl/HowlerAudioPlayer";
import { HtmlAudioPlayer } from "../Impl/HtmlAudioPlayer";
import { isIOS } from "../Util/DeviceUtil";

export enum AudioPlayer {
    StandardHtml = "StandardHtml",
    Howler = "Howler"
}

// The audio player for iOS
const IOS_AUDIO_PLAYER = AudioPlayer.Howler;

const AUDIO_PLAYER: AudioPlayer = isIOS()
    ? IOS_AUDIO_PLAYER
    : AudioPlayer.StandardHtml;

export const LOAD_TIME_MINIMUM_MILLIS = 1 * 1000; // 1 second

export enum AudioType {
    Binaural = "binaural",
    Music = "music",
    Soundscape = "soundscape",
    AudioBreath = "breath",
    VisualBreathTrigger = "visualBreathTrigger"
}

export interface AudioObj {
    id: string;
    type: AudioType;
    uri: string;
    isUnlimited: boolean;
    volume: number;
    isLogSuccess?: boolean;
    audio?: any;
}

export function getNewAudioPlayer(
    audios: AudioObj[],
    onLoaded: Function,
    onError: Function
) {
    switch (AUDIO_PLAYER) {
        case AudioPlayer.StandardHtml:
            return new HtmlAudioPlayer(audios, onLoaded, onError);
     
        case AudioPlayer.Howler:
            return new HowlerAudioPlayer(audios, onLoaded, onError);
        
    }
}

export interface AudioPlayerApi {
    
    getAudioObj(audioObj: AudioObj): Promise<any>;
    
    playAudioObj(audioObj: AudioObj): void;
    
    pauseAudioObj(audioObj: AudioObj): void;

    play(): void;

    pause(): void;

    setVolume(volume: number): void;

    setMusicVolume(volume: number): void;

    setBinauralVolume(volume: number): void;

    setNaturescapeVolume(volume: number): void;

    setAudioBreathVolume(volume: number): void;

    setVisualBreathTriggerVolume(volume: number): void;
    
    destroy(): void;
}
