//////////////////
// Import Files //
//////////////////

// Naturescapes (Soundscapes)
import beach3File from "../../assets/audio/Nature/beach_03mins.mp3"
import beach10File from "../../assets/audio/Nature/beach_10mins.mp3"
import beach25File from "../../assets/audio/Nature/beach_25mins.mp3"
import tropicalBeach3File from "../../assets/audio/Nature/tropicalBeach_03mins.mp3"
import tropicalBeach10File from "../../assets/audio/Nature/tropicalBeach_10mins.mp3"
import tropicalBeach25File from "../../assets/audio/Nature/tropicalBeach_25mins.mp3"
import forest3File from "../../assets/audio/Nature/forest_03mins.mp3"
import forest10File from "../../assets/audio/Nature/forest_10mins.mp3"
import forest25File from "../../assets/audio/Nature/forest_25mins.mp3"
import meadow3File from "../../assets/audio/Nature/meadow_03mins.mp3"
import meadow10File from "../../assets/audio/Nature/meadow_10mins.mp3"
import meadow25File from "../../assets/audio/Nature/meadow_25mins.mp3"
import fireplace3File from "../../assets/audio/Nature/fireplace_03mins.mp3"
import fireplace10File from "../../assets/audio/Nature/fireplace_10mins.mp3"
import fireplace25File from "../../assets/audio/Nature/fireplace_25mins.mp3"
import rain3File from "../../assets/audio/Nature/rain_03mins.mp3"
import rain10File from "../../assets/audio/Nature/rain_10mins.mp3"
import rain25File from "../../assets/audio/Nature/rain_25mins.mp3"
import rainforest3File from "../../assets/audio/Nature/rainforest_03mins.mp3"
import rainforest10File from "../../assets/audio/Nature/rainforest_10mins.mp3"
import rainforest25File from "../../assets/audio/Nature/rainforest_25mins.mp3"
import gardenAmbience3File from "../../assets/audio/Nature/gardenAmbience_03mins.mp3"
import gardenAmbience10File from "../../assets/audio/Nature/gardenAmbience_10mins.mp3"
import gardenAmbience25File from "../../assets/audio/Nature/gardenAmbience_25mins.mp3"
import whitenoise3File from "../../assets/audio/Nature/whiteNoise_03mins.mp3"
import whitenoise10File from "../../assets/audio/Nature/whiteNoise_10mins.mp3"
import whitenoise25File from "../../assets/audio/Nature/whiteNoise_25mins.mp3"
import brownnoise3File from "../../assets/audio/Nature/brownnoise-soundscape-3min.mp3"
import brownnoise10File from "../../assets/audio/Nature/brownNoise--soundscape-10min.mp3"
import brownnoise25File from "../../assets/audio/Nature/brownNoise--soundscape-25min.mp3"
import babblingBrook3File from "../../assets/audio/Nature/babblingbrook-soundscape-3min.mp3"
import babblingBrook10File from "../../assets/audio/Nature/babblingbrook-soundscape-10min.mp3"
import babblingBrook25File from "../../assets/audio/Nature/babblingbrook-soundscape-25min.mp3"
import desert3File from "../../assets/audio/Nature/desert-soundscape-3min.mp3"
import desert10File from "../../assets/audio/Nature/desert-soundscape-10min.mp3"
import desert25File from "../../assets/audio/Nature/desert-soundscape-25min.mp3"
import lakeAtNight3File from "../../assets/audio/Nature/lakeatnight-soundscape-3min.mp3"
import lakeAtNight10File from "../../assets/audio/Nature/lakeatnight-soundscape-10min.mp3"
import lakeAtNight25File from "../../assets/audio/Nature/lakeatnight-soundscape-25min.mp3"
import mountain3File from "../../assets/audio/Nature/mountain-soundscape-3min.mp3"
import mountain10File from "../../assets/audio/Nature/mountain-soundscape-10min.mp3"
import mountain25File from "../../assets/audio/Nature/mountain-soundscape-25min.mp3"
import ocean3File from "../../assets/audio/Nature/ocean-soundscape-3min.mp3"
import ocean10File from "../../assets/audio/Nature/ocean-soundscape-10min.mp3"
import ocean25File from "../../assets/audio/Nature/ocean-soundscape-25min.mp3"
import train3File from "../../assets/audio/Nature/train-soundscape-3min.mp3"
import train10File from "../../assets/audio/Nature/train-soundscape-10min.mp3"
import train25File from "../../assets/audio/Nature/train-soundscape-25min.mp3"

// Music
import focusmusic3File from "../../assets/audio/Music/focus--music-3min_v02.mp3"
import focusmusic10File from "../../assets/audio/Music/focus--music-10min_v02.mp3"
import focusmusic20File from "../../assets/audio/Music/focus--music-20min_v02.mp3"
import focusmusic25File from "../../assets/audio/Music/focus--music-25min_v02.mp3"
import relaxmusic3File from "../../assets/audio/Music/relax--music-3min_v02.mp3"
import relaxmusic10File from "../../assets/audio/Music/relax--music-10min_v02.mp3"
import relaxmusic20File from "../../assets/audio/Music/relax--music-20min_v02.mp3"

// Binaural
import focusbinaural3File from "../../assets/audio/Binaural/focus--binaural-03min--v02.mp3"
import focusbinaural10File from "../../assets/audio/Binaural/focus--binaural-10min--v02.mp3"
import focusbinaural20File from "../../assets/audio/Binaural/focus--binaural-20min--v02.mp3"
import focusbinaural25File from "../../assets/audio/Binaural/focus--binaural-25min--v02.mp3"
import relaxbinaural3File from "../../assets/audio/Binaural/relax_binaural_3min.mp3"
import relaxbinaural10File from "../../assets/audio/Binaural/relax--binaural-10min--v02.mp3"
import relaxbinaural20File from "../../assets/audio/Binaural/relax--binaural-20min--v02.mp3"

// Audio-Guided Breath
import relaxaudioguidedbreathing4File from "../../assets/audio/Breath/RelaxBreathe5mins_2022-10-25_v03.mp3"
import focusaudioguidedbreathing5File from "../../assets/audio/Breath/FocusBreathe5mins_2022-11-14_v03.mp3"

// Visual-Guided Breath triggers
import breathTriggerHold1File from "../../assets/audio/Breath/Triggers/Hold_1.mp3"
import breathTriggerIn1File from "../../assets/audio/Breath/Triggers/In_1.mp3"
import breathTriggerOut1File from "../../assets/audio/Breath/Triggers/Out_1.mp3"
import breathTriggerNum02File from "../../assets/audio/Breath/Triggers/Number_02.mp3"
import breathTriggerNum03File from "../../assets/audio/Breath/Triggers/Number_03.mp3"
import breathTriggerNum04File from "../../assets/audio/Breath/Triggers/Number_04.mp3"
import breathTriggerNum05File from "../../assets/audio/Breath/Triggers/Number_05.mp3"
import breathTriggerNum06File from "../../assets/audio/Breath/Triggers/Number_06.mp3"
import breathTriggerNum07File from "../../assets/audio/Breath/Triggers/Number_07.mp3"


/////////////
// Exports //
/////////////


// Naturescapes (Soundscapes)
export const beach3 = beach3File;
export const beach10 = beach10File;
export const beach25 = beach25File;
export const tropicalBeach3 = tropicalBeach3File
export const tropicalBeach10 = tropicalBeach10File
export const tropicalBeach25 = tropicalBeach25File
export const forest3 = forest3File
export const forest10 = forest10File
export const forest25 = forest25File
export const meadow3 = meadow3File
export const meadow10 = meadow10File
export const meadow25 = meadow25File
export const fireplace3 = fireplace3File
export const fireplace10 = fireplace10File
export const fireplace25 = fireplace25File
export const rain3 = rain3File
export const rain10 = rain10File
export const rain25 = rain25File
export const rainforest3 = rainforest3File
export const rainforest10 = rainforest10File
export const rainforest25 = rainforest25File
export const gardenAmbience3 = gardenAmbience3File
export const gardenAmbience10 = gardenAmbience10File
export const gardenAmbience25 = gardenAmbience25File
export const whitenoise3 = whitenoise3File
export const whitenoise10 = whitenoise10File
export const whitenoise25 = whitenoise25File
export const brownnoise3 = brownnoise3File
export const brownnoise10 = brownnoise10File
export const brownnoise25 = brownnoise25File
export const babblingBrook3 = babblingBrook3File
export const babblingBrook10 = babblingBrook10File
export const babblingBrook25 = babblingBrook25File
export const desert3 = desert3File
export const desert10 = desert10File
export const desert25 = desert25File
export const lakeAtNight3 = lakeAtNight3File
export const lakeAtNight10 = lakeAtNight10File
export const lakeAtNight25 = lakeAtNight25File
export const mountain3 = mountain3File
export const mountain10 = mountain10File
export const mountain25 = mountain25File
export const ocean3 = ocean3File
export const ocean10 = ocean10File
export const ocean25 = ocean25File
export const train3 = train3File
export const train10 = train10File
export const train25 = train25File

// Music
export const focusmusic3 = focusmusic3File
export const focusmusic10 = focusmusic10File
export const focusmusic20 = focusmusic20File
export const focusmusic25 = focusmusic25File
export const relaxmusic3 = relaxmusic3File
export const relaxmusic10 = relaxmusic10File
export const relaxmusic20 = relaxmusic20File

// Binaural
export const focusbinaural3 = focusbinaural3File
export const focusbinaural10 = focusbinaural10File
export const focusbinaural20 = focusbinaural20File
export const focusbinaural25 = focusbinaural25File
export const relaxbinaural3 = relaxbinaural3File
export const relaxbinaural10 = relaxbinaural10File
export const relaxbinaural20 = relaxbinaural20File

// Audio-Guided Breath
export const relaxaudioguidedbreathing4 = relaxaudioguidedbreathing4File
export const focusaudioguidedbreathing5 = focusaudioguidedbreathing5File

// Visual-Guided Breath triggers
export const breathTriggerHold1 = breathTriggerHold1File
export const breathTriggerIn1 = breathTriggerIn1File
export const breathTriggerOut1 = breathTriggerOut1File
export const breathTriggerNum02 = breathTriggerNum02File
export const breathTriggerNum03 = breathTriggerNum03File
export const breathTriggerNum04 = breathTriggerNum04File
export const breathTriggerNum05 = breathTriggerNum05File
export const breathTriggerNum06 = breathTriggerNum06File
export const breathTriggerNum07 = breathTriggerNum07File