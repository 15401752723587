import React, { useEffect } from "react";
import "../styles/muscular.css"

const Muscular = (props) => {
  const url = `muscular.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);

  return (
    <>
    <canvas id= "muscular"> </canvas>
    </>
  );
}

export default Muscular;