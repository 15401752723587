import "../styles/timer.css"
import { AudioDurationSecs} from "./Data/constants";
import { convertSecondsToMinsAndSeconds } from "./Util/TimeUtil";

const CountDown = (props) => {

    const unlimited = props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP;

    const timePassedTotalSecs = props.countDown;
    const timePassed = convertSecondsToMinsAndSeconds(timePassedTotalSecs);
    const timePassedFormatted = `${timePassed.mins}:${timePassed.secs}`;
    
    const timeDuration = convertSecondsToMinsAndSeconds(props.timeLimit);
    const timeDurationFormatted = `${timeDuration.mins}:${timeDuration.secs}`;

    const getTimeFormatted = () => {
        return unlimited 
        ? `${timePassedFormatted}`
        : `${timePassedFormatted} | ${timeDurationFormatted}`;
    }

    const getTimeFormattedAlt = () => {
        const timePassedStr = `${timePassed.mins} minutes and ${timePassed.secs} seconds`;
        return unlimited 
            ? `${timePassedStr} into the experience`
            : `${timePassedStr} remaining of the ${timeDuration.mins} minutes and ${timeDuration.secs} seconds Experience time.`;
    }

    return (
    <div className="timer"
        tabIndex={props.hasLoaded && props.hasStarted && !props.closeExperiencePopup ? 0 : -1}
        alt={getTimeFormattedAlt()}
        aria-label={getTimeFormattedAlt()}
    >
        {getTimeFormatted()}
    </div>
    )
}

export default CountDown;