import React, { useEffect, useState } from "react";
import "../../styles/experience-summary.css"
import arrow_back from "../../assets/icons/arrow_back.svg"
import focus_swirl from "../../assets/icons/focus-swirl.svg"
import naturescape_icon from "../../assets/icons/forrest-icon.svg"
import relax_swirl from "../../assets/icons/relax-vector.svg"
import timer_icon from "../../assets/icons/timer-icon.svg"
import touch_icon from "../../assets/icons/touch.svg"
import sound_icon from "../../assets/icons/sound.svg"
import breathe_icon from "../../assets/icons/breathe.svg"
import headphone_icon from "../../assets/icons/headphone-icon.svg"
import logo from "../../assets/icons/zensory_logo.svg"
import cross from "../../assets/icons/close.svg"
import { ZensoryModule } from "../../ZensoryModule";
import { withScorm } from 'react-scorm-provider';
import { Interweave } from "interweave";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { AudioDurationSecs, getAudioDurationInMins, getMoodIdTitle, getSenseTitle, MoodId, SenseId } from "../Data/constants";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import LearnWhyPopup from "../LearnWhyPopup";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import CloseAndExitPopup from "../Close&ExitPopup";
import { GlobalAppState } from "../AppState";

const ExperienceSummaryPage = (props) => {

  const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
  const isShowBackButton = ZensoryModule.getFeatureUi().isShowBackButton();
  const [showLearnWhyPopup, setShowLearnWhyPopup] = useState(false);
  const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);

  // check the Experience props
  ZensoryModule.getNavigationHandler().checkExperiencePage(window, RouteId.ExperienceSummary, props);

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenExperienceSummary);
  }, []);
  
  const startExperience = (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.Experience, props.onStart, props.onexperienceSummary);
  }

  const back = (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().back(window, props.onClose, props.onexperienceSummary, false, GlobalAppState.getIsDeepLink());
  }

  const close = (e) => {
    preventDefault(e);
    setShowCloseAndExitPopup(true);
    ZensoryModule.getNavigationHandler().exit(window, props.sco);
  }

  // transform clickable text with an underline
  const transformText = (node, children) => {
    if (node.tagName.toLowerCase() === "a") {
      return <span
        id="link-learn-why" 
        style={{textDecoration:"underline", cursor:"pointer"}} 
        onClick={()=>{setShowLearnWhyPopup(!showLearnWhyPopup)}}
        tabIndex={!showLearnWhyPopup ? "0" : "-1"}
        role="button"
        alt={getText(TextId.experienceSummaryInstructionLearnWhy)}
        aria-label={getText(TextId.experienceSummaryInstructionLearnWhy)}
        onKeyDown={(e) => _handleKeyDownLearnWhy(e)}>
        {children}
      </span>;
    }
  };

  // handle return key presses on the back button
  const _handleKeyDownBack = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      back(e);
    }
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  }
  
  // handle return key presses on the learn why button
  const _handleKeyDownLearnWhy = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      setShowLearnWhyPopup(!showLearnWhyPopup);
    }
  }

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    }}>
      { showLearnWhyPopup 
          ? <LearnWhyPopup
            setShowLearnWhyPopup={setShowLearnWhyPopup}
            mood={props.mood} />
          : null
      }
      { showCloseAndExitPopup
          ? <CloseAndExitPopup setShowCloseAndExitPopup={setShowCloseAndExitPopup}/>
          : null
      }
      <div className="header-row-choose">
        { isShowBackButton 
        ? <div 
            id="btn-exp-summary-back"
            className="back"
            onClick={(e) => back(e)}
            tabIndex={!showLearnWhyPopup ? "0" : "-1"}
            onKeyDown={(e) => _handleKeyDownBack(e)}
            role="button"
            alt={getText(TextId.backButtonAlt, { GENERIC: ""})}
            aria-label={getText(TextId.backButtonAlt, { GENERIC: ""})} 
        >
          <img 
            src={arrow_back} 
            style={{ marginTop: "2px" }} 
            alt="Back to Experience options"
          />
            <span>
              { GlobalAppState.getIsDeepLink()
                ? "Home"
                : "Settings"
              } 
            </span>
        </div>
        : <div
            className="placeholder">
        </div> }

        { isShowExitButton 
          ? <div className="close"
                id="btn-exp-summary-close"
                onClick={(e) => close(e)}
                tabIndex={!showLearnWhyPopup ? "0" : "-1"}
                onKeyDown={(e) => _handleKeyDownCloseExit(e)}
                alt={getText(TextId.mainCloseAndExitAriaLabel)}
                aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
                role="button" >
              <img 
                src={cross} 
                style={{ marginTop: "2px" }} 
                alt="Close icon" 
              />
              <span>{getText(TextId.mainCloseAndExitText)}</span>
            </div> 
          : null
        }
        <div className="logo-container">
          <img 
            className="logo" 
            src={logo} 
            alt="Zensory logo"
          />
        </div>
      </div>
      <div className="choose-feeling-container">
        <h1 className="major-heading-feeling">
        <Interweave
            content={getText(TextId.experienceSummaryTitle)}
        />
        </h1>
        <div className="box-row-emojis">
          <div className={ "emoji-box"}>
            <img 
              src={props.mood === MoodId.Focused ? focus_swirl : relax_swirl} 
              height={90} 
              alt="Goal icon"
            />
            <div className="feeling-box-heading">
            <Interweave
                content={getText(TextId.experienceSummaryGoalText)}
              />
            </div>
            <div className="feeling-box-subheading">
            <Interweave
                content={getMoodIdTitle(props.mood).toLocaleLowerCase()}
            />
            </div>
          </div>
          <div
            className={ "emoji-box"} >
            <img 
              src={
                props.zense === SenseId.Sound 
                  ? sound_icon 
                  : props.zense === SenseId.Breath 
                    ? breathe_icon 
                    : touch_icon
              } 
              height={90} 
              alt="Zense icon" />
            <div className="feeling-box-heading">
            <Interweave
                content={getText(TextId.experienceSummaryZenseText)}
            />
            </div>
            <div className="feeling-box-subheading">
            <Interweave
                content={getSenseTitle(props.zense)}
              />
            </div>
          </div>
          { props.zense === SenseId.Sound 
            ? <div className={ "emoji-box"} >
                <img 
                  src={naturescape_icon} 
                  height={90} 
                  alt="Naturescape icon"
                />
                <div className="feeling-box-heading">
                <Interweave
                  content={getText(TextId.experienceSummaryExperienceText)}
                />
                </div>
                <div className="feeling-box-subheading">
                  { props.zense === SenseId.Sound 
                    // for Sound experiences, get the Soundscape name (do not just use the soundscapeId)
                    ? ZensoryModule.getData().getSoundscape(props.experience).name
                    : props.experience
                  }
                </div>
              </div>  
            : null
          }
          <div
            className={ "emoji-box"} >
            <img 
              src={timer_icon} 
              height={90} 
              alt="Time icon"
            />
            <div className="feeling-box-heading">
            <Interweave
                content={getText(TextId.experienceSummaryTimeText)}
            />
            </div>
            <div className="feeling-box-subheading">
              {props.timeLimit === -1 ? "Unlimited" : `${getAudioDurationInMins(props.timeLimit)} min`}
            </div>
          </div>
        </div>
        <div className="experience-instruction">
            <img 
              className="headphone-icon" 
              src={headphone_icon} 
              alt="headphones icon"
            />
            <div
              alt={getText(TextId.experienceSummaryInstructionAriaText)}
              aria-label={getText(TextId.experienceSummaryInstructionAriaText)}
              role="contentinfo"
            >
              <Interweave
                content={getText(TextId.experienceSummaryInstructionText)}
                transform={transformText}
              />
            </div>
        </div>
        <div className="learn-more">
          <Interweave
            content={getText(TextId.customizeExperienceTouchWarningText)}
          />
        </div>
        <div className="button-container">
          <button 
            id="btn-exp-summary-next"
            className="next-button"
            tabIndex={!showLearnWhyPopup ? "0" : "-1"}
            onClick={(e) => startExperience(e)}>
              <Interweave
                content={getText(TextId.experienceSummaryButtonText)}
              />
          </button>
        </div>
      </div>
    </div>
  );
};

const ExperienceSummary = withScorm()(ExperienceSummaryPage);

export default ExperienceSummary;
