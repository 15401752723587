import React, { useEffect } from "react";
import "../styles/neon-bubbles.css"

const NeonBubbles = () => {
    const url = "neon-bubbles.js"

    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
      const startDiv = document.getElementById('startNeonBubbles');
      startDiv.click();
      const stopDiv = document.getElementById('stopNeonBubbles');
      
      return () => {
        stopDiv.click();
        document.body.removeChild(script);
      }
    }, [url]);
    
    return (
      <>
        <div id="stopNeonBubbles"></div>
        <div id="startNeonBubbles"></div>
        <canvas id="canvas"></canvas>
      </>
    );
}

export default NeonBubbles;