import { AudioObj, AudioType } from "../API/AudioPlayerAPI";
import { ExperienceId, SenseId, SoundscapeId } from "../Data/constants";
import { getExperienceAudioLevelDefaults } from "../Data/Setup/data_experience_senses";

export const DEFAULT_GLOBAL_VOLUME_PERCENT = 75;

const AUDIO_LEVELS = getExperienceAudioLevelDefaults();

export const getAudioObjLevel = (zenseId: SenseId, experienceId: ExperienceId, audioType: AudioType) => {
    switch (audioType) {

        case AudioType.Binaural:
            // for White and Brown noise experiences, reduce Binaural audio level because it's over powering
            if (zenseId === SenseId.Sound 
                && (experienceId === SoundscapeId.WhiteNoise
                    || experienceId === SoundscapeId.BrownNoise)
                ) 
            {
                return AUDIO_LEVELS.binnauralWhiteBrownNoise;
            } else {
                return AUDIO_LEVELS.binnaural;
            }
        
        case AudioType.Music:
            return AUDIO_LEVELS.music;

        case AudioType.Soundscape:
            if (experienceId === SoundscapeId.WhiteNoise 
                || experienceId === SoundscapeId.BrownNoise)
            {
                return AUDIO_LEVELS.soundscapeWhiteBrownNoise;
            } else {
                return AUDIO_LEVELS.soundscape;
            }
        
        case AudioType.AudioBreath:
            return AUDIO_LEVELS.audioBreath;

        case AudioType.VisualBreathTrigger:
            return AUDIO_LEVELS.visualBreathTrigger;
        
        default:
            throw new Error(`Invalid type=${audioType}`);
    }
}

export function audioObjMapToArray(map: {[key: string]: AudioObj}): AudioObj[] {
    const arr: AudioObj[] = [];
    for (const audioObj of Object.values(map)) {
        arr.push(audioObj);
    }
    return arr;
}