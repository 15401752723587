
import { FeatureUiApi } from "../API/FeatureUiApi";
import { ImageLoaderApi } from "../API/ImageLoaderApi";
import { NavigationHandlerApi } from "../API/NavigationHandlerApi";
import { SessionHandlerApi } from "../API/SessionHandlerApi";
import { EnvId } from "../Data/constants";
import { SimpleSessionHandler } from "../Impl/SimpleSessionHandler";
import { WebFeatureUiImpl } from "../Impl/Web/WebFeatureUi";
import { ImageLoaderWeb } from "../Impl/Web/WebImageLoader";
import { WebNavigationHandler } from "../Impl/Web/WebNavigationHandler";
import { BaseProvider } from "./BaseProvider";
import { ProviderApi } from "./ProviderApi";

export class ProviderWeb extends BaseProvider implements ProviderApi {
    
    // eslint-disable-next-line
    constructor(envId: EnvId, navigate: any) {
        super(envId);
    }

    createImageLoader(): ImageLoaderApi {
        return new ImageLoaderWeb();
    }

    createSessionHandler(): SessionHandlerApi {
        return new SimpleSessionHandler();
    }   
    
    createNavigationHandler(): NavigationHandlerApi {
        return new WebNavigationHandler();
    }

    createFeatureUi(): FeatureUiApi {
        return new WebFeatureUiImpl();
    }

}