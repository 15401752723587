import "../../styles/layout.css"
import logo from "../../assets/icons/zensory_logo.svg"
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";

const PageNotFound = () => {
  return (
    <div className="experience-container">
      <img src={logo} width={300} height={200}/>
      <h1 className={"major-heading-page-not-found"}>
        <Interweave
          content={getText(TextId.pageNotFoundTitle)}
        />
      </h1>
    </div>
  );
};

export default PageNotFound;
