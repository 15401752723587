import cross from "../assets/icons/close.svg"
import info from "../assets/icons/info.svg"
import { isSubmitKey, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import { Interweave } from "interweave";
import { getText, TextId } from "./Util/TextHelper";

const CloseAndExitPopup = (props) => {

    const closePopup = (e) =>{
        preventDefault(e);
        props.setShowCloseAndExitPopup(false);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            closePopup(e);
        }
    }

    return (
        <div className="popup-container-close">
            <div className='popup-header'>
            <img 
                src={info} 
                className="popup-info-icon" 
                alt="More information button" />
            <img 
                id="btn-exit-popup-close"
                src={cross} 
                className="popup-close-icon" 
                onClick={(e)=>closePopup(e)} 
                alt="Close popup"
                tabIndex="1" 
                onKeyDown={(e) => _handleKeyDownClose(e)}
            />
            </div>
            <h1 className="popup-heading">
                <Interweave
                    content={getText(TextId.closeAndExitPopupHeading)}
                />
            </h1>
            <div className='popup-instruction-close'>
                <Interweave
                    content={getText(TextId.closeAndExitPopupText)}
                />
            </div>
            <button
                id="btn-exit-popup-okay"
                className="okay-button"
                onClick={(e) => closePopup(e)}>
                Okay
            </button>
        </div>
    );
};

export default CloseAndExitPopup;