import { isNullOrEmpty } from "../Data/Util/util";

export const PRE_POST_PATTERN = "%%";

export enum SubstituteId {
    GENERIC = "GENERIC",
    Mood = "MOOD",
    Sense =  "SENSE"
}

export type SubstitutionMap = {[substituteId in SubstituteId]?: string};

export function substituteStrings(text: string, substitutionMap?: SubstitutionMap): string {
    if (isNullOrEmpty(substitutionMap)) {
        return text;
    }

    const substitutionIds = Object.keys(substitutionMap!!) as SubstituteId[];
    for (var substituteId of substitutionIds) {
        text = text.replaceAll(PRE_POST_PATTERN + substituteId + PRE_POST_PATTERN, substitutionMap!![substituteId]!!);
    }
    return text;
}
