import cross from "../assets/icons/close.svg"
import ReactSlider from 'react-slider';
import "../styles/slider.css"
import { isSubmitKey, preventDefault, stopPropagation } from "./UI/KeyPressHelper";
import { useEffect, useRef } from "react";
import { isNullOrEmpty } from "./Data/Util/util";
import { handleTabKey } from "./Util/ModalAccessibilityHelper";
import { KeyCode } from "./UI/KeyEnum";
import { hasMusicAudio, hasNaturescapeAudio, isAudioGuidedBreath, isVisualGuidedBreath, SenseId } from "./Data/constants";
import { setExperienceChangeAudioLevelsGlobal } from "./Util/ExperienceAnalyticsHelper";
import { AudioType } from "./API/AudioPlayerAPI";

const VolumeSetting = (props) => {

  const modalRef = useRef();
  
  useEffect(() => {
    const sliderThumb = document.getElementsByClassName("customSlider-thumb");
    if (!isNullOrEmpty(sliderThumb)) {
      sliderThumb[0].focus();
    }
  }, []);

  useEffect(() => {
    function keyListener(e) {
        const listener = e.keyCode === KeyCode.Tab 
          ? handleTabKey(e, modalRef,'img, div.customSlider-thumb')
          : e.keyCode === KeyCode.Esc
          ? closeVolumeSetting(e) 
          : null;
        return listener && listener(e, modalRef,'img, div.customSlider-thumb');
      }
    document.addEventListener("keydown", keyListener);
    return () => document.removeEventListener("keydown", keyListener);
  },[]);

  const closeVolumeSetting = (e) =>{
      preventDefault(e);
      props.setShowVolumeSetting(false);
  }
  
  // handle return key presses on the close popup icon
  const _handleKeyDownClose = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      closeVolumeSetting(e);
    }
  }

  /**
   * Set the Binaural volume value and track that the Binuaral volume was changed
   * 
   * @param {number} volume the volume value (1-100) to set for Binuaral
   */
  const setBinauralVolume = (volume) => {
    props.setBinauralVolume(volume);
    setExperienceChangeAudioLevelsGlobal(AudioType.Binaural);
  }

  /**
   * Set the Music volume value and track that the Music volume was changed
   * 
   * @param {number} volume the volume value (1-100) to set for Music
   */
  const setMusicVolume = (volume) => {
    props.setMusicVolume(volume);
    setExperienceChangeAudioLevelsGlobal(AudioType.Music);
  }

  /**
   * Set the Naturescape volume value and track that the Naturescape volume was changed
   * 
   * @param {number} volume the volume value (1-100) to set for Naturescape
   */
  const setNaturescapeVolume = (volume) => {
    props.setNaturescapeVolume(volume);
    setExperienceChangeAudioLevelsGlobal(AudioType.Soundscape);
  }

  /**
   * Set the Audio-Guided Breath volume value and track that the Audio-Guided Breath volume was changed
   * 
   * @param {number} volume the volume value (1-100) to set for Audio-Guided Breath
   */
  const setAudioBreathVolume = (volume) => {
    props.setAudioBreathVolume(volume);
    setExperienceChangeAudioLevelsGlobal(AudioType.AudioBreath);
  }

  /**
   * Set the Visual-Guided Breath volume value and track that the Visual-Guided Breath volume was changed
   * 
   * @param {number} volume the volume value (1-100) to set for Visual-Guided Breath
   */
  const setVisualBreathTriggerVolume = (volume) => {
    props.setVisualBreathTriggerVolume(volume);
    setExperienceChangeAudioLevelsGlobal(AudioType.VisualBreathTrigger);
  }

  return (
    <div className="popup-container-volume"
        ref={modalRef}>
        <div className='popup-header'>
        <img 
          id="btn-volume-setting-close"
          src={cross} 
          className="popup-close-icon"
          role="button"
          alt="Close volume" 
          onClick={(e)=>closeVolumeSetting(e)}
          tabIndex="0"
          onKeyDown={(e) => _handleKeyDownClose(e)} />
        </div>
        <h1 className="popup-heading-volume">Volume</h1>
        <div className="popup-instruction-volume">Change the audio level of each audio track.</div>
        { !isAudioGuidedBreath(props.zense, props.subZense)
        ? <div className="react-slider-container">
          <div className="slider-value">
              {props.binauralVolume}%
              <div className="slider-value-desc">Binaural</div>
          </div>
          <ReactSlider
            id="slider-binaural"
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            defaultValue={0}
            value={props.binauralVolume}
            onChange={(value) => setBinauralVolume(value)}
            tabIndex="0"
            autofocus
          />
        </div>
        : null }
        { !isAudioGuidedBreath(props.zense, props.subZense) && hasMusicAudio(props.experience)
        ? <div className="react-slider-container">
          <div className="slider-value">
              {props.musicVolume}%
              <div className="slider-value-desc">Music</div>
          </div>
          <ReactSlider
            id="slider-music"
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            defaultValue={0}
            value={props.musicVolume}
            onChange={(value) => setMusicVolume(value)}
            tabIndex="0"
            autofocus
          />
        </div>
        : null }
        {props.zense === SenseId.Sound && hasNaturescapeAudio(props.experience)
        ? <div className="react-slider-container">
          <div className="slider-value">
              {props.naturescapeVolume}%
              <div className="slider-value-desc">Naturescape</div>
          </div>
          <ReactSlider
            id="slider-naturescape"
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            defaultValue={0}
            value={props.naturescapeVolume}
            onChange={(value) => setNaturescapeVolume(value)}
            tabIndex="0"
            autofocus
          />
        </div>
        : null }
        {isAudioGuidedBreath(props.zense, props.subZense)
        ? <div className="react-slider-container">
          <div className="slider-value">
              {props.audioBreathVolume}%
              <div className="slider-value-desc">Breath</div>
          </div>
          <ReactSlider
            id="slider-breath"
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            defaultValue={0}
            value={props.audioBreathVolume}
            onChange={(value) => setAudioBreathVolume(value)}
            tabIndex="0"
            autofocus
          />
        </div>
        : null }
        {isVisualGuidedBreath(props.zense, props.subZense)
        ? <div className="react-slider-container">
          <div className="slider-value">
              {props.visualBreathTriggerVolume}%
              <div className="slider-value-desc">Breath</div>
          </div>
          <ReactSlider
            id="slider-breath-trigger"
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            defaultValue={0}
            value={props.visualBreathTriggerVolume}
            onChange={(value) => setVisualBreathTriggerVolume(value)}
            tabIndex="0"
            autofocus
          />
        </div>
        : null }
    </div>
)};

export default VolumeSetting;