import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Experience from "./experiences/Experience";
import Layout from "./components/Layout";
import "./styles/layout.css"
import "./index.css"
import Welcome from "./components/Screens/Welcome";
import Profile from "./components/Screens/Profile";

import ChooseZense from "./components/Screens/ChooseZense";
import ChooseNaturescape from "./components/Screens/ChooseNaturescape";
import ExperienceSummary from "./components/Screens/ExperienceSummary";
import ChooseBreathwork from "./components/Screens/ChooseBreathwork";
import Review from "./components/Screens/Review";
import ChooseTouchpad from "./components/Screens/ChooseTouchpad";
import ScormProvider from 'react-scorm-provider';
import { Routes, Route, Navigate } from 'react-router-dom'
import { setGlobalAppState } from "./components/AppState";
import { SoundscapeId, SenseId, AudioDurationSecs, UserActivityType } from "./components/Data/constants";
import { getConfiguration, isDebug } from "./components/Util/ConfigHelper";
import { DeploymentType, isUseLicenses } from "./components/Data/Models/configuration";
import { RouteId } from "./Routes";
import { handleBackNavigation, handleNavigationfromParams, queryParams } from "./components/Util/SearchParamHelper";
import { listenToFullScreenEnter } from "./components/Util/ScreenUtil";
import PageNotFound from "./components/Screens/PageNotFound";
import AfterExperience from "./components/Screens/AfterExperience";
import { getExperienceAnalyticsHolderDefault } from "./components/Util/ExperienceAnalyticsHelper";
import Login from "./components/Screens/Login";
import { LOGGER, LogLevel } from "./components/Util/Logger";
import { ZensoryModule } from "./ZensoryModule";
import { isNullOrEmpty } from "./components/Data/Util/util";
import { DEEP_LINK_PARAM_AUDIO_DURATION_SECS, DEEP_LINK_PARAM_AUTH_TOKEN, DEEP_LINK_PARAM_AUTO_PLAY, DEEP_LINK_PARAM_EXPERIENCE_ID, DEEP_LINK_PARAM_MOOD_ID, DEEP_LINK_PARAM_SOURCE, DEEP_LINK_PARAM_SUB_ZENSE_ID, DEEP_LINK_PARAM_ZENSE_ID } from "./components/Util/DeepLinkUtil";
import SignUp from "./components/Screens/SignUp";
import ForgotPassword from "./components/Screens/ForgotPassword";
import Subscribe from "./components/Screens/Subscribe";
import AuthErrorPopup from "./components/AuthErrorPopup";
import ManageSubcription from "./components/Screens/ManageSubscription";
import { checkUserLicenses } from "./components/Impl/Licenses/LicenseHelper";

const App = () => {
  const [start, setStart] = useState(false);
  const [restart, setRestart] = useState(false);
  const [chooseZense, setChooseZense] = useState(false);
  const [chooseNaturescape, setChooseNaturescape] = useState(false);
  const [chooseBreathwork, setChooseBreathwork] = useState(false);
  const [chooseTouchpad, setChooseTouchpad] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState(false);
  const [review, setReview] = useState(false);
  const [afterExperience, setAfterExperience] = useState(false);
  const [beforeRating, setBeforeRating] = useState(0);
  const [afterRating, setAfterRating] = useState();
  const [expired, setExpired] = useState(false);
  // MoodId enum to track the Mood selected for the Experience
  const [mood, setMood] = useState("");
  // SenseId enum
  const [zense, setZense] = useState("");
  // SubSenseId enum
  const [subZense, setSubZense] = useState("");
  // For Sound, this is "soundscapeId". For Breath, this is the type of Breath experience (eg. audio/visual)
  const [experience, setExperience] = useState(SoundscapeId.NoNature);
  // Always an AudioDurationSecs enum
  const [timeLimit, setTimeLimit] = useState(AudioDurationSecs.THREE_3_MINS);
  // Duration of the Experience
  const [experienceDuration, setExperienceDuration] = useState(0);
  // Signed-in User object
  const [user, setUser] = useState(null);
  // Store Full Screen state outside of Experience so we keep track in between Experiences
  const [isFullScreen, setIsFullScreen] = useState(false);
  // Track if we came from a deep link
  const [isDeepLink, setIsDeepLink] = useState(false);
  // Set to TRUE when the user is signing up, so we know to wait for that to complete before we load the user
  const [isSigningUp, setIsSigningUp] = useState(false);
  // Whether a user is logged in, set it to "undefined" to prevent redirection before authentication
  const [isLoggedIn, setIsLoggedIn] = useState("undefined");
  // track meta data about the experience in 1 holder object
  const [getExperienceAnalyticsHolder, setExperienceAnalyticsHolder] = useState(
    getExperienceAnalyticsHolderDefault()
  );
  // Used to track native browser back button clicks (for ExperienceDeepLinkOnly mode)
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const [licenceError, setLicenseError] = useState();
  const [showLicenseErrorPopup, setShowLicencesErrorPop] = useState();

  const backNavRef = useRef();
  backNavRef.current = isBackNavigation;

  const searchParams = queryParams;
  const [urlParamMoodId, setUrlParamMoodId] = useState(searchParams[DEEP_LINK_PARAM_MOOD_ID]);
  const [urlParamZenseId, setUrlParamZenseId] = useState(searchParams[DEEP_LINK_PARAM_ZENSE_ID]);
  const [urlParamSubZenseId, setUrlParamSubZenseId] = useState(searchParams[DEEP_LINK_PARAM_SUB_ZENSE_ID]);
  const [urlParamExperienceId, setUrlParamExperienceId] = useState(searchParams[DEEP_LINK_PARAM_EXPERIENCE_ID]);
  const [urlParamAudioDurationSecs, setUrlParamAudioDurationSecs] = useState(searchParams[DEEP_LINK_PARAM_AUDIO_DURATION_SECS]);
  const [urlParamAutoPlay, setUrlParamAutoPlay] = useState(searchParams[DEEP_LINK_PARAM_AUTO_PLAY]);
  const [urlParamAuthToken, setUrlParamAuthToken] = useState(searchParams[DEEP_LINK_PARAM_AUTH_TOKEN]);
  const [urlParamSource, setUrlParamSource] = useState(searchParams[DEEP_LINK_PARAM_SOURCE]);

  // Dynamically override the og:url header value based on the config
  useEffect(() => {
    const config = getConfiguration();
    const url = config.authorisedDomains && config.authorisedDomains.length > 0
      ? config.authorisedDomains[0]
      : "https://zensory.web.app";
    const selctor = document.querySelector('meta[property="og:url"]');
    if (selctor) {
      selctor.setAttribute("content", url);
    }
  });

  // Handle first check on if user is logged in
  useEffect(() => {
    async function hasUser() {
      const hasUser = await ZensoryModule.getAuth().hasUser(true).then(res => {
        return res;
      });
      setIsLoggedIn(hasUser);
      LOGGER.log(LogLevel.DEBUG, `hasUser=${hasUser}`);
    }
    hasUser();
  }, []);
  
  // Handles native browser back button clicks when in ExperienceDeepLinkOnly mode
  useEffect(() => {
    handleBackNavigation(setIsBackNavigation, window, backNavRef);
  },[setIsBackNavigation]);

  // Get users active licesnes
  // if array of active licneses is 0
  // we redirect to subscribe page
  useEffect(() => {
    if (isLoggedIn === true && !isNullOrEmpty(user) && isUseLicenses()) {
      try {
        checkUserLicenses(user, []);
      } catch (error) {
        setLicenseError(error.message);
        setShowLicencesErrorPop(true);
      }

    } else {
      // clear the error handler
      ZensoryModule.getErrorHandler().destroy();
    }

  }, [isLoggedIn, user]);

  // Handle Firebase authentication, app open user activity tracking
  // and adding user device details to db when
  // we get set to logged in, or clear when logged out
  useEffect(() => {
    if (isLoggedIn === true && !isNullOrEmpty(user)) {
      // track the user in the error handler
      ZensoryModule.getErrorHandler().init(user);

      // Ensure we identify the user using their User ID
      ZensoryModule.getAnalytics().identify(user.userId);
      ZensoryModule.getAnalytics().updateUserProps({
        isWeb: true
      });

      async function trackUserActivity() {
        await ZensoryModule.getAnalytics().trackUserActivity(
          user.userId,
          {
            type: UserActivityType.AppOpen,
            timestampMillis: new Date().getTime(),
          }
        );
      }

      async function addUserDevice() {
        await ZensoryModule.getData().addUserDevice();
      }
      
      addUserDevice();
      trackUserActivity();

    } else {
      // clear the error handler
      ZensoryModule.getErrorHandler().destroy();
    }
  }, [isLoggedIn, user]);

  // Handle Firebase authentication with auth token and then URL params

  useEffect(() => {
    async function handleUrlParams() {
      // if we have an auth token, attempt the log in
      var isSignedIn = false;
      if (urlParamAuthToken) {
        const hasUser = await ZensoryModule.getAuth().getCurrentUserWithObserver();
        if (hasUser) {
          // TODO: should we ask user if they want to proceed with currently authed user?
        } else {
          isSignedIn = await ZensoryModule.getAuth().signInWithCustomToken(urlParamAuthToken);
        }
        setUrlParamAuthToken(null); // clear the token after use
      } else {
        isSignedIn = isLoggedIn === true;
      }
  
      // if we are signed in, then handle the URL params
      if (isSignedIn) {
        handleNavigationfromParams(
          urlParamMoodId,
          urlParamZenseId,
          urlParamSubZenseId,
          urlParamExperienceId,
          urlParamAudioDurationSecs,
          urlParamAutoPlay,
          urlParamSource
        );

        // clear the values after being used
        setUrlParamMoodId(null);
        setUrlParamZenseId(null);
        setUrlParamSubZenseId(null);
        setUrlParamExperienceId(null);
        setUrlParamAudioDurationSecs(null);
        setUrlParamAutoPlay(null);
        setUrlParamSource(null);
      }
    };
    if (!isSigningUp) {
      handleUrlParams();
    }

  }, [urlParamSubZenseId, urlParamAuthToken, urlParamMoodId, urlParamZenseId, urlParamExperienceId, urlParamAudioDurationSecs, urlParamAutoPlay, urlParamSource, isLoggedIn, user, isSigningUp]);

  // Listen to the user entering/exiting full screen from the browser native controls
  // here in case the user changes it outside of an Experience (where we expose the toggle button)
  useEffect(() => {
    listenToFullScreenEnter((isFullScreen) => {
      setIsFullScreen(isFullScreen);
    });
  }, []);

  // set all the states into the global app state
  setGlobalAppState({
    setStart: setStart,
    setRestart: setRestart,
    setChooseZense: setChooseZense,
    setChooseNaturescape: setChooseNaturescape,
    setChooseBreathwork: setChooseBreathwork,
    setChooseTouchpad: setChooseTouchpad,
    setExperienceSummary: setExperienceSummary,
    setReview: setReview,
    setBeforeRating: setBeforeRating,
    setAfterRating: setAfterRating,
    setExpired: setExpired,
    setMood: setMood,
    getMood: () => { return mood; },
    setZense: setZense,
    setSubZense: setSubZense,
    setIsDeepLink: setIsDeepLink,
    getIsDeepLink: () => { return isDeepLink; },
    setIsLoggedIn: setIsLoggedIn,
    isSigningUp: () => { return isSigningUp; },
    setIsSigningUp: setIsSigningUp,
    setUser: setUser,
    getUser: () => {return user; },
    setExperience: setExperience,
    setTimeLimit: setTimeLimit,
    setExperienceDuration: setExperienceDuration,
    setExperienceAnalyticsHolder: setExperienceAnalyticsHolder,
    getExperienceAnalyticsHolder: () => { return getExperienceAnalyticsHolder; },
  });

  const debug = isDebug();
  const config = getConfiguration();
  const deploymentType = config.deploymentType;

  // For Scorm, return the state-based system that is wrapped in the <ScormProvider> tags
  if (deploymentType === DeploymentType.Scorm) {
    return (
      <ScormProvider debug={debug}>
        <Layout mood={mood}>
          <div className="container">
            {
              start
                ? <Experience
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  expired={expired}
                  restart={restart}
                  experienceDuration={experienceDuration}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  setRestart={setRestart}
                  setExpired={setExpired}
                  setExperience={setStart}
                  setExperienceDuration={setExperienceDuration}
                  setReview={setReview}
                  onClose={setReview}
                />
              : chooseZense
                ? <ChooseZense
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  onChooseZense={setChooseZense}
                  onChooseNaturescape={setChooseNaturescape}
                  onChooseBreathwork={setChooseBreathwork}
                  onChooseTouchpad={setChooseTouchpad}
                  setSubZense={setSubZense}
                  setExperience={setExperience}
                  setZense={setZense}
                  setMood={setMood}
                />
              : chooseNaturescape
                ? <ChooseNaturescape
                  onChooseNaturescape={setChooseNaturescape}
                  onexperienceSummary={setExperienceSummary}
                  experience={experience}
                  timeLimit={timeLimit}
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  setSubZense={setSubZense}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
              : chooseBreathwork
                ? <ChooseBreathwork
                  onChooseBreathwork={setChooseBreathwork}
                  onexperienceSummary={setExperienceSummary}
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  setSubZense={setSubZense}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
              : chooseTouchpad
                ? <ChooseTouchpad
                  onChooseTouchpad={setChooseTouchpad}
                  onexperienceSummary={setExperienceSummary}
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  setSubZense={setSubZense}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
              : experienceSummary
                ? <ExperienceSummary
                  start={start}
                  onStart={setStart}
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  timeLimit={timeLimit}
                  experience={experience}
                  beforeRating={beforeRating}
                  setBeforeRating={setBeforeRating}
                  onexperienceSummary={setExperienceSummary}
                  onClose={zense === SenseId.Breath ? setChooseBreathwork : zense === SenseId.Touch ? setChooseTouchpad : setChooseNaturescape}
                />
              : review || expired
                ? <Review
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  beforeRating={beforeRating}
                  afterRating={afterRating}
                  experienceDuration={experienceDuration}
                  setMood={setMood}
                  setExperience={setExperience}
                  setZense={setZense}
                  setTimeLimit={setTimeLimit}
                  setExpired={setExpired}
                  setRestart={setRestart}
                  setBeforeRating={setBeforeRating}
                  setAfterRating={setAfterRating}
                  setAfterExperience={setAfterExperience}
                  onReview={setReview}
                />
              : afterExperience
                ? <AfterExperience
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  beforeRating={beforeRating}
                  afterRating={afterRating}
                  experienceDuration={experienceDuration}
                  setMood={setMood}
                  setExperience={setExperience}
                  setZense={setZense}
                  setTimeLimit={setTimeLimit}
                  setExpired={setExpired}
                  setRestart={setRestart}
                  setBeforeRating={setBeforeRating}
                  setAfterRating={setAfterRating}
                  onReview={setReview}
                />
              : <Welcome
                onMood={setMood}
                onChooseZense={setChooseZense}
              />
            }
          </div>
        </Layout>
      </ScormProvider>
    );

    // else for Web, return Route and state based without the <ScormProvider> tags
  } else if (deploymentType === DeploymentType.Web) {
    return (
      <Layout mood={mood}>
        <div className="container">
          {showLicenseErrorPopup ? (
            <AuthErrorPopup
              setShowAuthErrorPopup={setShowLicencesErrorPop}
              errorMessage={licenceError}
            />
          ) : null}
          <Routes>
            <Route path={`/${RouteId.Experience}`} element={
              start
                ? <Experience
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  expired={expired}
                  restart={restart}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  setRestart={setRestart}
                  setExpired={setExpired}
                  setExperience={setStart}
                  experienceDuration={experienceDuration}
                  setExperienceDuration={setExperienceDuration}
                  setReview={setReview}
                  onClose={setReview}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.ChooseZense}`} element={
              chooseZense || mood !== ""
                ?
                <ChooseZense
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  onChooseZense={setChooseZense}
                  onChooseNaturescape={setChooseNaturescape}
                  onChooseBreathwork={setChooseBreathwork}
                  onChooseTouchpad={setChooseTouchpad}
                  setZense={setZense}
                  setExperience={setExperience}
                  setMood={setMood}
                  setSubZense={setSubZense}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.ChooseNaturescape}`} element={
              chooseNaturescape || zense !== ""
                ? <ChooseNaturescape
                  onChooseNaturescape={setChooseNaturescape}
                  onexperienceSummary={setExperienceSummary}
                  experience={experience}
                  timeLimit={timeLimit}
                  mood={mood}
                  subZense={subZense}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.ChooseBreathwork}`} element={
              chooseBreathwork || zense !== ""
                ? <ChooseBreathwork
                  onChooseBreathwork={setChooseBreathwork}
                  onexperienceSummary={setExperienceSummary}
                  mood={mood}
                  subZense={subZense}
                  setSubZense={setSubZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.ChooseTouchpad}`} element={
              chooseTouchpad || zense !== ""
                ? <ChooseTouchpad
                  onChooseTouchpad={setChooseTouchpad}
                  onexperienceSummary={setExperienceSummary}
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  setSubZense={setSubZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  setExperience={setExperience}
                  setTimeLimit={setTimeLimit}
                  onClose={setChooseZense}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.ExperienceSummary}`} element={
              <ExperienceSummary
                start={start}
                onStart={setStart}
                mood={mood}
                zense={zense}
                subZense={subZense}
                timeLimit={timeLimit}
                experience={experience}
                beforeRating={beforeRating}
                setBeforeRating={setBeforeRating}
                onexperienceSummary={setExperienceSummary}
                onClose={zense === SenseId.Breath ? setChooseBreathwork : SenseId.Touch ? setChooseTouchpad : setChooseNaturescape}
              />
            }
            />
            <Route path={`/${RouteId.Review}`} element={
              review || expired
                ? <Review
                  mood={mood}
                  experience={experience}
                  zense={zense}
                  subZense={subZense}
                  timeLimit={timeLimit}
                  beforeRating={beforeRating}
                  afterRating={afterRating}
                  experienceDuration={experienceDuration}
                  setMood={setMood}
                  setSubZense={setSubZense}
                  setExperience={setExperience}
                  setZense={setZense}
                  setTimeLimit={setTimeLimit}
                  setExpired={setExpired}
                  setRestart={setRestart}
                  setBeforeRating={setBeforeRating}
                  setAfterRating={setAfterRating}
                  setAfterExperience={setAfterExperience}
                  onReview={setReview}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.AfterExperience}`} element={
              afterExperience
                ? <AfterExperience
                  mood={mood}
                  zense={zense}
                  subZense={subZense}
                  experience={experience}
                  timeLimit={timeLimit}
                  beforeRating={beforeRating}
                  afterRating={afterRating}
                  experienceDuration={experienceDuration}
                  setMood={setMood}
                  setExperience={setExperience}
                  setZense={setZense}
                  setTimeLimit={setTimeLimit}
                  setExpired={setExpired}
                  setRestart={setRestart}
                  setBeforeRating={setBeforeRating}
                  setAfterRating={setAfterRating}
                  onReview={setReview}
                />
                : <Navigate to="/" />
            } />
            <Route path={`/${RouteId.SignUp}`} element={
              <SignUp
            />
            } />
            <Route path={`/${RouteId.ForgotPassword}`} element={
              <ForgotPassword
            />
            } />
            <Route path={`/${RouteId.Login}`} element={
              <Login
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
            />
            } />
            <Route path={`/${RouteId.Profile}`} element={
              isLoggedIn === true
              ? <Profile
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
            <Route path="/" element={
              isLoggedIn === true
              ? <Welcome
                onMood={setMood}
                onChooseZense={setChooseZense}
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
             <Route path={RouteId.Subscribe} element={
              isLoggedIn === true
              ? <Subscribe
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
              <Route path={RouteId.ManageSubscription} element={
              isLoggedIn === true
              ? <ManageSubcription
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
            <Route path={"*" || RouteId.NotAuthorized} element={
              <PageNotFound />
            } />
          </Routes>
        </div>
      </Layout>
    )
  }
};

export default App;
