/**
 * This is file that has all of the generic functions for performing all of the CRUD functions i.e, POST, GET, PATCH, DELETE etc.
 * If you need to perform any of the above action you can call any of the following function and pass in following parameters:
 * @param {string} url
 * @param {string} link
 * @param {string} params
 * @param {string} accessToken
 * @returns
 */

export const apiGet = async (url, link) => {
    const response = await fetch(`${url}${link}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response;
  };
  
  export const apiPost = async (url, link, params) => {
    const response = fetch(`${url}${link}`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: params,
      }),
    })
    return await response;
    ;
  };
  
  export const apiPatch = async (url, link, body) => {
    const response = await fetch(`${url}${link}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return await response;
  };
  
  export const apiGetToken = async (url, link, accessToken) => {
    const response = await fetch(`${url}${link}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    });
    return await response;
  };
  
  export const apiPostToken = async (url, link, body, accessToken) => {
    const response = await fetch(`${url}${link}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body,
    });
    return await response;
  };
  
  export const apiPatchToken = async (url, link, body, accessToken) => {
    const response = await fetch(`${url}${link}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body,
    });
    return await response;
  };
  