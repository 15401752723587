import React from "react";
import moment from "moment"; // Import moment library
import ratingIcon from "../assets/icons/ratingIcon.png";
import { SenseId } from "./Data/constants";

// Function to get experience tile
const ExperienceTile = ({ item }) => {

  //do not show if item is null
  if(!item)
  return null;

  //select content title
  const title = item?.experience?.data?.selectedHomeCard?.contentTitle;

  //select background image
  const backgroundImageUrl =
    item?.experience?.soundscape !== SenseId.NoNature &&
    item?.experience?.data?.senseData?.senseId !== SenseId.Breath
      ? item.experience.soundscape.backgroundImageUrl
      : item.experience.data.senseData &&
        item.experience.data.senseData.senseId === SenseId.Breath
      ? item.experience.data.zenseData.breathingBackgroundImg
      : item?.experience?.data?.HomeCardBackground
      ? item.experience.data.HomeCardBackground
      : null;

  //select duration
  const durations = item?.didLoop
    ? "Unlimited"
    : item?.experience?.binaurals?.lengthSecs / 60 + " min";

  //select time, sense title, sense icon and rating change
  const time = moment(item?.endedAt).format("HH:mm");
  const senseTitle = item?.experience?.data?.zenseData?.senseId;
  const senseIconurl = item?.experience?.data?.senseData?.iconUrl;
  const ratingChange = item.userRatingChange;

  // do not show tile if title or background image is missing
  if (!title || !backgroundImageUrl) return null;

  return (
    <div
      className="expTileMain"
      style={{
        height: "150px",
        //applying inline style for variable background image. gradient is for dark tint over image
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImageUrl})`,
        borderRadius: 16,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Title + subtitle view */}
      <div className="titleandsubtitle">
        {/* Title */}
        <div className="expTitle">{title}</div>
        {/* Time and duration text view */}
        <div className="timeanddurationtext">
          {/* Time completed text */}
          <div>{time}</div>
          {/* Duration text */}
          <div>• {durations}</div>
          <div>• {moment(item.startedAt).format("DD MM YYYY")}</div>
        </div>
      </div>
      {/* Bottom icon view */}
      <div className="bottomExp">
        {/* First column */}
        <div className="rowGapCon">
          <img
            src={require(`../assets/icons/ic_mood_${item.experienceId}.png`)}
            alt=""
            style={{
              width: 24,
              height: 24,
              marginRight: 4,
            }}
          />
          <h3>{item.title}</h3>
        </div>
        {/* Vertical divider */}
        <div className="verticalDivider"></div>
        {/* Second column */}
        <div className="rowGapCon">
          <img
            src={senseIconurl}
            alt=""
            style={{
              width: 28,
              height: 28,
            }}
          />

          <h3 style={{ textTransform: "capitalize" }}> {senseTitle}</h3>
        </div>
        {/* Vertical divider */}
        <div className="verticalDivider"></div>
        {/* Third column */}
        <div className="rowGapCon">
          {ratingChange > 0 ? <img src={ratingIcon} alt="rating" /> : null}

          <h3>{ratingChange} Mood</h3>
        </div>
      </div>
    </div>
  );
};

export default ExperienceTile;
