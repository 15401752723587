import React, { useEffect } from "react";
import "../styles/greenFluid.css"

const GreenFluid = (props) => {
  const url = `greenFluid.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('start');
    startDiv.click();
    const stopDiv = document.getElementById('stop');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);

  return (
    <>
    <div id="stop"></div>
    <div id="start"></div>
    <canvas id= "c"> </canvas>
    </>
  );
}

export default GreenFluid;