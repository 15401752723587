import "../styles/carousel.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useState, useEffect, useCallback } from "react";
import back from "../assets/icons/back-arrow.svg"
import forward from "../assets/icons/forward-arrow.svg"
import { ZensoryModule } from '../ZensoryModule';
import { getSoundscapeTitle } from "./Data/constants";
import { isKey, isSubmitKey, preventDefault, stopPropagation } from "./UI/KeyPressHelper";
import { KeyId } from "./UI/KeyEnum";

const Next = (props) => {
  return (
    <div className="arrow-container" 
        id="btn-carousel-next"
        onClick={props.onClick}
        tabIndex="0"
        onKeyDown={props.onKeyPress}
        ref={(view) => { props.setNextArrow(view); }} >
      <img 
        className='slider-next' 
        src={forward}
        alt='Select the next Sound button'
      />
    </div>
  )
}

const Back = (props) => {
  return (
    <div className="arrow-container" 
        id="btn-carousel-back"
        onClick={props.onClick} 
        tabIndex="0"
        onKeyDown={props.onKeyPress}
        ref={(view) => { props.setBackArrow(view); }} >
      <img 
        className='slider-back' 
        src={back}
        alt='Select the previous Sound button'
      />
    </div>
  )
}

const getSoundscapes = (moodId) => {
  return ZensoryModule.getData().getSoundscapes(moodId);
}

const Carousel = (props) => {

  const currentExperienceIndex = getSoundscapes(props.mood).findIndex(x => x.soundscapeId === props.experience);
  const [activeIndex, setActiveIndex] = useState(0);

  const [btnBackArrow, setBackArrow] = useState(null);
  const [btnNextArrow, setNextArrow] = useState(null);

  // set focus to the back naturescape button when the view opens
  useEffect(() => {
    if (btnBackArrow != null) {
      btnBackArrow.focus();
    }
  }, [btnBackArrow]);

  useEffect(() => {
    const soundscape = getSoundscapes(props.mood)[activeIndex].soundscapeId;
    props.setExperience(soundscape);
   
  }, [activeIndex])

  const [swiperRef, setSwiperRef] = useState();

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  // handle return key presses on the right arrow button
  const _handleKeyDownSelectedNaturescape = (e, soundscapeId) => {
    stopPropagation(e);
    var handled = false;
    if (isKey(e, KeyId.ArrowLeft)) {
      handleLeftClick();
      handled = true;
    } else if (isKey(e, KeyId.ArrowRight)) {
      handleRightClick();
      handled = true;
    } else if (e.shiftKey && isKey(e, KeyId.Tab)) {
      handled = true;
      btnBackArrow.focus();
    } else if (isKey(e, KeyId.Tab)) {
      handled = true;
      btnNextArrow.focus();
    } 

    if (handled) {
      preventDefault(e);
      document.getElementById(soundscapeId).tabIndex = "-1";
    }
  }
  
  // handle return key presses on the right arrow button
  const _handleKeyDownLeftArrow = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e) || isKey(e, KeyId.ArrowLeft)) {
      handleLeftClick();
      preventDefault(e);
    } else if (isKey(e, KeyId.ArrowRight)) {
      handleRightClick();
      preventDefault(e);
    }
  }

  // handle return key presses on the right arrow button
  const _handleKeyDownRightArrow = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e) || isKey(e, KeyId.ArrowRight)) {
      handleRightClick();
      preventDefault(e);
    } else if (isKey(e, KeyId.ArrowLeft)) {
      handleLeftClick();
      preventDefault(e);
    } else if (e.shiftKey && isKey(e, KeyId.Tab)) {
      preventDefault(e);
      document.getElementById(props.experience).tabIndex = "0";
      document.getElementById(props.experience).focus();
    }
  }

  return (
    <div className="slider-cont">
      <Back 
        onClick={(e) => {
          preventDefault(e);
          handleLeftClick();
        }}
        onKeyPress={_handleKeyDownLeftArrow}
        setBackArrow={setBackArrow}
      />
      <Swiper className="swiper-container"
          onSwiper={setSwiperRef}
          spaceBetween={10}
          slidesPerView={1}
          centeredSlides= {true}
          initialSlide= {currentExperienceIndex}
          roundLengths= {true}
          loopedSlides= {30}
          slideToClickedSlide ={true}
          loop= {true}
          navigation={false}
          modules={[Navigation, Pagination]}
          onRealIndexChange={(element) => setActiveIndex(element.realIndex)}
          breakpoints={{
          768: {
              slidesPerView: 3,
              spaceBetween:10
            },
          1024: {
              slidesPerView: 4,
              spaceBetween:10
          },
          1300: {
            slidesPerView: 5,
            spaceBetween:10
          }
          }}
      >
          {
            getSoundscapes(props.mood).map((soundscape, index) =>
              <SwiperSlide key={index} className="swiper-slide">
                <div class="round-item">
                  <img
                    id={soundscape.soundscapeId}
                    src={ZensoryModule.getImageLoader().getNaturescapeSelectImage(soundscape.soundscapeId)}
                    loading="lazy"
                    alt={getSoundscapeTitle(soundscape.soundscapeId)}
                    tabIndex={activeIndex === index ? "0" : "-1"}
                    onKeyDown={(e) => _handleKeyDownSelectedNaturescape(e, soundscape.soundscapeId)}
                  />
                </div>
                <div className="naturescape-name">
                  {
                    activeIndex === index 
                      ? getSoundscapeTitle(soundscape.soundscapeId)
                      : ""
                  }
                </div>
              </SwiperSlide>
            )}
      </Swiper >
      <Next 
        onClick={(e) => {
          preventDefault(e);
          handleRightClick();
        }}
        onKeyPress={_handleKeyDownRightArrow}
        setNextArrow={setNextArrow}
      />
    </div>
  )
}
export default Carousel;