import React, { useEffect, useState } from "react";
import "../../styles/choose-experience.css";
import arrow_back from "../../assets/icons/arrow_back.svg";
import logo from "../../assets/icons/zensory_logo.svg";
import cross from "../../assets/icons/close.svg"
import info from "../../assets/icons/info.svg"
import rotate from "../../assets/icons/rotate.svg"
import { withScorm } from 'react-scorm-provider';
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getAudioDurationTitle, getMoodIdTitle, MoodId, SubSenseId } from "../Data/constants";
import { getText, TextId } from "../Util/TextHelper";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { Interweave } from "interweave";
import { getBreathwork } from "../Loaders/BreathworkLoader";
import { isNullOrEmpty } from "../Data/Util/util";
import { LOGGER, LogLevel } from "../Util/Logger";
import CloseAndExitPopup from "../Close&ExitPopup";
import { isMacOS } from "../Util/DeviceUtil";
import { GlobalAppState } from "../AppState";

const ChooseBreathworkPage = (props) => {

  ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenCustomise);

  const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
  const isShowBackButton = ZensoryModule.getFeatureUi().isShowBackButton();
  const visualGuidedInfo = getBreathwork(props.mood, SubSenseId.VisualGuidedBreath);
  const audioGuidedInfo = getBreathwork(props.mood, SubSenseId.AudioGuidedBreath);
  const [lastVisualDuration, setLastVisualDuration] = useState(visualGuidedInfo.duration[0]);
  const [lastAudioDuration, setLastAudioDuration] = useState(audioGuidedInfo.duration[0]);
  const [visualGuidedCardFlip, setVisualGuidedCardFlip] = useState(false);
  const [audioGuidedCardFlip, setAudioGuidedCardFlip] = useState(false);
  const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);

  const getDefaultBreathCard = () => {
    // the default could have been set from a deep link
    if (!isNullOrEmpty(props.subZense)) {
      return props.subZense;
    }

    // set the default to card (prefer Visual)
    if (visualGuidedInfo.isShow) {
      return visualGuidedInfo.subZenseId;
    } else if (audioGuidedInfo.isShow) {
      return audioGuidedInfo.subZenseId;
    }

    return null;
  }

  const [activeTimeLength, setActiveTimeLength] = useState(props.timeLimit);
  const [activeBreathCard, setActiveBreathCard] = useState(getDefaultBreathCard());

  useEffect(() => {
    if (document.getElementById("choose-breathwork-container")) {
      document.getElementById("choose-breathwork-container").focus();
    };

    if (!Object.values(SubSenseId).includes(props.experience) && visualGuidedInfo.isShow) {
      setActiveTimeLength(lastVisualDuration);
    }
    if (props.subZense === SubSenseId.AudioGuidedBreath || (!visualGuidedInfo.isShow && audioGuidedInfo.isShow)) {
      setActiveTimeLength(lastAudioDuration);
      props.setTimeLimit(lastAudioDuration);
    }
  }, []);

  const startExperienceSummary = (e) => {
    // default to visual guided
    if (isNullOrEmpty(props.subZense)) {
      if (visualGuidedInfo.isShow) {
        props.setSubZense(visualGuidedInfo.subZenseId);
      } else if (audioGuidedInfo.isShow) {
        props.setSubZense(audioGuidedInfo.subZenseId);
      }
    }

    preventDefault(e);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.ExperienceSummary, props.onexperienceSummary, props.onChooseBreathwork);
  }

  const back = (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().back(window, props.onClose, props.onChooseBreathwork, false, GlobalAppState.getIsDeepLink());
  }

  const close = (e) => {
    preventDefault(e);
    setShowCloseAndExitPopup(true);
    ZensoryModule.getNavigationHandler().exit(window, props.sco);
  }

  const onFlip = (e, subSenseId) => {
    stopPropagation(e);
    switch (subSenseId) {
      case SubSenseId.VisualGuidedBreath:
        setVisualGuidedCardFlip(!visualGuidedCardFlip);
        break;
      case SubSenseId.AudioGuidedBreath:
        setAudioGuidedCardFlip(!audioGuidedCardFlip);
        break;
      default:
        throw new Error(`Invalid subSenseId=${subSenseId}`);
    }
  }

  const onClickTimeLengthButton = (e, durationSecs) => {
    preventDefault(e);
    stopPropagation(e);
    props.setTimeLimit(durationSecs);
    setActiveTimeLength(durationSecs);
    if (isNullOrEmpty(activeBreathCard) || activeBreathCard === visualGuidedInfo.subZenseId) {
      setLastVisualDuration(durationSecs);
    }
  }

  const onClickBreathCard = (e, subZenseId) => {
    preventDefault(e);
    stopPropagation(e);
    LOGGER.log(LogLevel.DEBUG, `onClickBreathCard() subZenseId=${subZenseId}`);
    props.setSubZense(subZenseId);
    setActiveBreathCard(subZenseId);

    var audioTimeSecs;

    // auto update the selected audio duration
    if (subZenseId === audioGuidedInfo.subZenseId && audioGuidedInfo.duration.length === 1) {
      audioTimeSecs = audioGuidedInfo.duration[0];
    } else if (subZenseId === visualGuidedInfo.subZenseId) {
      audioTimeSecs = lastVisualDuration;
    } else {
      throw new Error(`Invalid subZenseId=${subZenseId}`);
    }

    props.setTimeLimit(audioTimeSecs);
    setActiveTimeLength(audioTimeSecs);
  }

  // handle return key presses on the back button
  const _handleKeyDownBack = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      back(e);
    }
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  }

  // handle return key presses on the breath cards
  const _handleKeyDownBreathCard = (e, subZenseId) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      onClickBreathCard(e, subZenseId);
    }
  }

  const _handleKeyDownTileInfo = (e, experience) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      onFlip(e, experience);
    }
  }

  const getExperienceDurationMap = () => {
    LOGGER.log(LogLevel.DEBUG, `getExperienceDurationMap() activeBreathCard=${activeBreathCard}`);
    return activeBreathCard === audioGuidedInfo.subZenseId
      ? audioGuidedInfo.duration
      : visualGuidedInfo.duration;
  }

  return (
    <>
      {showCloseAndExitPopup
        ? <CloseAndExitPopup setShowCloseAndExitPopup={setShowCloseAndExitPopup} />
        : null
      }
      <div style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <div className="header-row-choose">
          { isShowBackButton
            ? <div className="back"
              id="btn-choose-breathwork-back"
              onClick={(e) => back(e)}
              tabIndex="0"
              onKeyDown={(e) => _handleKeyDownBack(e)}
              role="button"
              alt={getText(
                TextId.backButtonAlt,
                { GENERIC: "to Choose a Zense" })}
              aria-label={getText(
                TextId.backButtonAlt,
                { GENERIC: "to Choose a Zense" })}>
              <img
                src={arrow_back}
                style={{ marginTop: "2px" }}
                alt="Back to Choose a Zense"
              />
              <span>
                {GlobalAppState.getIsDeepLink()
                  ? "Home"
                  : "Zenses"
                }
              </span>
            </div>
            : <div className="placeholder"></div>}
          { isShowExitButton
            ? <div className="close"
              id="btn-choose-breathwork-close"
              onClick={(e) => close(e)}
              tabIndex="0"
              onKeyDown={(e) => _handleKeyDownCloseExit(e)}
              alt={getText(TextId.mainCloseAndExitAriaLabel)}
              aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
              role="button"
            >
              <img
                src={cross}
                style={{ marginTop: "2px" }}
                alt="Close icon"
              />
              <span>{getText(TextId.mainCloseAndExitText)}</span>
            </div>
            : null
          }
          <div className="logo-container">
            <img
              className="logo"
              src={logo}
              alt="Zensory logo"
            />
          </div>
        </div>
        <div
          className="choose-experience-container"
          tabIndex="-1"
          id="choose-breathwork-container">

          <h1 className="major-heading-experience">
            <Interweave
              content={getText(TextId.customizeExperienceBreathTitle)}
            />
          </h1>
          <div className="choose-breathwork-subtitle">
            <Interweave
              content={getText(TextId.customizeExperienceBreathSubTitle,
                { MOOD: getMoodIdTitle(props.mood) })}
            />
          </div>
          <div className="breath-card-container">
            {visualGuidedInfo.isShow
              ? <div
                id={visualGuidedInfo.subZenseId}
                className={
                  activeBreathCard === visualGuidedInfo.subZenseId
                    ? `breath-card-active ${visualGuidedCardFlip ? 'active' : ''}`
                    : `breath-card ${visualGuidedCardFlip ? 'active' : ''}`}
                onClick={(e) => onClickBreathCard(e, visualGuidedInfo.subZenseId)}
                tabIndex="0"
                onKeyDown={(e) => _handleKeyDownBreathCard(e, visualGuidedInfo.subZenseId)}
                alt={visualGuidedInfo.subZenseId + " selection tile"}
                aria-label={visualGuidedInfo.subZenseId + " selection tile"}
              >
                <img
                  id={`${visualGuidedInfo.subZenseId}-info`}
                  src={visualGuidedCardFlip ? rotate : info}
                  className="info-icon-breath"
                  alt={getText(visualGuidedCardFlip
                    ? TextId.hideInformationAlt
                    : TextId.showInformationAlt,
                    { GENERIC: visualGuidedInfo.subZenseId })
                  }
                  onClick={(e) => onFlip(e, visualGuidedInfo.subZenseId)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownTileInfo(e, visualGuidedInfo.subZenseId)} />
                <div className="breath-card-inner">
                  <div className="breath-card-front"
                    style={{
                      backgroundImage: `url(${visualGuidedInfo.src})`,
                    }}
                    role={isMacOS() ? "textbox" : null}
                    tabIndex={visualGuidedCardFlip ? "-1" : "0"}
                  >
                    <div className="hidden-text">
                      {visualGuidedInfo.subZenseId}
                    </div>
                  </div>
                  <div
                    className="breath-card-back"
                    role={isMacOS() ? "textbox" : null}
                    tabIndex={visualGuidedCardFlip ? "0" : "-1"}
                  >
                    <div className="breath-card-back-blur"
                      style={{
                        backgroundImage: `url(${visualGuidedInfo.src})`,
                      }}
                    >
                    </div>
                    <Interweave className="breath-card-back-body"
                      content={
                        getText(props.mood === MoodId.Focused
                          ? TextId.customizeBreathExperienceFocusVisualGuided
                          : TextId.customizeBreathExperienceRelaxVisualGuided)
                      }
                    />
                  </div>
                </div>
              </div>
              : null
            }
            {audioGuidedInfo.isShow
              ? <div
                id={audioGuidedInfo.subZenseId}
                className={
                  activeBreathCard === audioGuidedInfo.subZenseId
                    ? `breath-card-active ${audioGuidedCardFlip ? 'active' : ''}`
                    : `breath-card ${audioGuidedCardFlip ? 'active' : ''}`}
                onClick={(e) => onClickBreathCard(e, audioGuidedInfo.subZenseId)}
                tabIndex="0"
                onKeyDown={(e) => _handleKeyDownBreathCard(e, audioGuidedInfo.subZenseId)}
                alt={audioGuidedInfo.subZenseId + " selection tile"}
                aria-label={audioGuidedInfo.subZenseId + " selection tile"}
              >
                <img
                  id={`${audioGuidedInfo.subZenseId}-info`}
                  src={audioGuidedCardFlip ? rotate : info}
                  className="info-icon-breath"
                  alt={getText(audioGuidedCardFlip
                    ? TextId.hideInformationAlt
                    : TextId.showInformationAlt,
                    { GENERIC: audioGuidedInfo.subZenseId })
                  }
                  onClick={(e) => onFlip(e, audioGuidedInfo.subZenseId)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownTileInfo(e, audioGuidedInfo.subZenseId)} />
                <div className="breath-card-inner">
                  <div className="breath-card-front"
                    style={{
                      backgroundImage: `url(${audioGuidedInfo.src})`,
                    }}
                    role={isMacOS() ? "textbox" : null}
                    tabIndex={audioGuidedCardFlip ? "-1" : "0"}
                  >
                    <div className="hidden-text">
                      {audioGuidedInfo.subZenseId}
                    </div>
                  </div>
                  <div
                    className="breath-card-back"
                    role={isMacOS() ? "textbox" : null}
                    tabIndex={audioGuidedCardFlip ? "0" : "-1"} >
                    <div className="breath-card-back-blur"
                      style={{
                        backgroundImage: `url(${audioGuidedInfo.src})`,
                      }}
                    ></div>
                    <Interweave className="breath-card-back-body"
                      content={
                        getText(props.mood === MoodId.Focused
                          ? TextId.customizeBreathExperienceFocusAudioGuided
                          : TextId.customizeBreathExperienceRelaxAudioGuided)
                      }
                    />
                  </div>
                </div>
              </div>
              : null
            }
          </div>
          <div className="time-box">
            <div className="choose-time-heading">
              <Interweave
                content={getText(TextId.customizeExperienceTimeLengthText)}
              />
            </div>
            <div className="time-row">
              {getExperienceDurationMap().map((durationSecs, index) => {
                return (
                  <button
                    // if there is only 1 time, then always show it as active
                    key={index}
                    className={activeTimeLength === durationSecs
                      ? "time-btn-click"
                      : "time-btn"}
                    id={durationSecs}
                    onClick={(e) => onClickTimeLengthButton(e, durationSecs)}>
                    {getAudioDurationTitle(durationSecs)}
                  </button>
                )
              })
              }
            </div>
          </div>
          <div className="experience-btn-container">
            <button
              id="btn-choose-breathwork-next"
              className="next-button"
              onClick={(e) => startExperienceSummary(e)}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ChooseBreathwork = withScorm()(ChooseBreathworkPage);

export default ChooseBreathwork;
