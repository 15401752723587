import cross from "../assets/icons/close.svg"
import info from "../assets/icons/info.svg"
import { isSubmitKey, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import {  useRef } from "react";

const AuthSuccessPopup = (props) => {

    const modalRef = useRef();

    const closePopup = (e) =>{
        preventDefault(e);
        props.setShowAuthErrorPopup(false);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            closePopup(e);
        }
    }

    return (
        <div className="popup-container-close" ref={modalRef}>
            <div className='popup-header'>
            <img 
                src={info} 
                className="popup-info-icon" 
                tabIndex="0"
                alt="More information button" />
            <img 
                id="btn-auth-error-popup-close"
                src={cross} 
                className="popup-close-icon" 
                onClick={(e)=>closePopup(e)} 
                alt="Close popup"
                tabIndex="0" 
                onKeyDown={(e) => _handleKeyDownClose(e)}
            />
            </div>
            <div className='popup-instruction-close'>
              {props.successMessage}
            </div>
            <button
                id="btn-auth-error-popup-okay"
                tabIndex="0"
                className="okay-button"
                onClick={(e) => closePopup(e)}>
                Okay
            </button>
        </div>
    );
};

export default AuthSuccessPopup;