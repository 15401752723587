import { CustomerId } from "../../Data/Models/configuration";
import { getConfiguration } from "../../Util/ConfigHelper";
import { BaseFeatureUi } from "../Base/BaseFeatureUi";

export class ScormFeatureUi extends BaseFeatureUi {

    isShowExitButton(): boolean {
        // only show the Exit button for KnowBe4
        const config = getConfiguration();
        return config.customerId === CustomerId.KnowBe4;
    }
}