import { AnalyticEventName, AnalyticsApi, AnalyticsScreenName, AnalyticsSummary, EndExperienceAnalyticsEvent, EndExperienceWithRatingAnalyticsEvent, getDefaultAnalyticsSummary, StartExperienceAnalyticsEvent } from "../../API/AnalyticsApi";
import { Analytics, logEvent, setUserProperties } from "firebase/analytics";
import { getConfiguration } from "../../Util/ConfigHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";
import { getFirebaseHelper } from "../../Util/FirebaseHelper";
import { UserActivity } from "../../Data/Models/app";
import { TrackUserActivityRequest, ZensoryCloudFunctionNames } from "../../Data/Models/zensory_api";

export class FirebaseAnalyticsImpl implements AnalyticsApi {

    async identify(
        userId: string
    ): Promise<void> {
    }

    async updateUserProps(
        props: { [key: string]: any; }
    ): Promise<void> {
        setUserProperties(
            this.getFirebaseAnalytics(),
            props
        );
    }

    async trackEvent(eventName: AnalyticEventName, props: { [key: string]: any; }): Promise<void> {
        logEvent(this.getFirebaseAnalytics(), eventName, props);
    }

    async trackScreen(screenName: AnalyticsScreenName): Promise<void> {
        LOGGER.log(LogLevel.DEBUG, `trackingscreen=${screenName}`);
        logEvent(this.getFirebaseAnalytics(), 'screen_view', {
            firebase_screen: screenName, 
            firebase_screen_class: screenName
        });
    }

    async trackStartExperience(event: StartExperienceAnalyticsEvent): Promise<void> {
        logEvent(this.getFirebaseAnalytics(), event.name, event.toPlainObj());
    }

    async trackEndExperience(event: EndExperienceAnalyticsEvent): Promise<void> {
        logEvent(this.getFirebaseAnalytics(), event.name, event.toPlainObj());
    }

    async trackEndExperienceWithRating(event: EndExperienceWithRatingAnalyticsEvent): Promise<void> {
        logEvent(this.getFirebaseAnalytics(), event.name, event.toPlainObj());
    }

    async trackUserActivity(userId: string, activity: UserActivity): Promise<any> {
        const payload: TrackUserActivityRequest = {
            userId: userId,
            activity: activity
        };
        try {
            const response = await getFirebaseHelper().callCloudFunction(
                ZensoryCloudFunctionNames.TrackUserActivity,
                payload
            );
            return response;

        } catch (error) {
            LOGGER.log(LogLevel.ERROR, "trackUserActivityFun error " + error);
        }
    }

    getSessionSummaryJson(): AnalyticsSummary {
        const config = getConfiguration();
        return getDefaultAnalyticsSummary(config); 
    }

    getFirebaseAnalytics(): Analytics {
        return getFirebaseHelper().getAnalytics();
    }

}