import { Configuration, ConfigurationOverride, SurveyEndExperienceConfig } from "../Data/Models/configuration";
import configJson from '../../config.json';
import { ConfigurationDefault, EnvId } from "../Data/constants";
import { LOGGER, LogLevel, isLogLevelDebug } from "./Logger";
import { ZensoryModule } from "../../ZensoryModule";
import { replaceAll } from "../Data/Util/util";
import { AnalyticMainEventName, EndExperienceWithRatingAnalyticsEvent, getAnalyticsEvent } from "../API/AnalyticsApi";

var hasLogged = false;

export function getConfiguration(shouldLog = true): Configuration {
    const config: Configuration = {...ConfigurationDefault, ...configJson as ConfigurationOverride};

    // log the configuration if in the DEV env
    if (!hasLogged && shouldLog && config.envId === EnvId.DEV) {
        // have to use console because the configuration is 
        LOGGER.log(LogLevel.DEBUG, `Configuration=${JSON.stringify(config)}`);
        hasLogged = true;
    }
    
    return config;
}

export function isDebug(): boolean {
    const config = getConfiguration(false);
    return config.envId === EnvId.DEV && config.logging.isEnabled && isLogLevelDebug(config.logging.logLevel);
}

// tag for the text to replace in the survey URL
const SURVEY_URL_DATA_KEY = '||DATA||';

/**
 * @param surveyEndExperienceConfig the SurveyEndExperienceConfig to take the survey url from
 * @returns the populate URL with the analytical data in the survey URL
 */
export function getEndExperienceConfigSurvey(surveyEndExperienceConfig: SurveyEndExperienceConfig): string {
    const analyticsSummary = ZensoryModule.getAnalytics().getSessionSummaryJson();
    
    // extract the end experience event and convert the key properties into a string
    const endExperienceEvent: EndExperienceWithRatingAnalyticsEvent = getAnalyticsEvent(AnalyticMainEventName.EndExperienceWithRating, analyticsSummary.events) as EndExperienceWithRatingAnalyticsEvent;
    var data = "";
    data += "goal:" + endExperienceEvent.goal;
    data += ",senseId:" + endExperienceEvent.senseId;
    data += ",experienceSenseId:" + endExperienceEvent.experienceSenseId;
    data += ",durationSecs:" + endExperienceEvent.audioDurationSecs;
    data += ",rating:" + endExperienceEvent.rating;
    data += ",timeSpentPercent:" + endExperienceEvent.timeSpentPercent;
    data += ",soundscape:" + endExperienceEvent.soundscape;
    data += ",breathExperience:" + endExperienceEvent.breathExperience;

    const replacements: {[token: string]: string} = {};
    replacements[`${SURVEY_URL_DATA_KEY}`] = data;
    LOGGER.log(LogLevel.DEBUG, `getEndExperienceConfigSurvey() replacements=${JSON.stringify(replacements)}`);
    
    const surveyUrl = replaceAll(surveyEndExperienceConfig.surveyUrl, replacements);
    LOGGER.log(LogLevel.DEBUG, `getEndExperienceConfigSurvey() before=${surveyEndExperienceConfig.surveyUrl}, surveyUrl=${surveyUrl}`);
    return surveyUrl;
}