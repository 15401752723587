import { AudioDurationSecs, MoodId } from "../constants";

export function getBreathingExericeDurations(moodId: MoodId): AudioDurationSecs[] {
    return [ AudioDurationSecs.THREE_3_MINS, AudioDurationSecs.TEN_10_MINS ];
}

export function getBreathingExericeDurationSecsActual(moodId: MoodId, audioDurationSecs: AudioDurationSecs): number {
    switch (moodId) {
        // @ts-ignore
        // eslint-disable-next-line
        case MoodId.Creative:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                return 185;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                return 610;
            }

        // @ts-ignore
        // eslint-disable-next-line
        case MoodId.Focused:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                return 173;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                return 598;
            }
        
        // @ts-ignore
        // eslint-disable-next-line
        case MoodId.Relax:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                return 215;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                return 610;
            }

        // @ts-ignore
        // eslint-disable-next-line
        case MoodId.Positive:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                return 185;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                return 610;
            }

        // @ts-ignore
        // eslint-disable-next-line
        default:
            throw new Error("Unsupported moodId");
    }
}

export function getBreathingExericeUrl(moodId: MoodId, audioDurationSecs: AudioDurationSecs): string {
    // https://thezensory.web.app/breathing/boxFocus.html?countdownSecs=3&numIterations=1&restIntervalSecs=10

    var countdownSecs: number = 5;
    var numIterations: number = 9999;
    var restIntervalSecs: number = 10;
    switch (moodId) {
        case MoodId.Creative:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                countdownSecs = 5;
                numIterations = 2;
                restIntervalSecs = 10;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                countdownSecs = 10;
                numIterations = 2;
                restIntervalSecs = 20;
            }
            break;
        case MoodId.Focused:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                countdownSecs = 5;
                numIterations = 2;
                restIntervalSecs = 10;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                countdownSecs = 10;
                numIterations = 4;
                restIntervalSecs = 20;
            }
            break;
        case MoodId.Relax:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                countdownSecs = 5;
                numIterations = 3;
                restIntervalSecs = 10;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                countdownSecs = 10;
                numIterations = 4;
                restIntervalSecs = 20;
            }
            break;
        case MoodId.Positive:
            if (audioDurationSecs === AudioDurationSecs.THREE_3_MINS) {
                countdownSecs = 5;
                numIterations = 3;
                restIntervalSecs = 15;
            } else if (audioDurationSecs === AudioDurationSecs.TEN_10_MINS) {
                countdownSecs = 10;
                numIterations = 2;
                restIntervalSecs = 30;
            }
            break;
        default:
            throw new Error("Unsupported moodId");
    }

    var url = "";
    const path = getBreathingExericeUrlPath(moodId);
    if (!url.endsWith('/') && !path.startsWith('/')) {
        url += '/';
    }
    url += path;
    url += `?countdownSecs=${countdownSecs}&numIterations${numIterations}&restIntervalSecs=${restIntervalSecs}`;
    return "";
}

/**
 * @param moodId the MoodId
 * @returns the URL path to the Breathing exercise for the mood
 */
export function getBreathingExericeUrlPath(moodId: MoodId): string {
    switch (moodId) {
        case MoodId.Creative:
            return "breathing/alternateNostrilCreative.html";
        case MoodId.Focused:
            return "breathing/boxFocus.html";
        case MoodId.Relax:
            return "breathing/threePartRelax.html";
        case MoodId.Positive:
            return "breathing/shortInhalePositive.html";
        default:
            throw new Error("Unsupported moodId");
    }
}

/**
 * @param moodId the MoodId
 * @returns the URL to the Breathing background image for the mood
 */
 export function getBreathingBackgroundImg(moodId: MoodId): string {
    switch (moodId) {
        case MoodId.Creative:
            return "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2FbreathBackgrounds%2Fbreathing_background_creative.jpg?alt=media&token=da22e821-a2fd-47a7-92b2-725ce4a27a50";
        case MoodId.Focused:
            return "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2FbreathBackgrounds%2Fbreathing_background_focus.jpg?alt=media&token=9ed83231-6b6a-4f92-a365-5458d0e598a4";
        case MoodId.Relax:
            return "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2FbreathBackgrounds%2Fbreathing_background_relax.jpg?alt=media&token=9f11d91b-b9c2-45d8-8fe7-e39061a31d19";
        case MoodId.Positive:
            return "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2Fsenses%2Fbreath%2FbreathBackgrounds%2Fbreathing_background_positive.jpg?alt=media&token=7afd212d-849e-49c4-a425-0ee787a4961a";
        default:
            throw new Error("Unsupported moodId");
    }
}

