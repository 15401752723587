import { useEffect, useState, useLayoutEffect } from "react";
import playIcon from "../assets/icons/playIcon.png"
import pauseIcon from "../assets/icons/pauseIcon.png"
import Timer from "./Timer";
import { LOGGER, LogLevel } from "./Util/Logger";
import { AudioDurationSecs, hasMusicAudio, hasNaturescapeAudio, isAudioGuidedBreath, isVisualGuidedBreath, SenseId, SoundscapeId, SubSenseId } from "./Data/constants";
import { ZensoryModule } from "../ZensoryModule";
import PauseOverlay from "./PauseOverlay";
import { isKeyIn, stopPropagation } from "./UI/KeyPressHelper";
import { KeyId } from "./UI/KeyEnum";
import { getExperienceButtonTabIndex, isShowExperienceButton, showStartExperiencePopup } from "../experiences/ExperienceUIHelper";
import { isNullOrEmpty } from "./Data/Util/util";
import StartExperienceOverlay from "./StartExperienceOverlay";
import { AudioType, getNewAudioPlayer } from "./API/AudioPlayerAPI";
import LoadExperienceOverlay from "./LoadExperienceOverlay";
import { DEFAULT_GLOBAL_VOLUME_PERCENT, getAudioObjLevel } from "./Util/AudioHelper";

const MultiTrackPlayer = (props) => {

    const [audios, setAudios] = useState([]);
    const [audioPlayer, setAudioPlayer] = useState(null);
    const isUnlimited = props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP;

    useEffect(() => {
        setAudios(audiosunused => {
            LOGGER.log(LogLevel.DEBUG, `setAudios() started, audios=${audios}`);
            if (audios.length > 0) {
                return audios;
            }

            const audioObjs = [];

            // play Binaural and Music for Sound, Touch and Visual-Guided breathing
            if (props.zense === SenseId.Touch
                || props.zense === SenseId.Sound
                || (props.zense === SenseId.Breath && props.subZense === SubSenseId.VisualGuidedBreath)) {
                const binaural = ZensoryModule.getAudioLoader().getBinuaralAudio(props.mood, props.timeLimit);
                audioObjs.push({
                    id: AudioType.Binaural,
                    type: AudioType.Binaural,
                    uri: binaural,
                    isUnlimited: isUnlimited,
                    isLogSuccess: true,
                    volume: getAudioObjLevel(props.zense, props.experience, AudioType.Binaural)
                });
                
                if (hasMusicAudio(props.experience)) {
                    const music = ZensoryModule.getAudioLoader().getMusicAudio(props.mood, props.timeLimit);
                    audioObjs.push({
                        id: AudioType.Music,
                        type: AudioType.Music,
                        uri: music,
                        isUnlimited: isUnlimited,
                        isLogSuccess: true,
                        volume: getAudioObjLevel(props.zense, props.experience, AudioType.Music)
                    });
                }
            }

            if (props.subZense === SubSenseId.AudioGuidedBreath) {
                const audioBreath = ZensoryModule.getAudioLoader().getAudioGuidedBreathworkAudio(props.mood, props.timeLimit);
                audioObjs.push({
                    id: AudioType.AudioBreath,
                    type: AudioType.AudioBreath,
                    uri: audioBreath,
                    isUnlimited: isUnlimited,
                    isLogSuccess: true,
                    volume: getAudioObjLevel(props.zense, props.experience, AudioType.AudioBreath)
                });            
            }

            // only add the Soundscape/Naturescape for Sound experiences (excluding the No Nature track)
            if (props.zense === SenseId.Sound && props.experience !== SoundscapeId.NoNature) {
                const soundscape = ZensoryModule.getAudioLoader().getNaturescapeAudio(props.experience, props.timeLimit);
                audioObjs.push({
                    id: AudioType.Soundscape,
                    type: AudioType.Soundscape,
                    uri: soundscape,
                    isUnlimited: isUnlimited,
                    isLogSuccess: true,
                    volume: getAudioObjLevel(props.zense, props.experience, AudioType.Soundscape)
                });
            }
            
            return audioObjs;
        });
      }, []);

    useEffect(() => {
        if (hasExperienceLoaded() && !showStartExperiencePopup()) {
            LOGGER.log(LogLevel.DEBUG, `useEffect([props.hasLoaded, props.hasLoadedVisualBreath]) has loaded so Playing Audio`);
            playAudio();
        }
    }, [props.hasLoaded, props.hasLoadedVisualBreath]);

    useEffect(() => {
        if (props.expired) {
            LOGGER.log(LogLevel.DEBUG, `useEffect([audios, props.volume]) expired, returning`);
            return;
        }

        if (audios.length > 0) {
            const setupAudioPlayerLocal = async () => {
                if (isNullOrEmpty(audioPlayer)) {
                    await initAudioPlayer();
                    updateAudioPlayerVolumes(
                        props,
                        DEFAULT_GLOBAL_VOLUME_PERCENT,
                        getAudioObjLevel(props.zense, props.experience, AudioType.Binaural),
                        getAudioObjLevel(props.zense, props.experience, AudioType.Music),
                        getAudioObjLevel(props.zense, props.experience, AudioType.Soundscape),
                        getAudioObjLevel(props.zense, props.experience, AudioType.AudioBreath)
                    );
                } else {
                    updateAudioPlayerVolumes(
                        audioPlayer,
                        props.volume,
                        props.binauralVolume,
                        props.musicVolume,
                        props.naturescapeVolume,
                        props.audioBreathVolume,
                    );
                }
            };
            setupAudioPlayerLocal();
        }
    }, [audios, props.musicVolume, props.binauralVolume, props.audioBreathVolume, props.naturescapeVolume, props.visualBreathTriggerVolume, updateAudioPlayerVolumes]);

    /**
     * Initialize the audio player and set it in the state
     */
    async function initAudioPlayer() 
    {
        LOGGER.log(LogLevel.DEBUG, `MultiTrackPlayer.initAudioPlayer() Loading audios=${audios}`);
        const audioPlayerNew = getNewAudioPlayer(
            audios,
            // on load 
            () => {
                LOGGER.log(LogLevel.DEBUG, `MultiTrackPlayer.initAudioPlayer() hasLoaded`);
                setAudioPlayer(audioPlayerNew);
                props.setHasLoaded(true);
            },
            // on error
            (error) => {
                LOGGER.log(LogLevel.ERROR, `MultiTrackPlayer.initAudioPlayer() audio player error=${error}`);
                // TODO: set Experience error prop
            }
        );
        await audioPlayerNew.load();
    }

    /**
     * Update the audio volumes
     * 
     * @param {any | import("./API/AudioPlayerAPI").AudioPlayerApi} obj the props or AudioPlayer object to set the volume on
     * @param {*} volumeGlobal      the Global volume [0-100]
     * @param {*} volumeBinaural    the Binaural volume [0-100]
     * @param {*} volumeMusic       the Music volume [0-100]
     * @param {*} volumeNaturescape the Naturescape volume [0-100]
     * @param {*} volumeAudioBreath the Audio Breath volume [0-100]
     */
    function updateAudioPlayerVolumes(
        obj,
        volumeGlobal,
        volumeBinaural,
        volumeMusic,
        volumeNaturescape,
        volumeAudioBreath
    ) {
        obj.setVolume(volumeGlobal);
        obj.setBinauralVolume(volumeBinaural);
        if (hasMusicAudio(props.experience)) {
            obj.setMusicVolume(volumeMusic);
        }
        if (props.zense === SenseId.Sound && hasNaturescapeAudio(props.experience)) {
            obj.setNaturescapeVolume(volumeNaturescape);
        }
        if (isAudioGuidedBreath(props.zense, props.subZense)) {
            obj.setAudioBreathVolume(volumeAudioBreath);
        }
    }

    useEffect(() => {
        if (props.expired) {
            LOGGER.log(LogLevel.DEBUG, `pauseAudio() props.expired=true`);
            pauseAudio();
        }
    }, [props.expired]);


    useEffect(() => {
        document.addEventListener('keydown', handleToggleAudioFromKey);
        return () => document.removeEventListener("keydown", handleToggleAudioFromKey);
    });

    /**
     * @returns if all the audio has loaded. For Visual Breath Experiences, also check if the visual triggers have loaded
     */
    const hasExperienceLoaded = () => {
        return props.hasLoaded && (!isVisualGuidedBreath(props.zense, props.subZense) || props.hasLoadedVisualBreath)
    }

    // Have to use useLayoutEffect instead of useEffect to trigger audio playback on Safari
    // https://lukecod.es/2020/08/27/ios-cant-play-youtube-via-react-useeffect/
    useLayoutEffect(() => {
        if (hasExperienceLoaded() && showStartExperiencePopup() && props.hasStarted) {
            playAudio();
            props.setIsPlaying(true);
        }
    }, [props.hasStarted]);

    const playAudio = () => {
        if (!hasExperienceLoaded()) {
            return;
        }

        if (showStartExperiencePopup() && !props.hasStarted) {
            // wait for user to click the start Experience overlay
            props.setIsPlaying(false);
            return;
        }

        // if this is the first call to play the audio, track the experience start
        if (!props.hasStarted) {
            props.trackStartExperience();
        }

        // must clear for all other browsers
        props.setHasStarted(true);

        audioPlayer.play();
    }

    const pauseAudio = () => {
        if (isNullOrEmpty(audioPlayer)) {
            return;
        }
        
        audioPlayer.pause();
    }

    const backButton = document.getElementById("btn-close-exp-popup-okay")
    if (backButton) {
        backButton.addEventListener("click", function () {
            pauseAudio();
        });
    }

    const toggleAudio = (e) => {
        stopPropagation(e);
        props.setIsPlaying(!props.isPlaying);
        if (!props.isPlaying) {
            playAudio();
        } else {
            pauseAudio();
        }
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownToggleAudio = (e) => {
        stopPropagation(e);
        handleToggleAudioFromKey(e);
    }

    const handleToggleAudioFromKey = (e) => {
        if (!hasExperienceLoaded()
            || !props.hasStarted
            || props.showVolumeSetting
            || !isKeyIn(e, [KeyId.SpaceBar, KeyId.Enter])
            || isVisualGuidedBreath(props.zense, props.subZense)) {
            return;
        }
        toggleAudio();
    }

    return (
        <>
            {!hasExperienceLoaded() && !props.closeExperiencePopup
                ? <LoadExperienceOverlay/> 
                : null
            }
            {hasExperienceLoaded() && showStartExperiencePopup() && !props.hasStarted && !props.closeExperiencePopup
                ? <StartExperienceOverlay
                    setHasStarted={props.setHasStarted}
                />
                : null
            }
            {!props.isPlaying && props.hasStarted
                ? <PauseOverlay
                    toggleAudio={toggleAudio}
                    timeLimit={props.timeLimit}
                    experienceDuration={props.experienceDuration}
                />
                : null
            }
            <div className={!isShowExperienceButton(props) 
                ? "track-controls-not-visible" 
                : "track-controls"}
            >
                {props.subZense !== SubSenseId.VisualGuidedBreath && hasExperienceLoaded() && props.hasStarted
                    ? <img src={props.isPlaying ? pauseIcon : playIcon}
                        id="btn-toggle-audio"
                        className="play"
                        onClick={(e) => toggleAudio(e)}
                        tabIndex={getExperienceButtonTabIndex(props)}
                        onKeyDown={(e) => _handleKeyDownToggleAudio(e)}
                        alt="Pause experience"
                        aria-label="Pause experience"
                        role="button"
                    />
                    : <div className="visual-guided-play"
                    />
                }
                <Timer
                    timeLimit={props.timeLimit}
                    isPlaying={props.isPlaying}
                    hasLoaded={hasExperienceLoaded()}
                    hasStarted={props.hasStarted}
                    expired={props.expired}
                    setExpired={props.setExpired}
                    closeExperiencePopup={props.closeExperiencePopup}
                    setExperienceDuration={props.setExperienceDuration} />
            </div></>
    )
}
export default MultiTrackPlayer;