import React, { useEffect } from "react";
import "../styles/moonDust.css"

const MoonDust = (props) => {
  const url = `moonDust.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('startMoonDust');
    startDiv.click();
    const stopDiv = document.getElementById('stopMoonDust');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);

  return (
    <div className='background'> 
      <div id="stopMoonDust"></div>
      <div id="startMoonDust"></div>
      <canvas id="canvasOne"></canvas>
      <div className="moonFlare">
          <canvas id="fireFlies" width={1700} height={1000}></canvas>
      </div>
    </div>
  );
}

export default MoonDust;