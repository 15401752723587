import React, { useEffect } from "react";
import "../styles/lavalamp.css";

const Lavalamp = () => {
  const url = "lavalamp.js"

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
  return (
    <div className="purple_wrap">
      <canvas
        id="bubble"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></canvas>
    </div>
  );
};

export default Lavalamp;
