import { RouteId } from "../../Routes";
import { ZensoryModule } from "../../ZensoryModule";
import { GlobalAppState } from "../AppState";
import { EnvId, ExperienceId, ExperienceSource, MoodId, SenseId, SoundscapeId, SubSenseId } from "../Data/constants";
import { isValidEnumValue } from "../Data/Util/util";
import { isNullOrEmpty } from "../Data/Util/util";
import { preventDefault } from "../UI/KeyPressHelper";
import { getConfiguration } from "./ConfigHelper";
import { setExperienceSourceGlobal } from "./ExperienceAnalyticsHelper";
import { LOGGER, LogLevel } from "./Logger";

const SENSE_ID_NATURE = "nature";
const SOUNDSCAPE_ID_NONE = "none";

export function handleNavigationfromParams(
    moodId: MoodId | null,
    zenseId: SenseId | null,
    subZenseId: SubSenseId | null,
    experienceId: ExperienceId | null,
    audioDurationSecs: string | null,
    autoPlay: string | null,
    source: string | null
) {
    LOGGER.log(
        LogLevel.DEBUG, 
        `handleNavigationfromParams() moodId=${moodId}, 
        zenseId=${zenseId}, 
        subZenseId=${subZenseId}, 
        experienceId=${experienceId}, 
        audioDurationSecs=${audioDurationSecs}, 
        autoPlay=${autoPlay},
        source=${source}`
    );

    // prioritise the source passed in from the URL params
    const getSource = (experienceSource: ExperienceSource): ExperienceSource => {
        if (isValidEnumValue(ExperienceSource, source)) {
            return source as ExperienceSource;
        } else {
            return experienceSource;
        }
    }

    // set the default value
    setExperienceSourceGlobal(getSource(ExperienceSource.WebChooseZense));

    // mobile app sends "nature" for Sound
    if (!isNullOrEmpty(zenseId) && (zenseId as string) === SENSE_ID_NATURE) {
        zenseId = SenseId.Sound;
    }

    // mobile app sends "none" for noNature Soundscape
    if (!isNullOrEmpty(experienceId) && (experienceId as string) === SOUNDSCAPE_ID_NONE) {
        experienceId = SoundscapeId.NoNature;
    }

    // if the content for the Experience is not configured
    if (!ZensoryModule.getExperienceConfig().isConfigured(moodId, zenseId, subZenseId, experienceId, audioDurationSecs)) {
        logAndOrAlert(`Sorry! This version of The Zensory does not support this link.`);

        // if we are running in deep link only mode but do not have all the required fields, 
        // go to the not authorized screen
        if (getConfiguration().isExperienceDeepLinkOnly) {
            ZensoryModule.getNavigationHandler().navigate(window, RouteId.NotAuthorized);
        }
        return;
    }

    // Handle the path into the web app
    if (moodId && zenseId && subZenseId && audioDurationSecs && autoPlay === "true") {
        GlobalAppState.setIsDeepLink(true);
        setExperienceSourceGlobal(getSource(ExperienceSource.WebDeepLink));
        GlobalAppState.setMood(moodId);
        GlobalAppState.setZense(zenseId);
        GlobalAppState.setSubZense(subZenseId);
        GlobalAppState.setExperience(experienceId ? experienceId : undefined);
        GlobalAppState.setTimeLimit(parseInt(audioDurationSecs));
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Experience, GlobalAppState.setStart, GlobalAppState.setExperienceSummary);
        return;
    } else if (moodId && zenseId && subZenseId && audioDurationSecs && (isNullOrEmpty(autoPlay) || autoPlay === "false")) {
        GlobalAppState.setIsDeepLink(true);
        setExperienceSourceGlobal(getSource(ExperienceSource.WebDeepLink));
        GlobalAppState.setMood(moodId);
        GlobalAppState.setZense(zenseId);
        GlobalAppState.setSubZense(subZenseId);
        GlobalAppState.setExperience(experienceId ? experienceId : undefined);
        GlobalAppState.setTimeLimit(parseInt(audioDurationSecs));
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.ExperienceSummary, GlobalAppState.setExperienceSummary, GlobalAppState.setChooseNaturescape);
        return;
    }

    // if we are not on the Experience page (to avoid falling back into the empty URL query params case)
    // and running in deep link only mode but do not have all the required fields, go to the not 
    // authorized screen
    LOGGER.log(LogLevel.DEBUG, `window.location.pathname=${window.location.pathname}`);
    const isExperiencePage = window.location.pathname === `/${RouteId.Experience}` || window.location.pathname === `/${RouteId.ExperienceSummary}`;
    if (getConfiguration().isExperienceDeepLinkOnly && !isExperiencePage) {
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.NotAuthorized);
        return;
    }

    // subZense is optional for Breath, if set, it will select the tile on the Choose a Breath page
    if (moodId && zenseId && (subZenseId || zenseId === SenseId.Breath)) {
        GlobalAppState.setIsDeepLink(true);
        setExperienceSourceGlobal(getSource(ExperienceSource.WebChooseZense));
        GlobalAppState.setMood(moodId);
        GlobalAppState.setZense(zenseId);
        if (subZenseId) {
            GlobalAppState.setSubZense(subZenseId);
        }
        if (experienceId) {
            GlobalAppState.setExperience(experienceId);
        }

        switch (zenseId) {
            case SenseId.Breath:
                ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseBreathwork, GlobalAppState.setChooseBreathwork, GlobalAppState.setChooseZense);
                break;

            case SenseId.Touch:
                ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseTouchpad, GlobalAppState.setChooseTouchpad, GlobalAppState.setChooseZense);
                break;

            case SenseId.Sound:
                ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseNaturescape, GlobalAppState.setChooseNaturescape, GlobalAppState.setChooseZense);
                break;

            default:
                throw new Error(`Invalid zenseId=${zenseId}`);
        }

    } else if (moodId) {
        GlobalAppState.setIsDeepLink(true);
        setExperienceSourceGlobal(getSource(ExperienceSource.WebChooseZense));
        GlobalAppState.setMood(moodId);
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseZense, GlobalAppState.setChooseZense, null);
    }
};

function logAndOrAlert(errorMsg: string) {
    LOGGER.log(LogLevel.ERROR, errorMsg);

    // also show an alert when in DEV
    const config = getConfiguration();
    if (config.envId === EnvId.DEV) {
        alert(errorMsg);
    }
}

/**
 * Gets params from search URL.
 *
 * @return {Object} A key-value map from the string param name to its value.
 */
export var queryParams = window.location.search.substring(1).split('&').reduce(function (q: any, query: any) {
    var chunks = query.split('=');
    var key: any = chunks[0];
    var value: any = decodeURIComponent(chunks[1]);
    value = isNaN(Number(value)) ? value : Number(value);
    return (q[key] = value, q);
}, {});

/**
 * Handles back navigation when the experience is in ExperienceDeepLinkOnly mode.
 *
 * @param  setIsBackNavigation Callable setState function for boolean state IsBackNavigation.
 * @param  window Window object.
 * @param  backNavRef Ref of the isBackNavigation state.
 */
export function handleBackNavigation(setIsBackNavigation: CallableFunction, window: Window & typeof globalThis, backNavRef: React.MutableRefObject<undefined>) {
    const config = getConfiguration();

    const onBackButtonEvent = (e: any) => {
        preventDefault(e);
        
        //prevent back navigation for ExperienceDeepLinkOnly mode, do nothing otherwise
        if (config.isExperienceDeepLinkOnly) {
            setIsBackNavigation(true);
            if (backNavRef.current === true) {
                window.history.pushState(null, "", window.location.pathname);
            }
        }
    };

    if (config.isExperienceDeepLinkOnly) {
        window.history.pushState(null, "", window.location.pathname);
    }
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
        setIsBackNavigation(false);
    };
}
