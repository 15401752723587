import { AnalyticsApi, AnalyticsSummary } from "../../API/AnalyticsApi";
import { SessionHandlerApi } from "../../API/SessionHandlerApi";
import { GlobalAppState, setAppStateToStart } from "../../AppState";
import { LOGGER, LogLevel } from "../../Util/Logger";

export class ScormSessionHandler implements SessionHandlerApi {

    analyticsApi: AnalyticsApi;

    constructor(analyticsApi: AnalyticsApi) {
        this.analyticsApi = analyticsApi;
    }

    async onExperienceReviewFinish(window: Window & typeof globalThis, scorm: any, isExit: boolean): Promise<void> {
        // track all the scorm values

        const statsSummary: AnalyticsSummary = this.analyticsApi.getSessionSummaryJson();

        LOGGER.log(LogLevel.WARN, `statsSummary=${statsSummary}`, statsSummary);
        
        scorm.setScore({
            value: statsSummary.score,
            min: 0,
            max: 100
        });
        const scoreScaledDecimal = Math.round(10 * (statsSummary.score / 100.0)) / 10.0;
        scorm.set('cmi.objectives.0.score.raw', statsSummary.score);
        scorm.set('cmi.objectives.0.score.min', 0);
        scorm.set('cmi.objectives.0.score.max', 100);
        scorm.set('cmi.score.scaled', scoreScaledDecimal);

        var completionStatus;
        var successStatus;
        var progressMeasure = statsSummary.score;
        if (statsSummary.score === 100) {
            completionStatus = 'completed';
            successStatus = 'passed';
        } else {
            completionStatus = 'incomplete';
            successStatus = 'unknown';
        }

        scorm.set('cmi.objectives.0.completion_status', completionStatus);
        scorm.set('cmi.completion_status', completionStatus);
        scorm.set('cmi.objectives.0.success_status', successStatus);
        scorm.set('cmi.success_status', successStatus);
        
        scorm.set("cmi.suspend_data", JSON.stringify(statsSummary));
        scorm.set("cmi.progress_measure", progressMeasure)  // set progress measure to minimum progress measure
        
        if (isExit) {
            scorm.set("cmi.exit", "normal");      //exit normally
            scorm.set("adl.nav.request", "exitAll");
        }
        scorm.setStatus(completionStatus);

        LOGGER.log(LogLevel.DEBUG, `SCORM score=${statsSummary.score}, completionStatus=${completionStatus}, successStatus=${successStatus}`);

        // take the user back to the start
        if (!isExit) {
            setAppStateToStart(GlobalAppState);
        }
    }

    isCompleted(): boolean {
        const summary = this.analyticsApi.getSessionSummaryJson();
        return summary.score === 100;
    }

}