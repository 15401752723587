import cross from "../assets/icons/close.svg"
import info from "../assets/icons/info.svg"
import { isSubmitKey, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import { Interweave } from "interweave";
import { getText, TextId } from "./Util/TextHelper";
import { useEffect, useRef } from "react";
import { handleTabKey } from "./Util/ModalAccessibilityHelper";
import { KeyCode } from "./UI/KeyEnum";

const AuthErrorPopup = (props) => {

    const modalRef = useRef();

    useEffect(() => {
        function keyListener(e) {
            const listener = e.keyCode === KeyCode.Tab 
                ? handleTabKey(e, modalRef,'img, button') 
                : e.keyCode === KeyCode.Esc
                ? closePopup(e) 
                : null; 
            return listener && listener(e, modalRef,'img, button');
          }
        document.addEventListener("keydown", keyListener);
        return () => document.removeEventListener("keydown", keyListener);
      },[]);

    const closePopup = (e) =>{
        preventDefault(e);
        props.setShowAuthErrorPopup(false);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            closePopup(e);
        }
    }

    return (
        <div className="popup-container-close" ref={modalRef}>
            <div className='popup-header'>
            <img 
                src={info} 
                className="popup-info-icon" 
                tabIndex="0"
                alt="More information button" />
            <img 
                id="btn-auth-error-popup-close"
                src={cross} 
                className="popup-close-icon" 
                onClick={(e)=>closePopup(e)} 
                alt="Close popup"
                tabIndex="0" 
                onKeyDown={(e) => _handleKeyDownClose(e)}
            />
            </div>
            <h1 className="popup-heading">
                <Interweave
                    content={getText(TextId.loginPopupHeading)}
                />
            </h1>
            <div className='popup-instruction-close'>
              {props.errorMessage}
            </div>
            <button
                id="btn-auth-error-popup-okay"
                tabIndex="0"
                className="okay-button"
                onClick={(e) => closePopup(e)}>
                Okay
            </button>
        </div>
    );
};

export default AuthErrorPopup;