import { isSafari } from "@firebase/util";
import { ScreenOrientation } from "../Data/constants";
import { isNullOrEmpty } from "../Data/Util/util";
import { isEdge, isIOS, isMobile } from "./DeviceUtil";
import { LOGGER, LogLevel } from "./Logger";

export function getScreenOrientation(): ScreenOrientation {
    // iOS is not able to check orientation correctly using other methods, 
    // so assume all iOS devices are portrait
    if (isIOS()) {
        return ScreenOrientation.Portrait;
    }

    var isLandscape = true; // default to landscape

    var orientationType = null;
    try {
        const myScreen: any = window.screen;
        orientationType = (myScreen.orientation || {}).type || myScreen.mozOrientation || myScreen.msOrientation;
    } catch (ignore) {
    }

    if (!isNullOrEmpty(orientationType)) {
        switch (orientationType) {
            case "landscape-primary":
            case "landscape-secondary":
                isLandscape = true;
                break;
            
            case "portrait-secondary":
            case "portrait-primary":
                isLandscape = false;
                break;

            default:
                break;
        }
    
    } else if (!isNullOrEmpty(window) && !isNullOrEmpty(window.orientation)) {
        isLandscape = Math.abs(+window.orientation) === 90;
    
    } else {
        var width = 0, height = 0;
        try {
            width = window.innerWidth;
            height = window.innerHeight;
        } catch (ignore) {
        }

        if (height > 0 && width > 0) {
            isLandscape = width > height;
        } else {
            var successFromMatchMedia = false;
            try {
                if (window.matchMedia("(orientation: landscape)").matches) {
                    isLandscape = true;
                    successFromMatchMedia = true;
                } else if (window.matchMedia("(orientation: portrait)").matches) {
                    isLandscape = false;
                    successFromMatchMedia = true;
                } 
            } catch (ignore) {
            }

            if (!successFromMatchMedia) {
                // assume mobile is portrait
                isLandscape = !isMobile();
            }
        }
    }

    if (isLandscape) {
        return ScreenOrientation.Landscape;
    } else {
        return ScreenOrientation.Portrait;
    }
}

export async function enterFullscreen() {
    var elem: any = document.documentElement;

    if (isSafari()) {
        await elem.webkitRequestFullscreen();
    } else if (isEdge()) {
        await elem.msRequestFullscreen();      
    } else if (document.fullscreenElement === null) {
        await elem.requestFullscreen();      
    }
}
  
export async function exitFullscreen() {
    var doc: any = document;

    if (isSafari()) {
        await doc.webkitExitFullscreen();
    } else if (isEdge()) {
        await doc.msExitFullscreen();      
    } else if (document.fullscreenElement !== null) {
        await doc.exitFullscreen();      
    }
}

function listenToFullScreenChange(onFunc: any) {
    if (document.addEventListener) {
        document.addEventListener('fullscreenchange', onFunc, false);
        document.addEventListener('mozfullscreenchange', onFunc, false);
        document.addEventListener('msfullscreenchange', onFunc, false);
        document.addEventListener('webkitfullscreenchange', onFunc, false);
    }
}

export function listenToFullScreenEnter(fullScreenListener: (isFullScreen: boolean) => any) {
    const enterHandler = (func: any) => {
        const doc: any = document;
        if (doc.webkitIsFullScreen || doc.mozFullScreen || doc.msFullscreenElement) {
            // Run code on enter
            fullScreenListener(true);
        } else {
            fullScreenListener(false);
        }
    }

    listenToFullScreenChange(enterHandler);
}

/**
 * Class handles screen wake locking and releasing
 */
export class ScreenWakeLock {

    wakeLock: any | null = null;

    /**
     * Get the screen wake lock (if available)
     */
    async lockHandler() {
        if (isNullOrEmpty(navigator) || isNullOrEmpty((navigator as any).wakeLock)) {
            return;
        }

        LOGGER.log(LogLevel.DEBUG, `WakeLock: requesting`);
        this.wakeLock = await (navigator as any).wakeLock?.request("screen");
    }
  
    /**
     * Release the screen wake lock if we have one
     */
    async releaseHandler() {
        if (isNullOrEmpty(this.wakeLock)) {
            return;
        }

        LOGGER.log(LogLevel.DEBUG, `WakeLock: releasing`);
        await this.wakeLock!!.release();
        this.wakeLock = null;
    }

}