import "../styles/experience.css"
import pause_icon from "../assets/icons/pause@3x.svg"
import { isSubmitKey, stopPropagation } from './UI/KeyPressHelper';
import { LOGGER, LogLevel } from "./Util/Logger";
import { useEffect, useState } from "react";

const StartExperienceOverlay = (props) => {

    useEffect(() => {
        LOGGER.log(LogLevel.DEBUG, `Showing StartExperienceOverlay`);
    }, []);

    // set focus to the start button when the dialog shows
    const [btnStart, setStartButton] = useState(null);
    useEffect(() => {
        if (btnStart != null) {
            btnStart.focus();
        }
    }, [btnStart]);

    const onStartExperienceClick = (e) => {
        LOGGER.log(LogLevel.DEBUG, `onStartExperienceClick() called`);
        stopPropagation(e);
        props.setHasStarted(true);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownStartExp = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            onStartExperienceClick(e);
        }
    }

    return (
        <div className="start-exp-overlay-container"
            id="btn-start-experience"
            role="button"
            ref={(button) => { setStartButton(button); }}
            onClick={onStartExperienceClick}
            onKeyDown={_handleKeyDownStartExp}                    
            tabIndex="0"
            alt="Tap to start experience button"
            aria-label="Tap to start experience button"
        >
            <h1 className="overlay-heading">
            Start Experience
            </h1>
            <img className="start-exp-play-icon" 
                src={pause_icon}
                alt="Play icon"
            />
        </div>
    )
};

export default StartExperienceOverlay;